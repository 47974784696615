import React from "react"

const BackArrowDark = () => (
  <svg width={20} height={10}>
    <g stroke="#767676" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M5.167 9.167L1 5M5.167.833L1 5M2 5h17.507" />
    </g>
  </svg>
)

export default BackArrowDark
