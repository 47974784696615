import React, { Component } from 'react';
import './project-block.css';
import SkillBlock from '../skills/skill-block.js';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import CreatedDateConverter from './created-date-converter.js';
import DateConverter from './date-converter.js';
import Skills from '../../data/skills.js';

const ProjectBlock = props => {

  function filter_quantified(skill) {
    return skill.state == 'quantified';
  }

  function filter_private(skill) {
    return skill.private == true;
  }

  function filter_not_quantified(skill) {
    return skill.state !== 'quantified';
  }

  function filter_public(skill) {
    return skill.private == false;
  }

  function compareDisplayBuckets(a, b) {
    return b.displayBucket - a.displayBucket;
  }

  function filter_reviews_contributed(skill) {
    return skill.ownerNumReqReviews == 0;
  }



  // let skillsNotHidden = Skills
  let skillsNotHidden = Object.keys(props.project.skills).map(key => {return props.project.skills[key]})
  const skillsNotHiddenQuantified = skillsNotHidden.filter(filter_quantified).filter(filter_public).filter(filter_reviews_contributed).sort(compareDisplayBuckets)
  const skillsNotHiddenQuantifiedPrivate = skillsNotHidden.filter(filter_quantified).filter(filter_private)
  const skillsNotHiddenNotQuantified = skillsNotHidden.filter(filter_not_quantified)
  skillsNotHidden = skillsNotHiddenQuantified.concat(skillsNotHiddenQuantifiedPrivate).concat(skillsNotHiddenNotQuantified)

  const numberOfSkills = skillsNotHidden.length
  const numberOfSkillsQuantified = Object.keys(props.project.skills).map(key => {return props.project.skills[key]}).filter(filter_quantified).filter(filter_reviews_contributed).filter(filter_public).length

  let numberOfSkillsLeft = skillsNotHidden.length - skillsNotHidden.slice(0,3).length;

  if (numberOfSkillsLeft < 0) {
    numberOfSkillsLeft = 0
  }

  let anyQuantified = false

  if (skillsNotHiddenQuantified.length>0) {
    anyQuantified=true
  }

  const skills = skillsNotHidden.slice(0,3).map(skill => {
    console.log('here')
  //  console.log(skill["Product Management"])
    // console.log(skill.privacy)
    // console.log(skill.skillName)

    return (
      <div className='project-block-skill-block-container' key={skill.skillName}>
        <SkillBlock
          skill={skill}
          isInPreviewMode={true}
          anyQuantified={anyQuantified}
          from={"user"}
          />
      </div>
    );
  });


  return (

    <div className='project-block'>
      <div className='project-block-header'>
        <img className='project-block-header-background-image' src={props.project.backgroundImage}/>
        <div className='project-block-header-container'>
          {props.isShownToExternalViewer?null:
            <div className='project-block-status-badge-container'>
              <div style={{color:props.project.publishState=="published"?"#242424":"#EB635C"}} className='project-block-status-badge'>
                {props.project.publishState=="published"?'Published':null}
                {props.project.publishState=="unpublished"?'Unpublished':null}
                {props.project.publishState=="not_published"?'Not Published':null}
              </div>
            </div>
          }
          {props.project.projectTitle?
            <div className='project-block-title'>
              {props.project.projectTitle}
            </div>
            :
            <div className='project-block-title-placeholder'>
              Untitled
            </div>
          }
        </div>
      </div>

      <div className='project-block-meta-data-section'>

        {!props.project.location && !props.project.role && !props.project.company? null:
          <div className='meta-data-block-1'>
            {props.project.company? props.project.company:null}
            {props.project.role && !props.project.company? props.project.role: null}
            {props.project.location && !props.project.role && !props.project.company? props.project.location: null}
          </div>
        }

        {!props.project.startDate && !props.project.endDate ?

          <div className='meta-data-block-2' style={{width:!props.project.location && !props.project.role && !props.project.company? '100%' : '50%'}}>
            {`Created on ${CreatedDateConverter(props.project.date_creation)}`}
          </div>
          :
        <div className='meta-data-block-2' style={{width:!props.project.location && !props.project.role && !props.project.company? '100%' : '50%'}}>
          {DateConverter(props.project.startDate, props.project.endDate)}
        </div>
        }
      </div>
      {!props.project.description?null:
        <div className='project-block-description-section'>
          <div className='project-block-description'>
            {props.project.description}
          </div>
        </div>
        }



          <div className='project-block-skill-container' style={{paddingBottom:!props.project.description && numberOfSkillsLeft == 0 ?'0px':'12px'}}>
            {skillsNotHidden==0?null:
              <div>
              <div className='project-block-skill-blocks-container'>
                {skills}
              </div>
              {(numberOfSkillsLeft == 0)? null:
                <div className='project-block-more-skills'>
                  {(numberOfSkillsLeft == 1)?
                  `+ ${numberOfSkillsLeft} skill`
                  :
                  `+ ${numberOfSkillsLeft} skills`
                }
                </div>
              }
              </div>
            }
          </div>



      <div className='project-block-see-all' onClick={() => props.seeProject(props.project.projectId)}>
        See the full case study
      </div>



    </div>

  );
}

export default ProjectBlock;
