import React, { Component } from 'react';
import './skill-block.css';
import SkillBlockX from '../../assets/svg/skill-block-x.js'
import SkillBlockEye from '../../assets/svg/skill-block-eye.js'
import SkillBlockLock from '../../assets/svg/skill-block-lock.js'
import Backend from '../backend.js'
import GenericModal from '../modal/generic-modal.js'
import ConvertToDisplaySkill from './convert-to-display-skill.js'

class SkillBlock extends Component {

  constructor(props) {
      super(props);
      this.state={
        isDeleteSkillModalOpen: false,
        isSkillInfoModalOpen: false,
        skillState:"in_progress",
      }

      this.toggleDeleteSkillModal = this.toggleDeleteSkillModal.bind(this);
      this.toggleSkillInfoModal = this.toggleSkillInfoModal.bind(this);

    }

  toggleDeleteSkillModal() {
    this.setState(state => ({
      isDeleteSkillModalOpen: !state.isDeleteSkillModalOpen
    }));
  }

  toggleSkillInfoModal() {
    this.setState(state => ({
      isSkillInfoModalOpen: !state.isSkillInfoModalOpen
    }));
  }

  render() {

    console.log("any quatnified?ADSfas ", this.props.anyQuantified);
    console.log("from ", this.props.from);
    const skillState = this.props.skill.state
    console.log('its the skillState', skillState)
    // STATE-BASED STYLINGS
    let blueBarWidth
    if (skillState=='quantified') {
      blueBarWidth = {width: this.props.skill.displayBucket + '%'};
    }

    const privacyStyling = {
      opacity: this.props.skill.private && !this.props.isInPreviewMode && this.props.skill.state == 'quantified' && this.props.skill.ownerNumReqReviews == 0 ? '0.3' : '1', // && not enough reviews
    }



    // THIS STARTS A BIG CONDITIONAL SECTION
    // DEPENDING ON THE STATE OF THE SKILL, WE RENDER DIFFERENT VIEWS

    let skillBlockBottomSection
    let displayBucketNumber

    if (skillState=='quantified') {
      displayBucketNumber = this.props.skill.displayBucket;
      console.log('display bucket: ',this.props.skill.displayBucket)//.replace('%', '')
    }

    // SHOW THIS SECTION IF STATE = QUANTIFIED
    const quantifiedBottom =
      <div style={privacyStyling} className='skill-block-display-score'>
        <div className='skill-block-display-score-bar-container'>
          <div className='skill-block-display-score-bar-background'>
            <div style={blueBarWidth} className='skill-block-display-score-bar-percentile'>
            </div>
          </div>
          <div className='skill-block-display-score-label'>
            {displayBucketNumber}
          </div>

        </div>
      </div>


    // SHOW THIS SECTION IF STATE = IN_PROGRESS
    const inProgressBottom =
      <div style={privacyStyling} className='skill-block-display-score'>
          <div className='skill-block-display-label'>
            <span onClick={this.toggleSkillInfoModal} className='underline'>Skill assessment in progress</span>
          </div>
      </div>

    // SHOW THIS SECTION IF STATE = NOT_QUANTIFIED
    const notQuantifiedBottom =
      <div style={privacyStyling} className='skill-block-display-score'>
          <div className='skill-block-display-label'>
            Not scored
          </div>
      </div>

      // SHOW THIS SECTION IF STATE = NOT_QUANTIFIED
      const waitingToPublishBottom =
        <div style={privacyStyling} className='skill-block-display-score'>
            <div className='skill-block-display-label'>
              Skill not published
            </div>
        </div>

        // SHOW THIS SECTION IF STATE = NOT_QUANTIFIED
        const scoredButNeedsReviewsBottom =
          <div style={privacyStyling} className='skill-block-display-score'>
              <div className='skill-block-display-label'>
                <span onClick={this.toggleSkillInfoModal} className='underline'>Scored — do peer reviews to see it</span>
              </div>
          </div>


      console.log("BEFORE ", skillState)
      console.log('Req teviews', this.props.skill.ownerNumReqReviews)
      console.log('Req teviews', this.props.skill)
      // SET SKILL BLOCK BOTTOM SECTOIN
      if (skillState=='quantified') {
        console.log("HEYYY, ", this.props.skill)
        if (this.props.skill.private && this.props.isInPreviewMode) {
          skillBlockBottomSection = notQuantifiedBottom
          console.log('1')
        } else {
          if (this.props.skill.ownerNumReqReviews > 0) {
            if (this.props.isInPreviewMode) {
              skillBlockBottomSection = notQuantifiedBottom
              console.log('2')
            } else {
              skillBlockBottomSection = scoredButNeedsReviewsBottom
              console.log('3')
            }
          } else {
            skillBlockBottomSection = quantifiedBottom
            console.log('4')
          }
        }

      }

      if (skillState=='in_progress') {
        console.log('5')

        {this.props.isInPreviewMode ?

          skillBlockBottomSection = notQuantifiedBottom

          :
          skillBlockBottomSection = inProgressBottom

        }

      }

      if (skillState=='waiting_to_assess') {
        console.log('no way: ', skillState)
        console.log('8')

        {this.props.isInPreviewMode ?
          skillBlockBottomSection = notQuantifiedBottom
          :
          skillBlockBottomSection = inProgressBottom
        }
      }

      if (skillState=='not_yet_reviewable') {

        {this.props.isInPreviewMode ?
          skillBlockBottomSection = notQuantifiedBottom
          :
          skillBlockBottomSection = inProgressBottom
        }
      }

      if (skillState=='waiting_to_publish') {
        skillBlockBottomSection = waitingToPublishBottom
      }

    // MAIN BODY OF SKILL BLOCK
    return (
      <div className='skill-block'>

        {this.props.isInPreviewMode ? null:
        <div className='skill-block-action-bar'>
          <div className='skill-block-action-bar-actions-container'>
            <div className='actions-container-vertical-center'>

              {this.props.skill.state === 'quantified' && this.props.skill.ownerNumReqReviews == 0 ?
              <div onClick={() => this.props.togglePrivate(this.props.skill.skillName)} className='skill-block-actions'>
                <SkillBlockLock
                  isPrivate={this.props.skill.private}
                />
              </div>

              : null

              }

              <div onClick={this.toggleDeleteSkillModal} className='skill-block-actions'>
                <SkillBlockX />
              </div>
            </div>
          </div>
        </div>
        }

        <div className='skill-block-body-container'>
          <div className='skill-block-skill-name'>
            {ConvertToDisplaySkill(this.props.skill.skillName)}
          </div>

          {/* RENDERS DIFFERENT BOTTOM SECTIONS DEPENDING ON SKILL STATE  */}

          {this.props.from==='user' && this.props.anyQuantified?
            <div>
              {skillBlockBottomSection}
            </div>
            :null
          }

          {this.props.from==='project' && (this.props.anyQuantified || (!this.props.isInPreviewMode&& !this.props.anyQuantified))?
            <div>
              {skillBlockBottomSection}
            </div>
            :null
          }

        </div>
        <GenericModal
           isModalOpen={this.state.isDeleteSkillModalOpen}
           toggleModal={this.toggleDeleteSkillModal}
           title={'Delete skill?'}
           message={<div>Are you sure you want to delete your {ConvertToDisplaySkill(this.props.skill.skillName)} skill? <br></br><br></br>Once deleted, all reviews for this skill that are associated with this case study will also be deleted. </div>}
           primaryActionLabel={'Delete skill'}
           primaryAction={() => this.props.deleteSkill(this.props.skill.skillName)}
           secondaryActionLabel={'Close'}
        />

        <GenericModal
           isModalOpen={this.state.isSkillInfoModalOpen}
           toggleModal={this.toggleSkillInfoModal}
           title={skillState=='quantified'?'Find out your score':'Skill assessment in progress'}
           message={
             skillState=='quantified'?
             <div>We have finished calculating your percentile score for {ConvertToDisplaySkill(this.props.skill.skillName)}.<br></br><br></br>In order to see your score, you first need to finish submitting peer reviews. Click "Go to reviews" to finish and get your score.</div>
             :
             <div>Your skills in {ConvertToDisplaySkill(this.props.skill.skillName)} are currently being assessed. Once the assessment is complete, we will calculate your percentile score.<br></br><br></br>To ensure you can see your score, make sure you finish submitting peer reviews. Click "Go to reviews" to finish.</div>

            }
           primaryActionLabel={'Go to reviews'}
           primaryAction={() => this.props.history.push('/review-lander')}
           secondaryActionLabel={'Close'}
        />
      </div>
    )
  }
}

export default SkillBlock;
