import React from 'react'
import './index.css'

export default () => (
  <div className="loading-spinner">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
)
