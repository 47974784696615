// @flow

import { Component } from 'react'
import { withPluginContext, constants } from '@djsp/core'
import { RichUtils } from 'draft-js'
import { COMMANDS } from '../constants'

class KeyboardShortcuts extends Component {
  constructor(props) {
    super(props)

    const { registerPlugin, setEditorState } = this.props

    this._unregister = registerPlugin({
      handleKeyCommand: command => {
        const { editorState } = this.props

        if ([COMMANDS.bold, COMMANDS.italic].includes(command)) {
          setEditorState(
            RichUtils.toggleInlineStyle(editorState, command.toUpperCase())
          )
          return constants.HANDLED
        }

        return constants.NOT_HANDLED
      }
    })
  }

  componentWillUnmount() {
    this._unregister()
  }

  render() {
    return null
  }
}

export default withPluginContext(KeyboardShortcuts)
