import React, { Component } from 'react';
import './create-account-modal.css';
import ModalX from '../../assets/svg/modal-x.js';
import AnimatingSpinnerButton from '../../assets/svg/animating-spinner-button.js'
import { Auth } from "aws-amplify";
import Backend from "../backend.js";
import Users from "../../data/user.js";

class CreateAccountModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isWaiting: false,
        isFirstNameError:false,
        isLastNameError:false,
        isEmailError:false,
        isPasswordError:false,
        isErrorMessageShown:false,
        errorMessage: "Invalid email or password",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
      };
      this.switchToLogIn = this.switchToLogIn.bind(this);
      this.onSignUpClick = this.onSignUpClick.bind(this);
    }

    switchToLogIn() {
        this.props.toggleLogInModal();
        this.props.toggleCreateAccountModal();
    }

    validateForm() {
      return this.state.email.length > 0 && this.state.password.length > 0 && this.state.firstName.length > 0 && this.state.lastName.length > 0;
    }

    async onSignUpClick() {
      if (!this.validateForm()) {
        return;
      }

      if (this.state.password.length < 8) {
        message = "Password must be at least 8 characters long."
        this.setState({isErrorMessageShown: true, errorMessage: message});
        return;
      }
      this.setState({isWaiting: true});
      try {
        let serverUp = await Backend.serverUp();
        if (!serverUp) {
          this.setState({isErrorMessageShown: true, errorMessage: "There was an error creating your account. Please try again or contact us."});
          return;
        }
        console.log("signing up")
        const newUser = await Auth.signUp({
          username: this.state.email,
          password: this.state.password
        });
        console.log("got cognito: ", newUser)
        let userSignedIn = await Auth.signIn(this.state.email, this.state.password);
        let userInfo = {"email": this.state.email,
                        "userId": userSignedIn.username,
                        "firstName": this.state.firstName,
                        "lastName": this.state.lastName,
                      }
        let response = await Backend.createUser(userInfo);
        console.log("whole user response: ", response);
        if (response.status == "success") {
          Users.auth.userId = userSignedIn.username;
          Users.auth.username = response.username;
          Users.auth.firstName = this.state.firstName;
          Users.auth.lastName = this.state.lastName;
          await this.setAuthUserInfo(userSignedIn.username);
          console.log("USERAUTH USERNAME: ", Users.auth.username);

          let session = userSignedIn.signInUserSession;
          if (session) {
            console.log("user authenticated!!");
            //console.log("THE ACCESSTOKEN: ", session.accessToken);
            Users.auth["accessToken"] = session.accessToken
          }

          this.props.userHasAuthenticated(true, userSignedIn.username);
          this.props.history.push("/");
        } else {
          this.setState({isErrorMessageShown: true, errorMessage: "There was an error creating your account. Please try again or contact us."});
        }
      } catch (e) {
        var message = e.message
        if (e.message.includes("Password did not conform with policy:")) {
            message = e.message.substring("Password did not conform with policy:".length);
        }
        if (e.message == "Username should be an email.") {
          message = "Email is not formatted correctly";
        }
        if (e.message == "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6"
            || message == "Password not long enough") {
          message = "Password must be at least 8 characters long."
        }
        if (message == "Failed to fetch") {
          message = "There was an error creating your account. Please try again later or contact us."
        }
        this.setState({isErrorMessageShown: true, errorMessage: message});
      }
      this.setState({isWaiting: false});
    }

    async setAuthUserInfo(userId) {
      let fullUser = await Backend.fetchUser(userId);

      Users.auth["username"] = fullUser.username;
      Users.auth["firstName"] = fullUser.firstName;
      Users.auth["lastName"] = fullUser.lastName;
      Users.auth["email"] = fullUser.email;
      Users.auth["bio"] = fullUser.bio;
      Users.auth["callToActionLink"] = fullUser.callToActionLink;
      Users.auth["callToActionText"] = fullUser.callToActionText;
      for (var property in fullUser) {
          if (fullUser.hasOwnProperty(property)) {
            Users.auth[property] = fullUser[property]
          }
      }
    }

    onPasswordChange(value) {
      this.setState({password: value});
    }

    onEmailChange(value) {
      this.setState({email: value});
    }

    onLastNameChange(value) {
      this.setState({lastName: value.replace(/[^a-zA-Z- \s]+/g,'')});
    }

    onFirstNameChange(value) {
      this.setState({firstName: value.replace(/[^a-zA-Z- \s]+/g,'')});
    }


  render() {
    return (
        <div style={{visibility: this.props.isCreateAccountModalOpen? 'visible' : 'hidden', opacity:this.props.isCreateAccountModalOpen? '1' : '0'}} className='create-account-modal-overlay'>
          <div className='create-account-modal-container'>
            <div className='create-account-modal'>
              <div className='create-account-modal-responsive-container'>
              <div className='create-account-modal-top-bar'>
                <div className='create-account-modal-x-container'>
                  <div onClick={this.props.toggleCreateAccountModal} className='create-account-modal-x'>
                    <ModalX />
                  </div>
                </div>
              </div>

              <div className='create-account-modal-title-body'>
                <div className='create-account-modal-title-container'>
                  <div className='create-account-modal-title'>
                    <div className='create-account-modal-title-label'>
                      Create your account
                    </div>
                  </div>
                  <div className='create-account-modal-login-option'>
                    <div>
                    Already have an account? <span onClick={this.switchToLogIn} className='blue-log-in'>Log in</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='create-account-modal-scroll-container'>
                <div className='create-account-modal-section'>
                  <div className='create-account-modal-section-container'>
                    <div className='create-account-modal-section-row'>
                      <div className='create-account-modal-section-column-padding-right'>
                        <div className='create-account-modal-half-field-container'>
                          <input
                            placeholder='First name'
                            className='create-account-modal-half-field'
                            style={{borderColor:this.state.isFirstNameError?'#EF7879':'#EBEDF2'}}
                            value={this.state.firstName}
                            onChange={(event) => this.onFirstNameChange(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className='create-account-modal-section-column'>
                        <div className='create-account-modal-half-field-container'>
                          <input
                            placeholder='Last name'
                            className='create-account-modal-half-field'
                            style={{borderColor:this.state.isLastNameError?'#EF7879':'#EBEDF2'}}
                            value={this.state.lastName}
                            onChange={(event) => this.onLastNameChange(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='create-account-modal-section-row'>
                      <div className='create-account-modal-section-column'>
                        <div className='create-account-modal-field-container'>
                          <input
                            type='email'
                            placeholder='Email'
                            className='create-account-modal-field'
                            style={{borderColor:this.state.isEmailError?'#EF7879':'#EBEDF2'}}
                            value={this.state.email}
                            onChange={(event) => this.onEmailChange(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='create-account-modal-section-row'>
                      <div className='create-account-modal-section-column'>
                        <div className='create-account-modal-field-container'>
                          <input
                            className='create-account-modal-field'
                            type='password'
                            placeholder='Password'
                            style={{borderColor:this.state.isPasswordError?'#EF7879':'#EBEDF2'}}
                            value={this.state.password}
                            onChange={(event) => this.onPasswordChange(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                    {!this.state.isErrorMessageShown? null:
                      <div className='create-account-modal-section-row'>
                        <div className='create-account-modal-section-column'>
                          <div className='create-account-modal-error-message'>
                            {this.state.errorMessage}
                          </div>
                        </div>
                      </div>
                  }
                  </div>

                    <div className='create-account-modal-section-row'>
                      <div className='create-account-modal-section-column'>
                        <div className='create-account-accept-message'>
                          By clicking “Create account” you confirm you accept our <span onClick={()=> window.open('https://www.notion.so/openmind/Symbol-Terms-of-Service-ddbc390b70fd465f8c7a8077a1452abf','_blank')} className='underline'>Terms</span> & <span onClick={()=> window.open('https://www.notion.so/Symbol-Privacy-Policy-54cb653d8f66491389c123f59535a664','_blank')} className='underline'>Privacy Policy</span>.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                </div>

                <div className='create-account-modal-button-section'>
                  <div className='create-account-modal-button-section-container'>
                    <div onClick={this.onSignUpClick} className={!this.validateForm() ? 'create-account-modal-primary-button-disabled' : (!this.state.isWaiting?'create-account-modal-primary-button':'create-account-modal-primary-button-waiting')}>

                      <div className='create-account-modal-primary-button-label'>
                        {!this.state.isWaiting?
                          "Create account"
                          :
                          <AnimatingSpinnerButton
                            style={{marginTop:'4px'}}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>

            </div>
            </div>

          </div>
        </div>


    );
  }
}

export default CreateAccountModal;
