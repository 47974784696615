import Users from "../data/user.js"
import Images from "../img/images.js"
import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET
} from './editor/constants'

import {ENV_API} from './constants'
const config = {
  apiKey: "AIzaSyDbVmdAuf2oDt_j-uKcunA020C1P_41V50",
};

let DEV = "http://18.191.216.157"
let STAGE = "https://api-staging.symbol.co"
let STAGE3 = "http://symbol3-env.4etfmgpvps.us-west-2.elasticbeanstalk.com"
// let PROD = ""


const API = ENV_API;
//"http://symbol-staging-env.my55mapsej.us-west-2.elasticbeanstalk.com/";
const CORS_HEADER = "SYMBOL-HEADER"
const CORS_ORIGIN = "*"

export default class Backend {
  static auth;
  static updateFunctions = {}

  static async createNewProject(): Promise<void> {
    var data = new FormData();
    data.append("userId", Users.auth.userId);
    data.append("username", Users.auth.username);
    data.append("backgroundImage", Images[Math.floor(Math.random() * Images.length)]);

    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"])
    data.append("userId", Users.auth.userId);

    let response = await fetch(API + '/createNewProjectForUser', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response: ", result);
    return result;
  }

  static async fetchProjectsForUser(username): Promise<void> {
    console.log("username is: ", username);
    var params = "?username="+username;
    let url = API + "/getProjectsForUser" + params;
    let response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    return result
  }

  static async fetchProjectsForUserId(userId): Promise<void> {
    var params = "?userId="+userId;
    let url = API + "/getProjectsForUserId" + params;
    let response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    return result
  }

  static async getBestQueueForReviewer(reviewerUserId): Promise<void> {
    console.log("Getting best queue for reviewer: ", reviewerUserId);
    var params = "?reviewerUserId="+reviewerUserId;
    let url = API + "/getBestQueueForReviewer" + params;

    let response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    console.log("got the result: ", result)
    return result.skillProjectPairsReviewQueue
  }

  static async fetchUserByUsername(username): Promise<void> {
    console.log("FETCHING username is: ", username);
    var params = "?username="+username;
    let url = API + "/getUserByUsername" + params;
    let response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    var user = result.user;
    console.log("got the user: ", user)
    return user
  }

  static async fetchUser(userId): Promise<void> {
    console.log("FETCHING userID is: ", userId);
    var params = "?userId="+userId;
    let url = API + "/getUser" + params;
    let response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    var user = result.user;
    console.log("got the user: ", user)
    return user
  }

  static async fetchProject(projectId): Promise<void> {
    console.log("FETCHING projectId is: ", projectId);
    var params = "?projectId="+projectId;
    let url = API + "/getProject" + params;
    let response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    var project = result.project;
    console.log("backend got new project: ", project);

    //Backend.Project = project;
    //console.log("new backend project: ", Backend.Project)
    return result.project
  }

  static async getSignedUrlS3(file): Promise<void> {
    var params = "?filename="+file.name;
    params += "&userId="+Users.auth.userId;
    // var data = new FormData();
    // data.append("filename", file.name);
    let response = await fetch(API + '/getSignedUrlS3' + params, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    console.log("response: ", result);
    return result;
  }

  static async uploadPhotoToCloudinary(file, userId, type) {
    const url = `https://api.cloudinary.com/v1_1/${
        CLOUDINARY_CLOUD_NAME
    }/upload`;
    var data = new FormData();
    data.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    data.append("file", file);
    data.append('tags', `${type},${userId}`);
    data.append('context', `userId=${userId}`);
    let response = await fetch(url, {
          method: 'POST',
          body: data
        })
    const result = await response.json();
    return result;
  }

  static async uploadFileToS3(file): Promise<void> {
    var data = new FormData();
    data.append("file", file);
    data.append('userId', Users.auth.userId);
    data.append("user_file", file);
    let response = await fetch(API + '/uploadFileToS3', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "Access-Control-Request-Headers":CORS_HEADER
          },
          body: data
        })
    const result = await response.json();
    console.log("response: ", result);
    return result;
  }

  static async updateProjectPublishState(project, state, isOptInToQuantify, isOptInToQuantifyPublicly) {
    await this.updateProjectInfo(project, "publishState", state);
    // update all the skills
    // already doing this in the backend whenever a project is published.
    // if (state == "published") {
    //   var quantifyState = "waiting_to_assess"; //"not_quantified";
    //   var isPrivate = "private"; //!isOptInToQuantifyPublicly; //? "public" : "private";
    //   //await this.setAllSkillsStateAndPrivacyForProject(project.projectId, quantifyState, isPrivate);
    // }
    return "done"
  }

  static async updateProjectTitle(project, projectTitle) {
    return this.smartUpdateProjectInfo(project, "projectTitle", projectTitle);
  }

  static async updateProjectStartDate(project, startDate) {
    return this.smartUpdateProjectInfo(project, "startDate", startDate);
  }

  static async updateProjectEndDate(project, endDate) {
    return this.smartUpdateProjectInfo(project, "endDate", endDate);
  }

  static async updateProjectCompany(project, company, timestamp) {
    return this.smartUpdateProjectInfo(project, "company", company);
  }

  static async updateProjectRole(project, role) {
    return this.smartUpdateProjectInfo(project, "role", role);
  }

  static async updateProjectClient(project, client) {
    return this.smartUpdateProjectInfo(project, "client", client);
  }

  static async updateProjectLocation(project, location) {
    return this.smartUpdateProjectInfo(project, "location", location);
  }

  static async updateProjectDescription(project, description) {
    return this.smartUpdateProjectInfo(project, "description", description);
  }

  static async updateProjectProcess(project, projectProcessNodes) {
    return this.smartUpdateProjectInfo(project, "process", projectProcessNodes);
  }

  static async updateProjectOutcome(project, projectOutcomeNodes) {
    return this.smartUpdateProjectInfo(project, "outcome", projectOutcomeNodes);
  }

  static async updateProjectBackgroundImage(project, backgroundImage) {
    let result = await this.updateProjectInfo(project, "backgroundImage", backgroundImage);
    return result;
  }

  static async smartUpdateProjectInfo(project, key, value) {
    if (this.updateFunctions[key]) {
      clearTimeout(this.updateFunctions[key]);
    }
    await this.timeout(project, key, value);
    return "done";
  }

  static timeout(project, key, value) {
    return new Promise((resolve) => {
        this.updateFunctions[key] = setTimeout(function() {
          resolve(Backend.updateProjectInfo(project, key, value));
      }, 2000);
    });
  }

  static async addSkill(skillName, projectId, userId, projectPublishState): Promise<void> {
    var data = new FormData();
    data.append("skillName", skillName);
    data.append("projectId", projectId);
    data.append("userId", userId);
    data.append("publishState", projectPublishState);
    let jwtToken = Users.auth["accessToken"]["jwtToken"];
    console.log("userid sending: ", userId);
    console.log("THE JWT TOKEN SENDING: ", jwtToken);
    data.append("jwtToken", jwtToken);

    let response = await fetch(API + '/addNewSkillToProject', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response from adding new skill: ", result);
  }

  static async updateSkillAttribute(skillName, projectId, userId, publishState, attribute, value): Promise<void> {

    var data = new FormData();
    data.append("skillName", skillName);
    data.append("projectId", projectId);
    data.append("publishState", publishState);
    data.append("attribute", attribute);
    data.append("value", value);
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"])
    data.append("userId", userId);
    console.log("the valueu is: ", value);

    let response = await fetch(API + '/updateSkillAttribute', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response from updating skill attribute: ", result);
    console.log("this is the status: ", result.status);
    return result;
  }

  static async deleteSkill(skillName, projectId, userId, publishState): Promise<void> {
    var data = new FormData();
    data.append("skillName", skillName);
    data.append("projectId", projectId);
    data.append("publishState", publishState);
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"])
    data.append("userId", userId);

    let response = await fetch(API + '/deleteSkill', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response from DELETING skill: ", result);
    return result;
  }

  static async updateProjectInfo(project, key, value) {
    console.log("upating project: ", key, value);
    var data = new FormData();
    data.append("key", key);
    data.append("value", value);
    data.append("projectId", project.projectId);
    data.append("publishState", project.publishState);
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"])
    data.append("userId", project.creatorId);

    console.log("SENDING THIS PUBLISHSTATE: ", project.publishState);

    let response = await fetch(API + '/updateProject', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response: ", result);
    console.log("this is the status: ", result.status);
  }

  static async deleteProject(project) {
    var data = new FormData();
    data.append("projectId", project.projectId);
    let jwtToken = Users.auth["accessToken"]["jwtToken"];
    data.append("jwtToken", jwtToken)
    data.append("userId", project.creatorId);

    let response = await fetch(API + '/deleteProject', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response: ", result);
    return result
  }

  static async saveProfileInfo(userInfo) {
    console.log("upating user: ", userInfo);
    var data = new FormData();

    data.append("userId", Users.auth.userId);
    data.append("prevUsername", userInfo.prevUsername);
    data.append("username", userInfo.username);
    data.append("firstName", userInfo.firstName);
    data.append("lastName", userInfo.lastName);
    data.append("bio", userInfo.bio);
    data.append("callToActionText", userInfo.callToActionText);
    data.append("callToActionLink", userInfo.callToActionLink);
    data.append("profilePhoto", userInfo.profilePhoto);
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"]);

    let response = await fetch(API + '/updateUser', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response: ", result);
    return result
  }

  static async createUser(userInfo) {
    console.log("creating user: ", userInfo);
    var data = new FormData();

    data.append("userId", userInfo.userId);
    data.append("firstName", userInfo.firstName);
    data.append("lastName", userInfo.lastName);
    data.append("email", userInfo.email);

    let response = await fetch(API + '/createUser', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response: ", result);
    return result
  }

  // not used anymore.
  static async setAllSkillsStateAndPrivacyForProject(projectId, state, privacy) {
    var data = new FormData();
    data.append("state", state);
    data.append("private", privacy);
    data.append("projectId", projectId);
    console.log("setting skills state and privacy");
    let response = await fetch(API + '/setAllSkillsStateAndPrivacyForProject', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response: ", result);
    if (result == "success") {
      //Backend.Project[""]
    }
    return result
  }

  static async submitComparison(reviewerId, skillIdentifier, betterProjectId, otherProjectId, revProjId, revProjSkillName, revNum, targetSkill): Promise<void> {
    var data = new FormData();
    data.append("otherProjectId", otherProjectId);
    data.append("betterProjectId", betterProjectId);
    data.append("reviewerId", reviewerId);
    data.append("skillIdentifier", skillIdentifier);
    data.append("revProjId", revProjId);
    data.append("revSkillname", revProjSkillName);
    data.append("revNum", revNum);
    targetSkill = JSON.stringify(targetSkill);
    data.append("targetSkill", targetSkill);

    console.log("sending project ids: ", betterProjectId, otherProjectId)
    // revProjId = request.form['revProjId']
    // revSkillName = request.form['revSkillName']
    // revNum = request.form['revNum']

    let response = await fetch(API + '/compare', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })

    const result = await response.json();
    console.log("COMPARISON response: ", result);
    return result
  }

  static async serverUp(): Promise<void> {
    let response = await fetch(API  + '/up', {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    console.log("Is server up? ", result);
    return result.up == "yes"
  }

  static async fetchOtherSkillToReview(userId, skillsExhausted): Promise<void> {
    var params = "?userId="+userId;
    skillsExhausted = JSON.stringify(skillsExhausted);
    params += "&skillsExhausted="+skillsExhausted;

    let response = await fetch(API  + '/getOtherSkillToReview' + params, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    return result;
  }

  static async fetchExistingSkillsByPopularity(): Promise<void> {
    let response = await fetch(API  + '/getExistingSkillsByPopularity', {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN,
          },
        })
    const result = await response.json();
    return result;
  }

  static async getTwoProjects(reviewerId, skillName, targetSkill): Promise<void> {
    var params = "?reviewerId="+reviewerId;
    params += "&skillName="+skillName;
    targetSkill = JSON.stringify(targetSkill);
    params += "&targetSkill="+targetSkill;

    let response = await fetch(API  + '/gettwoprojects' + params, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN,
          },
        })

    const result = await response.json();
    console.log("THE TWO PROJECTSS: ", result);
    return result;
  }

}
