import React from 'react';
import BGImageGalleryItem from './bg-image-gallery-item.js'
import './bg-image-gallery.css';


const BGImageGallery = props => {

  const images = props.images.map(image => {
    return (
      <div onClick={() => props.setTempBackgroundImage(image)} className='image-wrapper'>
        <BGImageGalleryItem
          image={image}
          />
      </div>
    );
  });

function applyImage(){
    props.setBackgroundImage(props.tempBackgroundImage)
    props.toggleIsGalleryOpen()
}

  return (
    <div>
      <div className='edit-header-action-bar'>
        <div className='edit-header-action-bar-container'>
          <div className='edit-header-action-bar-button-container'>
            <div onClick={props.toggleIsGalleryOpen} className='edit-header-action-bar-cancel-button'>
              <div className='edit-header-action-bar-cancel-button-label'>
                Cancel
              </div>
            </div>
            <div onClick={applyImage} className='edit-header-action-bar-apply-button'>
              <div className='edit-header-action-bar-apply-button-label'>
                Apply
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='gallery'>
        <div className='gallery-container'>
          {images}
        </div>
      </div>
      <div className='gallery-overlay'></div>
    </div>
  );
};

export default BGImageGallery;
