import React from 'react'

export default ({ onClick }) => (
  <button className="side-toolbar__button" onMouseDown={onClick}>
    <svg width="24px" height="25px" viewBox="0 0 24 25">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-515.000000, -1199.000000)" fill="#242424" fillRule="nonzero">
          <g transform="translate(314.000000, 1183.000000)">
            <g transform="translate(54.600000, 0.000000)">
              <g id="link-symbol" transform="translate(146.400000, 16.800000)">
                <path d="M22.1348505,10.8740767 L17.6294835,15.3779536 C15.1428801,17.8660165 11.1089763,17.8660165 8.6219528,15.3779536 C8.23020459,14.9873099 7.92373776,14.5471465 7.65460778,14.0932247 L9.7479926,12.0000066 C9.84750505,11.8996543 9.97038586,11.8421527 10.0878053,11.774411 C10.2325316,12.2688728 10.4851724,12.7369731 10.8740324,13.1258839 C12.1158637,14.3687075 14.1365703,14.3669746 15.3776665,13.1258839 L19.8816156,8.62211199 C21.1243922,7.37944589 21.1243922,5.35922075 19.8816156,4.11749988 C18.640467,2.87577901 16.6202855,2.87577901 15.3776665,4.11749988 L13.7760675,5.72082483 C12.4763666,5.21475767 11.0761031,5.07869665 9.71296632,5.27845586 L13.1258494,1.8656927 C15.6139232,-0.621897566 19.6467768,-0.621897566 22.1348505,1.8656927 C24.6217165,4.35317794 24.6217165,8.38664399 22.1348505,10.8740767 Z M10.2246546,18.279976 L8.62190029,19.8833009 C7.38017398,21.1243917 5.35936236,21.1243917 4.117426,19.8833009 C2.87559467,18.6404773 2.87559467,16.6202522 4.117426,15.3779536 L8.62190029,10.8740767 C9.86462434,9.63151564 11.8840706,9.63151564 13.1257969,10.8740767 C13.5137642,11.2621473 13.7666676,11.7301426 13.9127592,12.2239742 C14.0308613,12.1553398 14.1522193,12.0995711 14.2516792,11.9999541 L16.3449065,9.90757614 C16.0775094,9.45186889 15.7694672,9.01328085 15.3775615,8.62221702 C12.8911681,6.13462676 8.8568967,6.13462676 6.36929555,8.62221702 L1.86550394,13.1261465 C-0.621834646,15.6144719 -0.621834646,19.6467827 1.86550394,22.1348981 C4.35310509,24.6217006 8.38643125,24.6217006 10.8739799,22.1348981 L14.2877557,18.7213997 C12.9238838,18.9223142 11.5232527,18.7850979 10.2246546,18.279976 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
)
