// @flow

import React, { Component } from 'react'
import { EditorState, Modifier } from 'draft-js'
import AtomicBlock from '@djsp/atomic-block'
import { withPluginContext } from '@djsp/core'
import { getBlockEntityKey } from '@djsp/utils'
import LargeButton from './LargeButton'
// import LargeButton from './StandardButton'
import StandardButton from './StandardButton'

class _ImageBlock extends Component {
  setIsLarge = isLarge => {
    const { block, editorState, contentState, setEditorState } = this.props
    const entity = getBlockEntityKey(contentState, block.getKey())

    if (entity) {
      setEditorState(EditorState.push(
        editorState,
        contentState.mergeEntityData(
          entity,
          {
            isLarge
          }
        )
      ))
    }
  }

  render() {
    const { editorState, isFocused, blockProps: {
      isLarge,
      secure_url,
      original_filename
    } } = this.props

    let blockClass = 'block block--image'
    if (isFocused) blockClass+= ' block--selected'
    if (isLarge) blockClass+= ' block--large'

    return <div className={blockClass}>
      {isFocused && <div className="image-size-buttons">
        <div className="toolbar">
          <StandardButton
            isActive={!isLarge}
            onClick={() => this.setIsLarge(false)}
          />
          <LargeButton
            isActive={isLarge}
            onClick={() => this.setIsLarge(true)}
          />
        </div>
      </div>}
      <img alt={original_filename} src={secure_url} />
    </div>
  }
}

const ImageBlock = withPluginContext(_ImageBlock)

export default () => <AtomicBlock type="image">{(props) => <ImageBlock {...props} />}</AtomicBlock>
