import React from 'react'

export default ({ onClick }) => (
  <button className="side-toolbar__button" onMouseDown={onClick}>
    <svg width="20px" viewBox="0 0 17 19">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-456.000000, -1203.000000)" fill="#242424" fillRule="nonzero">
          <g transform="translate(314.000000, 1183.000000)">
            <g transform="translate(54.600000, 0.000000)">
              <g transform="translate(87.600000, 20.400000)">
                <path d="M15.4889805,8.20619878 L2.21035874,0.633824586 C1.00030408,-0.056941952 0.0242816173,0.495323842 0.0310651591,1.86390702 L0.0990620908,16.8943173 C0.105199581,18.2625846 1.093174,18.8195882 2.30678195,18.1341911 L15.483812,10.695422 C16.6961279,10.0112883 16.6987121,8.89696532 15.4889805,8.20619878 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
)
