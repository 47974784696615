import React, { Component } from 'react';
import './top-bar-branding.css';


class TopBarBranding extends Component {
  constructor(props) {
      super(props);
      this.state = {
      };

    }

  render() {
    return (
        <div>
          <div className='top-bar-branding'>
            <div className='top-bar-branding-container'>
                <div onClick={()=> window.open('/homepage', "_blank")} className='top-bar-branding-cta'>
                  Create your own profile on Symbol →
                </div>
            </div>
          </div>
          <div className='top-bar-branding-pushdown'></div>

        </div>



    );
  }
}

export default TopBarBranding;
