let Faqs = [

  {
    "question": "1 - Why do I need to submit peer reviews?",
    "answer": "In order for your skills to be assessed and scored, we ask other people on the network to review your case studies. To ensure there are enough reviewers, we ask everyone who submits a case study (including you!) to also submit peer reviews. Once you submit enough reviews for your peers, we'll make sure there are enough peers submitting reviews for you. By participating in the community in this way, you're able to get your skills scored."
  },

  {
    "question": "2 - How do reviews work?",
    "answer": "Once you start reviews, we'll show you two anonymous case studies at a time, side-by-side. We will ask you to read through both case studies and to select (based on the information presented in the case studies) which person demonstrates more expertise in a particular skill. Once you select a person and submit the pair, we'll show you another pair. We'll keep showing you pairs until you've finished all the necessary reviews."
  },


  {
    "question": "3 - How many peer reviews do I need to submit?",
    "answer": "The number of required reviews depends on the number of skills you are looking to get scored. We'll keep track for you and show you your progress, so you can just keep going until we let you know you've finished. To give you a sense, each skill for each case study requires around 30 reviews. While it can take some time to finish these reviews in a quality manner, the result will be quantitative proof of your skills (with over 95% accurary), which is pretty cool. Plus, reviews are often quite fun, especially since we'll attempt to show your peers who around your skill level, so plenty of opportunities to draw inspiration."
  },

  {
    "question": "4 - What do you do with the reviews?",
    "answer": "We take all the peer reviews, process them through our algorithm, and determine the rank of each person who was reviewed. Even though a single review can be subjective, when taken together, we are able to calculate percentile scores for skills with over 95% accuracy. Math is pretty cool like that."
  },


  {
    "question": "5 - How do you decide which pairs to show me for peer review?",
    "answer": "We try to show you the case studies of people with the same skills and who are around your same skill level. We'll show you case studies for your most popular skills first, followed by your less popular skills. We do this to minimize the amount of time it takes for you to get your scores back."
  },

  {
    "question": "6 - What is my Review Quality?",
    "answer": "We measure your Review Quality over time, which is simply a score we assign to you based on the level of quality of your reviews. We're able to measure the quality of your reviews using a number of different methods, like, for example, how often your reviews match the consensus on the network once a score has converged. The higher your Review Quality, the fewer reviews you need to submit per skill. The lower your Review Quality, the more you'll need to do. If your Review Quality becomes too low, we likely won't be able to count any of your reviews. As long as you are being thoughtful in the review process and doing your best, this will almost certainly not happen."
  },

  {
    "question": "7 - Can I come back later?",
    "answer": "Yes, it's not necessary to complete all your peer reviews at once. You can leave in the middle and come back later. With that said, often times, reviewers find it easiest to block off a chunk of time and build momentum. It's totally up to you though."
  },

  {
    "question": "8 - Who sees the reviews I make?",
    "answer": "Peer reviews are anonymous on both ends - you don't know who you are reviewing and they don't know you specifically are reviewing them. We do keep track though of the quality of your reviewing, and may make your Review Quality public on your profile in the future (we'll let you know)."
  },






]

export default Faqs;
