import React from 'react'

const SkillBlockX = props => (
  <svg width={9} height={9} {...props}>
    <g
      stroke= "#C3C3C3"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M1.583 1.583l5.834 5.834M7.417 1.583L1.583 7.417" />
    </g>
  </svg>
)

export default SkillBlockX
