import React from "react"

const HideCommandBarArrow = () => (
  <svg width={12} height={13}>
    <g
      stroke="#FFFFFF"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M1.833 6.167L6 2M10.167 6.167L6 2M2 12h8M6 8V2.929" />
    </g>
  </svg>
)

export default HideCommandBarArrow
