import React from 'react'

export default ({ isActive, onClick  }) =>
  <button
    className={`toolbar__button${isActive != null ? ' toolbar__button--active': ''}`}
    onClick={onClick}>
    <svg height={27} viewBox="0 0 31 26">
      <path
        d="M3.76.917h23.289v2.928H3.76V.917zm0 22h23.289v2.928H3.76v-2.928zm-3.5-17h30.081v15.615H.261V5.917z"
        fill={`${isActive ? '#3C93FF' : '#000'}`}
        fillRule="evenodd"
      />
    </svg>

  </button>
