import React from 'react'

export default ({ className, onClick }) => (
  <button className={className} onMouseDown={onClick}>
  <svg width={11} height={11}>
    <path
      d="M4.808 9.832V6.064H1.04a.472.472 0 0 1-.456-.456v-.672c0-.24.216-.456.456-.456h3.768V.688c0-.24.216-.432.456-.432h.672c.24 0 .456.192.456.432V4.48h3.792c.24 0 .432.216.432.456v.672c0 .24-.192.456-.432.456H6.392v3.768c0 .24-.216.432-.456.432h-.672c-.24 0-.456-.192-.456-.432z"
      fill="#242424"
      fillRule="evenodd"
    />
  </svg>
  </button>
)
