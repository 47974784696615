
export const ENV = "PROD_ENV" //DEV_ENV or PROD_ENV

export const DEV_API = "http://18.191.216.157"
export const STAGE_API = "https://api-staging.symbol.co"
export const STAGE3 = "http://symbol3-env.4etfmgpvps.us-west-2.elasticbeanstalk.com"


export const DEV_ENV = "DEV_ENV"
export const PROD_ENV = "PROD_ENV"

export const ENV_API = ENV == DEV_ENV ? DEV_API : STAGE_API

export const ENV_LOGS_OFF = ENV !== DEV_ENV

/* COGNITO CONFIGURATIONS */
export const COGNITO_REGION = "us-east-2"
export const COGNITO_PROD_USER_POOL_ID = "us-east-2_pm8eoB6Q0"
export const COGNITO_PROD_APP_CLIENT_ID = "17gaadu9agk2d6kuud0u7g6unf"

export const COGNITO_DEV_USER_POOL_ID = "us-east-2_rjowMP6jl"
export const COGNITO_DEV_APP_CLIENT_ID = "35vigoajrotg36sj5phr0ljgm7"

export const COGNITO_USER_POOL_ID = ENV == DEV_ENV ? COGNITO_DEV_USER_POOL_ID : COGNITO_PROD_USER_POOL_ID
export const COGNITO_APP_CLIENT_ID = ENV == DEV_ENV ? COGNITO_DEV_APP_CLIENT_ID : COGNITO_PROD_APP_CLIENT_ID
