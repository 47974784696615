import React, { Component } from 'react'
import './index.css'
import ToggleButton from './ToggleButton'
import ImageButton from './ImageButton'
import VideoButton from './VideoButton'
import EmbedButton from './EmbedButton'
import OutsideClickHandler from 'react-outside-click-handler'

export default class SideToolbar extends Component {
  state = {
    isExpanded: false
  }

  collapse = cb => (...args) => {
    this.setState({ isExpanded: false })
    cb(...args)
  }

  render() {
    const { isExpanded } = this.state
    const { onClickEmbed, onUploadImage, hasFocus } = this.props

    if (!hasFocus && !isExpanded) {
      return null
    } else {
      return <OutsideClickHandler onOutsideClick={() => this.setState({ isExpanded: false })}>
        <aside className="side-toolbar">
          <ToggleButton
            onClick={() => this.setState({ isExpanded: !isExpanded })}
            className={`side-toolbar-toggle${isExpanded ? ' expanded' : ''}`}
          />
        {isExpanded && <div className="side-toolbar-buttons">
          <ImageButton onUploadImage={this.collapse(onUploadImage)} />
          <VideoButton onClick={this.collapse(onClickEmbed)} />
          <EmbedButton onClick={this.collapse(onClickEmbed)} />
        </div>}
        </aside>
      </OutsideClickHandler>
    }
  }
}
