// @flow

import React, { Component, PureComponent } from 'react'
import EventListener from 'react-event-listener'
import InlineStyleToggle from '@djsp/inline-style-toggle'
import Popover from 'react-text-selection-popover'
import BlockTypeToggle from '@djsp/block-type-toggle'
import OutsideClickHandler from 'react-outside-click-handler'
import { withPluginContext } from '@djsp/core'
import type { Element } from 'react'
import {
  collapseToEnd,
  removeLinkAtSelection,
  getCurrentEntity,
  createLinkAtSelection,
  hasEntity,
} from '@djsp/utils'

const LinkButton = ({
  onClick,
  children,
  editorState,
}) => {
  const isEnabled = hasEntity(editorState, 'LINK')

  const className = `toolbar__button${
    isEnabled === true ? ' toolbar__button--active' : ''
  }`

  return <button
    className={className}
    onMouseDown={e => {
      e.preventDefault()
      onClick()
    }}
    >{children}</button>
}

const InlineButton = ({
  inlineStyle,
  children,
}: {
  inlineStyle: string,
  children: Element,
}) => (
  <InlineStyleToggle inlineStyle={inlineStyle}>
    {({ toggleStyle, hasStyle }) => {
      const className = `toolbar__button${
        hasStyle === true ? ' toolbar__button--active' : ''
      }`

      return (
        <button
          className={className}
          onMouseDown={e => {
            e.preventDefault()
            toggleStyle()
          }}>
          {children}
        </button>
      )
    }}
  </InlineStyleToggle>
)

const BlockTypeButton = ({
  blockType,
  children,
}: {
  blockType: string,
  children: React.Element,
}) => (
  <BlockTypeToggle blockType={blockType}>
    {({ toggleBlockType, hasBlockType }) => {
      const className = `toolbar__button${
        hasBlockType === true ? ' toolbar__button--active' : ''
      }`

      return (
        <button
          className={className}
          onMouseDown={e => {
            e.preventDefault()
            toggleBlockType()
          }}>
          {children}
        </button>
      )
    }}
  </BlockTypeToggle>
)

class LinkModal extends PureComponent {
  handleReturn = ev => {
    if (ev.which === 13) {
      this.props.onPressReturn();
    }
  };

  componentDidMount() {
    if (this.props.value.length < 1) {
      // fixes jump
      // TODO: If editor is inside container,
      // we need to adjust scroll position of container
      // rather than window
      const scroll = window.scrollY
      this.inputRef.focus();
      window.scrollTo(0, scroll)
      this.inputRef.selectionStart = this.inputRef.selectionEnd = this.inputRef.value.length;
    }
  }

  render() {
    return (
      <div>
        <input
          type="text"
          ref={el => {
            this.inputRef = el;
          }}
          onKeyUp={this.handleReturn}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <button className="toolbar__button-small" onClick={this.props.onRemove}>&#10005;</button>
      </div>
    );
  }
}

class InlineToolbar extends PureComponent {
  state = {
    isLinkModalOpen: false,
    currentLink: null,
    isFocused: false
  };

  onChangeLinkText = ev =>
    this.setState({
      currentLink: ev.target.value
    });

  onRemoveLink = () => {
    this.setState({
      currentLink: null,
      isLinkModalOpen: false
    });

    this.onChange(
      collapseToEnd(removeLinkAtSelection(this.props.editorState))
    );
  };

  getCurrentLink = (editorState = this.props.editorState) => {
    const entity = getCurrentEntity(editorState);
    return entity ? entity.getData().url : "";
  };

  openLinkModal = e => {
    if (e && e.preventDefault) e.preventDefault();

    this.setState({
      currentLink: this.getCurrentLink(),
      isLinkModalOpen: true
    });
  };

  onChange = editorState => {
    this.props.setEditorState(editorState);
  };

  closeLinkModal = e => {
    if (e && e.preventDefault) e.preventDefault();

    this.setState({
      currentLink: null,
      isLinkModalOpen: false
    });
  };

  onSubmitLink = () => {
    let { currentLink } = this.state;

    if (currentLink != null && currentLink.trim().length > 0) {
      if (currentLink != null && currentLink.trim().length > 0) {
        if (!currentLink.includes('mailto:') && !currentLink.includes('http')) {
          currentLink = "http://" + currentLink
        }}
      this.onChange(
        collapseToEnd(
          createLinkAtSelection(this.props.editorState, currentLink)
        )
      );
    }

    this.setState({
      isLinkModalOpen: false
    });
  };

  setIsFocused = () => {
    const selection = window.getSelection();

    this.setState({ isFocused: this.props.selectionRef.current
      && this.props.selectionRef.current.contains(selection.anchorNode)
      && this.props.selectionRef.current.contains(selection.focusNode)
    });
  };

  render() {
    if (!this.props.editorState) return null

    const isCollapsed = this.props.editorState.getSelection().isCollapsed();

    const isLinkModalOpen =
      (hasEntity(this.props.editorState, "LINK") && !isCollapsed) ||
      this.state.isLinkModalOpen;

    const currentLink =
      this.state.currentLink != null
        ? this.state.currentLink
        : this.getCurrentLink();

    const { isFocused } = this.state;

    return (
      <div>
        <EventListener
          target={document}
          onSelectionChange={this.setIsFocused}
        />
        <Popover
          className="toolbar"
          selectionRef={this.props.selectionRef}
          isOpen={!isCollapsed && !isLinkModalOpen && isFocused}
        >
        <InlineButton inlineStyle="BOLD"><span className='bold'>B</span></InlineButton>
        <InlineButton inlineStyle="ITALIC"><em>i</em></InlineButton>
          <LinkButton
            onClick={this.openLinkModal}
            editorState={this.props.editorState}>
            <span className='link-underline-styling'>Link</span>
          </LinkButton>

        </Popover>
        <Popover
          className="toolbar"
          isOpen={isLinkModalOpen}
          selectionRef={this.props.selectionRef}
        >
          <OutsideClickHandler onOutsideClick={this.onSubmitLink}>
            <LinkModal
              onChange={this.onChangeLinkText}
              onRemove={this.onRemoveLink}
              onPressReturn={this.onSubmitLink}
              value={currentLink}
            />
          </OutsideClickHandler>
        </Popover>
      </div>
    );
  }
}


export default withPluginContext(InlineToolbar)
