import React from 'react';
import './bg-image-gallery-item.css';

const BGImageGalleryItem = (props) => {
  return (
      <div className="image">
        <img src={props.image + '&w=288'} />
      </div>
  )
}

export default BGImageGalleryItem;
