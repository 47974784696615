import React, { Component } from 'react';
import './bottom-badge.css';


class BottomBadge extends Component {
  constructor(props) {
      super(props);
      this.state = {
      };

    }

  render() {
    return (
      <div style={{visibility:this.props.isAuth == this.props.onUser?"hidden":"visible"}} className='bottom-badge-container'>
        <div className='bottom-badge'>
          <div onClick={()=> {this.props.isAuth?window.open('/', "_blank"):window.open('/homepage', "_blank")}} className='top-bar-branding-cta'>
            {this.props.isAuth?"Go to your Symbol profile →": "Create your own profile on Symbol →"}
          </div>
        </div>
      </div>


    );
  }
}

export default BottomBadge;
