import React from "react"

const PublishAirplaneIcon = () => (
  <svg width={21} height={22}>
    <path
      d="M20.893.08a.307.307 0 0 0-.34-.05L.183 9.77a.324.324 0 0 0-.182.29.324.324 0 0 0 .176.293l5.765 2.912a.307.307 0 0 0 .326-.032l5.606-4.31-4.4 4.657a.329.329 0 0 0-.089.25l.438 5.872c.01.13.095.241.215.282a.307.307 0 0 0 .336-.096l3.06-3.649 3.783 1.86c.082.04.177.042.26.005a.32.32 0 0 0 .175-.2L20.986.42a.33.33 0 0 0-.093-.34z"
      fill="#FFFFFF"
      fillRule="nonzero"
    />
  </svg>
)

export default PublishAirplaneIcon
