import React, { Component } from 'react';
import './add-skill-modal.css';
import ReactAutocomplete from 'react-autocomplete';
import SkillDictionary from '../../data/skill-dictionary.js';
import ModalX from '../../assets/svg/modal-x.js';
import Backend from '../backend.js';
import AnimatingSpinnerButton from '../../assets/svg/animating-spinner-button.js'
import convertToDisplaySkill from '../skills/convert-to-display-skill.js'


class AddSkillModal extends Component {

  constructor(props) {
      super(props);
      this.state={
        value: '',
        isWarningMessageHidden:true,
      }
      this.toggleIsWarningMessageHidden = this.toggleIsWarningMessageHidden.bind(this);
      this.onSkillTypeChange = this.onSkillTypeChange.bind(this);
    }

  toggleIsWarningMessageHidden() {
    this.setState(state => ({
      isWarningMessageHidden: !this.state.isWarningMessageHidden
    }));
  }

  filterForUniqueSkills(skills) {
    var seen = {};
    return skills.filter(function(item) {
        return seen.hasOwnProperty(item["skillName"]) ? false : (seen[item["skillName"]] = true);
    });
  }

  filterForNonAddedSkills(skills) {
    var seen = {};
    let projectSkills = this.props.project.skills;
    if (this.props.hasEdits) {
      projectSkills = this.props.project.edits.skills;
    }
    return skills.filter(function(item) {
        return !projectSkills.hasOwnProperty(item["skillName"]);
    });
  }

  skillsToChoose() {
    let existingSkillsByPopularity = this.props.existingSkillsByPopularity.map((skill) => {
      return {"skillName": skill}
    });
    let skillsToChoose = existingSkillsByPopularity.concat(SkillDictionary);
    skillsToChoose = this.filterForUniqueSkills(skillsToChoose);
    skillsToChoose = this.filterForNonAddedSkills(skillsToChoose);
    skillsToChoose = skillsToChoose.map((skill) => {
      let skillName = convertToDisplaySkill(skill["skillName"])
      return {"skillName":skillName}

    });
    console.log('skillsToCHoose',skillsToChoose)
    return skillsToChoose;
  }

  onSkillTypeChange(skillName) {
    skillName = skillName.replace(/[^a-zA-Z-\d \s /\*/ /\_/]+/g,'') // allow slash
    // skillName = skillName.replace(/[^a-zA-Z-\d \s]+/g,'')
    this.setState({value: skillName});
    let typedLength = skillName.length;
    let skillsToChoose = this.skillsToChoose().map((skill) => skill.skillName.toLowerCase().slice(0,typedLength));
    let indexOfSkill = skillsToChoose.indexOf(skillName.toLowerCase());
    let showWarningMessage = indexOfSkill == -1 && typedLength != 0;
    this.setState({isWarningMessageHidden: !showWarningMessage});

  }

  convertToRawSkill(value) {
    // return value
    return value.replace(/\s/g,'_').replace(/\//g,'*')
  }

  render() {
    let skillsToChoose = this.skillsToChoose();
    const DropdownStyle = {
      borderRadius: '100px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 1)',
      paddingTop: '30px',
      width:'100px',
      padding: '2px 0',
      fontSize: '16px',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '50%',
      zIndex:'6000'
    }
    let menuStyle = {
      borderRadius: '5px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 1)',
      width:'292px',
      // paddingTop:'11px',
      // paddingBottom:'11px',
      fontSize: '16px',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '48.5%',
      minWidth: '!important 292px',
      // border: '1px solid #EBEDF2',
      boxShadow: '0 9px 34px 0 rgba(0,0,0,0.08)',
      zIndex:'5001',
      boxShadow:'inset 0px 0px 0px 1px #EBEDF2',
    }

    return (
      <div className='overlay'>
        <div className='add-skill-modal-container'>
          <div className='add-skill-modal'>
            <div className='add-skill-modal-top-bar'>
              <div className='add-skill-modal-x-container'>
                <div onClick={this.props.toggleAddSkillModal} className='add-skill-modal-x'>
                  <ModalX />
                </div>
              </div>
            </div>
            <div className='add-skill-modal-main-body'>
              <div className='add-skill-modal-title-container'>
                <div className='add-skill-modal-title'>
                  <div className='add-skill-modal-title-label'>
                    Add skill
                  </div>
                </div>
              </div>

              {/* AUTOCOMPLETE INPUT SECTION  */}

              <div className='add-skill-input-field-container'>

                <ReactAutocomplete

                  items={skillsToChoose}

                  autoHighlight={true}

                  inputProps={{placeholder:'Type to add skill', maxLength:'30'}}

                  menuStyle={menuStyle}

                  shouldItemRender={(item, value) => item.skillName.toLowerCase().indexOf(value.toLowerCase()) > -1}
                  getItemValue={item => convertToDisplaySkill(item.skillName)}
                  renderItem={(item, highlighted) =>
                    <div
                      key={"item.skillName"}
                      style={{
                        padding: '6px 31px',
                        backgroundColor: highlighted ? 'transparent' : 'transparent',
                        color: highlighted ? '#348EFF' : '#242424'
                      }}
                    >
                      {convertToDisplaySkill(item.skillName)}
                    </div>
                  }
                  value={convertToDisplaySkill(this.state.value)}
                  onChange={e => this.onSkillTypeChange(e.target.value)}
                  onSelect={value => this.onSkillTypeChange(value)}
                />

              </div>

              {this.state.isWarningMessageHidden? null :

                <div className='new-skill-warning-message-container'>
                  <div className='new-skill-warning-message'>
                    <div className='new-skill-warning-message-text'>
                      You are the first person with this skill, so double check to make sure it’s spelt correctly and doesn’t already exist. If you’re sure, you can create the new skill. Just so you know though, the more popular the skill, the faster we are able to calculate a score.
                    </div>
                  </div>
                </div>
            }

            </div>

            <div className='add-skill-modal-button-section'>
              <div className='add-skill-modal-button-section-container'>
                <div onClick={this.props.toggleAddSkillModal} className='add-skill-modal-close-button'>
                  <div className='add-skill-modal-close-button-label'>
                    Close
                  </div>
                </div>
                <div onClick={() => this.props.onAddSkillClick(this.convertToRawSkill(this.state.value))} className={this.state.value.trim().length > 0 ? 'add-skill-modal-add-skill-button' : 'add-skill-modal-add-skill-button-disabled'}>
                  {!this.state.isWaiting?
                    <div className='add-skill-modal-add-skill-button-label'>
                      Add skill
                    </div>

                    :

                    <AnimatingSpinnerButton
                      style={{marginTop:'15px'}}
                    />
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddSkillModal;
