import React, { Component } from 'react';
import './review-lander.css';
import Users from "../../data/user.js";
import ProjectBlock from '../user/project-block.js'
import Backend from "../backend.js";
import { Auth } from "aws-amplify";
import Projects from "../../data/projects.js";
import BackArrowDark from '../../assets/svg/back-arrow-dark.js';
import FaqCard from './faq-card.js';
import Faqs from './faqs.js';
import {Helmet} from "react-helmet";

const TOTAL_STARTING_NUMREVIEWS = 25;

class ReviewLander extends Component {
  constructor(props) {
      super(props);
      this.state = {
        projects: [],
        username: Users.auth.username,
        isShownToExternalViewer: true,
        user: Users.auth,
        skillName:this.props.match.params.skillName,
        loaded:false,
        reviewQueue:[],
        reviewQueueNumberOfCaseStudies:1,
        percentageLeft:0,
        numberOfCaseStudies:0,
      };
      this.setUser = this.setUser.bind(this);
      this.onReturnToProfileClick = this.onReturnToProfileClick.bind(this);
      this.goToReviews = this.goToReviews.bind(this);
  }

  async componentDidMount() {

    let isAuthUsersProfile = this.props.match.params.username
    this.setState({isShownToExternalViewer: !isAuthUsersProfile})
    this.setState({isUsernameError: false});
    await this.setUser();
    //await Backend.setUsername(this.state.username);
    let done = await this.fetchReviewerInfo();
    console.log('this is the all might review queue: ',this.state.reviewQueue, done)
    let numberOfCaseStudies = this.calculateNumberOfCaseStudies();
    let percentageLeft = this.calculateReviewPercentageLeft();
    this.setState({percentageLeft: percentageLeft});
    this.setState({numberOfCaseStudies: numberOfCaseStudies});
  }

  async setUser() {
    let user = await Backend.fetchUserByUsername(this.state.username);

    let username = user["username"]
    Users.otherUsers[username] = {"username": username}
    for (var property in user) {
        if (user.hasOwnProperty(property)) {
          Users.otherUsers[username][property] = user[property]
        }
    }
    this.setState({user: user});
  }

  onReturnToProfileClick() {
    this.props.history.push('/u/'+Users.auth.username);
  }

  goToReviews() {
    this.props.history.push('/reviews');
  }

  async fetchReviewerInfo() {
    let reviewQueue = await Backend.getBestQueueForReviewer(Users.auth.userId)
    console.log("whole review queue: ", reviewQueue);
    this.setState({reviewQueue: reviewQueue});
    return true
  }

  calculateReviewPercentageLeft() {
    let total = 0
    let numberLeft = 0
    if (this.state.reviewQueue) {
      for (let i = 0; i < this.state.reviewQueue.length; i++) {
        let skillName = this.state.reviewQueue[i]["skill"];
        let skills = this.state.reviewQueue[i]["project"]["skills"];
        numberLeft += skills[skillName]["ownerNumReqReviews"];
        total += TOTAL_STARTING_NUMREVIEWS;
      }

    }

    if (total!==0) {
      return Math.ceil(((total-numberLeft)/total)*100)

    }
    return 0
  }


  calculateNumberOfCaseStudies() {
    let total = 0
    let projects=[]
    for (let i = 0; i < this.state.reviewQueue.length; i++) {
      let skillName = this.state.reviewQueue[i]["skill"];
      let projectId = this.state.reviewQueue[i]["project"]["projectId"];
      if (projects.indexOf(projectId) === -1) {
        projects.push(projectId)
        total += 1
      }
    }

    return total

  }


  render() {


    var toTitleCase = function (str) {
      str = str.replace('-', ' ')
    	str = str.toLowerCase().split(' ');
    	for (var i = 0; i < str.length; i++) {
    		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    	}
    	return str.join(' ');
    };


    const faqs = Faqs.map(faq => {
      return (
        <div className='review-lander-faq-section-cards'>
          <FaqCard
            question={faq.question}
            answer={faq.answer}
          />
        </div>

      );
    });

    let reviewLanderDescription = <div>{"You don't have any skills pending to be assessed or scored. To start scoring new skills of yours, publish new case studies or add new skills to existing case studies. You can still contribute to the community right now by submitting high-quality and thoughtful peer reviews."}</div>

    if (this.state.reviewQueue && this.state.reviewQueue.length > 1) {

      if (this.state.numberOfCaseStudies == 1) {
        reviewLanderDescription =
          <div>You have <span className='review-lander-description-blue-span'>{this.state.reviewQueue.length} skills</span> across <span className='review-lander-description-blue-span'>1 case study</span> pending to be asessed and scored. In order to have these skills reviewed, you must contribute to the community by providing thoughtful and high-quality peer reviews. The higher-quality your reviews, the fewer you’ll need to do. So far, you have submitted <span className='review-lander-description-blue-span'>{this.state.percentageLeft}%</span> of the peers reviews necessary.</div>


      } else {

        reviewLanderDescription =
          <div>You have <span className='review-lander-description-blue-span'>{this.state.reviewQueue.length} skills</span> across <span className='review-lander-description-blue-span'>{this.state.numberOfCaseStudies} case studies</span> pending to be asessed and scored. In order to have these skills reviewed, you must contribute to the community by providing thoughtful and high-quality peer reviews. The higher-quality your reviews, the fewer you’ll need to do. So far, you have submitted <span className='review-lander-description-blue-span'>{this.state.percentageLeft}%</span> of the peers reviews necessary.</div>

      }
    }

    if (this.state.reviewQueue && this.state.reviewQueue.length == 1) {

      if (this.state.numberOfCaseStudies == 1) {
        reviewLanderDescription =
          <div>You have <span className='review-lander-description-blue-span'>1 skill</span> across <span className='review-lander-description-blue-span'>1 case study</span> pending to be asessed and scored. In order to have these skills reviewed, you must contribute to the community by providing thoughtful and high-quality peer reviews. The higher-quality your reviews, the fewer you’ll need to do. So far, you have submitted <span className='review-lander-description-blue-span'>{this.state.percentageLeft}%</span> of the peers reviews necessary.</div>


      } else {

        reviewLanderDescription =
          <div>You have <span className='review-lander-description-blue-span'>1 skill</span> across <span className='review-lander-description-blue-span'>{this.state.numberOfCaseStudies} case studies</span> pending to be asessed and scored. In order to have these skills reviewed, you must contribute to the community by providing thoughtful and high-quality peer reviews. The higher-quality your reviews, the fewer you’ll need to do. So far, you have submitted <span className='review-lander-description-blue-span'>{this.state.percentageLeft}%</span> of the peers reviews necessary.</div>

      }

    }



    return (
      <div className="review-lander">
        <Helmet>
          <title>Peer Reviews | Symbol</title>
        </Helmet>
        <div className='review-lander-top-bar'>
          <div className='review-lander-top-bar-container'>
            <div onClick={this.onReturnToProfileClick} className='review-lander-back-to-profile'>
              <BackArrowDark />
              <div className='review-lander-back-to-profile-label'>
                Back to profile
              </div>
              <div className='review-lander-back-to-profile-label-mobile'>
                Back
              </div>
            </div>
            <div className='review-lander-top-section-container'>
              <div className="review-lander-profile-person">
                <div onClick={this.onReturnToProfileClick} className="review-lander-profile-person-container">
                  <div className="review-lander-profile-person-photo">
                    {this.state.user.profilePhoto ?
                      <img src={this.state.user.profilePhoto}/>
                      :
                      <div className='review-lander-profile-picture-letter-small'>
                        {this.state.user.firstName?this.state.user.firstName[0]:null}
                      </div>
                    }
                  </div>
                  <div className="review-lander-profile-person-name">
                    {this.state.user.firstName} {this.state.user.lastName}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>







        <div className='review-lander-top-section'>
          <div className='review-lander-skill-name-container'>
            <div className='review-lander-skill-name'>
              Peer Reviews
            </div>
          </div>

          <div className='review-lander-description-container'>
            <div className='review-lander-description'>
              {reviewLanderDescription}
            </div>
          </div>

          <div className='review-lander-cta-button-container'>
            <div onClick={this.goToReviews} className='review-lander-cta-button'>
              <div className='review-lander-cta-button-label'>
                Begin reviewing →
              </div>
            </div>
          </div>


        </div>



        <div className='review-lander-faq-section'>
          <div className='review-lander-faq-section-container-margin'>
            <div className='review-lander-faq-section-container'>
              <div className='review-lander-faq-section-title'>
                Frequently Asked Questions
              </div>
            </div>
            <div className='review-lander-faq-section-container'>
              {faqs}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ReviewLander;
