import React from "react"

const DropdownArrowUp = () => (
  <svg width={8} height={5}>
    <g
      stroke="#F5F5F5"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M1.5 3.5L4 1M6.5 3.5L4 1" />
    </g>
  </svg>
)

export default DropdownArrowUp
