import React from 'react'

const ReviewMenuTabArrow = props => (
  <svg width={15} height={8} {...props}>
    {props.isReviewMenuOpen ?
      <path
        d="M14.847.155a.531.531 0 0 0-.75 0l-6.59 6.583L.904.155a.531.531 0 0 0-.749 0 .528.528 0 0 0 0 .747L7.12 7.845A.518.518 0 0 0 7.494 8a.54.54 0 0 0 .375-.155L14.834.902a.517.517 0 0 0 .013-.747z"
        fill="#767676"
        fillRule="nonzero"
      />
      : null}

    {!props.isReviewMenuOpen ?
      <path
        d="M14.847 7.845a.531.531 0 0 1-.75 0l-6.59-6.583L.904 7.845a.531.531 0 0 1-.749 0 .528.528 0 0 1 0-.747L7.12.155A.518.518 0 0 1 7.494 0a.54.54 0 0 1 .375.155l6.965 6.943c.22.206.22.541.013.747z"
        fill="#767676"
        fillRule="nonzero"
      />
      : null}



  }

  </svg>
)

export default ReviewMenuTabArrow
