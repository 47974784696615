import React, { Component } from 'react';
import './edit-profile-modal.css';
import ModalX from '../../assets/svg/modal-x.js';
import Users from "../../data/user.js";
import AnimatingSpinnerBig from '../../assets/svg/animating-spinner-big.js';
//import User from '../../data/user.js';
import EditProfilePhotoCamera from '../../assets/svg/edit-profile-photo-camera.js';
import Backend from "../backend.js";
import AnimatingSpinnerButton from '../../assets/svg/animating-spinner-button.js'
import {Image, Transformation, CloudinaryContext} from 'cloudinary-react';
import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET
} from '../editor/constants'

var ReactS3Uploader = require('react-s3-uploader');
//import ReactS3 from 'react-s3';

// const config = {
//     bucketName: 'openmind.app.images',
//     /*dirName: 'photos', /* optional */
//     region: 'us-west-1',
//     accessKeyId: 'AKIAIZYLFVW4V46PGJQQ',
//     secretAccessKey: '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',
// }

class EditProfileModal extends Component {


  constructor(props) {
      super(props);
      this.state = {
        username: this.props.username,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        bio: this.props.user.bio,
        callToActionText: this.props.user.callToActionText,
        callToActionLink: this.props.user.callToActionLink,
        profilePhoto: this.props.user.profilePhoto,
        isWaiting: this.props.isLoading,
        isProfilePhotoLoading: false,
      };
      this.onClickSave = this.onClickSave.bind(this);
      this.revertDataOnClose = this.revertDataOnClose.bind(this);
      this.getSignedUrl = this.getSignedUrl.bind(this);
      this.onUploadFinish = this.onUploadFinish.bind(this);
      this.onPhotoSelected = this.onPhotoSelected.bind(this);
    }

    async onClickSave() {
      if (this.validateForm()) {
        this.setState({isWaiting:true})
        await this.props.saveProfileInfo(this.state);

        Users.auth.username = this.state.username;
        Users.auth.firstName = this.state.firstName;
        Users.auth.lastName = this.state.lastName;
        Users.auth.bio = this.state.bio;
        Users.auth.callToActionText = this.state.callToActionText;
        Users.auth.callToActionLink = this.state.callToActionLink;
        Users.auth.profilePhoto = this.state.profilePhoto;

        this.setState({isWaiting:false})



      }
    }

    revertDataOnClose() {
      this.setState({
        username: this.props.user.username
      });

      this.setState({
        firstName: this.props.user.firstName
      });

      this.setState({
        lastName: this.props.user.lastName
      });

      this.setState({
        bio: this.props.user.bio
      });

      this.setState({
        callToActionText: this.props.user.callToActionText
      });

      this.setState({
        callToActionLink: this.props.user.callToActionLink

      });

      this.setState({
        profilePhoto: this.props.user.profilePhoto
      });

      this.props.toggleEditProfileModal()
    }

  async getSignedUrl(file, callback) {
    console.log("working with file: ", file);
    let response = await Backend.getSignedUrlS3(file, callback);
    console.log("backend s3 response: ", response);

    if (response.status == "error") {
      alert("error uploading your pic");
    } else {
      callback(response);
    }
  }

  onUploadFinish(e) {
    console.log("finsihed uploading ", e);
    //this.props.setBackgroundImage()
    this.setState({profilePhoto: e.url});
  }

  onUploadError(e) {
    console.log("error uploading: ", e);
  }

  onUsernameChange(value){
    this.setState({
         username: value.toLowerCase().replace(/[^a-z-\d]/, '')
    });
  }

  // resetUsername(prevUsername) {
  //   this.setState({username: prevUsername});
  // }

  onFirstNameChange(value){
    this.setState({
         firstName: value.replace(/[^a-zA-Z- \s]+/g,'')
    });
  }

  onLastNameChange(value){
    this.setState({
         lastName: value.replace(/[^a-zA-Z- \s]+/g,'')
    });
  }

  onBioChange(value){
    this.setState({
         bio: value
    });
  }

  onCtaTextChange(value){
    this.setState({
         callToActionText: value
    });
  }

  onCtaLinkChange(value){
    this.setState({
         callToActionLink: value
    });
  }

  validateForm() {
    let basic = false;
    if (this.state.firstName && this.state.lastName && this.state.username && this.state.bio) {
      basic = this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.username.length > 0 && this.state.bio.length > 0;
    }

    let callToActionLinkGood = true;
    let callToActionTextGood = true;

    if (this.state.callToActionText && this.state.callToActionText.length > 0) {
      callToActionLinkGood = this.state.callToActionLink && this.state.callToActionLink.length > 0;
    }
    if (this.state.callToActionLink && this.state.callToActionLink.length > 0) {
      callToActionTextGood = this.state.callToActionText && this.state.callToActionText.length > 0;
    }

    return basic && callToActionLinkGood; //&& callToActionTextGood;
  }

  async onPhotoSelected(file) {
    file = file[0];
    const userId = this.props.user["userId"];
    this.setState({isProfilePhotoLoading: true});
    let result = await Backend.uploadPhotoToCloudinary(file, userId, "profilePicture");
    let secureUrl = result.secure_url;
    this.setState({profilePhoto: secureUrl});
    this.setState({isProfilePhotoLoading: false});
  }

  render() {
    return (

        <div style={{visibility: this.props.isEditProfileModalOpen? 'visible' : 'hidden', opacity:this.props.isEditProfileModalOpen? '1' : '0'}} className='edit-profile-modal-overlay'>
          <div className='edit-profile-modal-container'>
            <div className='edit-profile-modal'>
              <div className='edit-profile-modal-responsive-container'>
              <div className='edit-profile-modal-top-bar'>
                <div onClick={this.revertDataOnClose} className='edit-profile-modal-x-container'>
                  <div className='edit-profile-modal-x'>
                    <ModalX />
                  </div>
                </div>
              </div>

              <div className='edit-profile-modal-title-body'>
                <div className='edit-profile-modal-title-container'>
                  <div className='edit-profile-modal-title'>
                    <div className='edit-profile-modal-title-label'>
                      Edit profile
                    </div>
                  </div>
                </div>
              </div>

              <div className='edit-profile-modal-scroll-container'>
                <div className='edit-profile-modal-section-1'>
                  <div className='edit-profile-modal-section-1-container'>
                    <div className='edit-profile-modal-section-1-container-2'>
                      <div className='edit-profile-modal-username-field-container'>
                        <div className='edit-profile-modal-username-field-label'>
                          Username
                        </div>
                      </div>
                      <div className='edit-profile-modal-username-field-container'>
                        <input
                          placeholder='Username is required'
                          className='edit-profile-modal-username-field'
                          value={this.state.username}
                          onChange={e => this.onUsernameChange(e.target.value)}
                          maxLength='30'
                          style={{borderColor:this.state.username=='' || this.props.isUsernameError ?'#EF7879':'#EBEDF2'}}
                        />
                      </div>
                      <div className='edit-profile-modal-username-field-container'>
                        <div className='edit-profile-modal-username-field-description'>
                          symbol.co/u/<span className='username-in-description'>{this.state.username.replace(/\s+/g, '-').toLowerCase()}</span>
                        </div>
                      </div>
                      </div>
                    <div className='edit-profile-modal-profile-photo'>

                      {this.state.isProfilePhotoLoading ?
                        <div className='edit-profile-modal-profile-picture'>
                          <AnimatingSpinnerButton/>
                        </div>
                        :

                        <div className='edit-profile-modal-profile-picture'>
                          {this.state.profilePhoto ?
                            <div className='edit-profile-modal-profile-photo-exists'>
                              <img src={this.state.profilePhoto}/>
                              <input className='edit-profile-modal-profile-photo-upload'
                                    type="file"
                                    id="fileupload"
                                    accept="image/*"
                                    ref={fileInputEl =>
                                        (this.fileInputEl = fileInputEl)
                                    }
                                    onChange={() =>this.onPhotoSelected(this.fileInputEl.files)}/>
                              <div className='edit-profile-modal-profile-photo-overlay'>
                                <div className='edit-profile-modal-profile-photo-overlay-message'>
                                  <div className='edit-profile-modal-profile-photo-overlay-message-icon'>
                                    <EditProfilePhotoCamera />
                                  </div>
                                  <div className='edit-profile-modal-profile-photo-overlay-message-text'>
                                    Change your profile photo
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            <div className='edit-profile-modal-profile-photo-doesnt-exists'>
                              <input className='edit-profile-modal-profile-photo-upload'
                                  type="file"
                                  id="fileupload"
                                  accept="image/*"
                                  ref={fileInputEl =>
                                      (this.fileInputEl = fileInputEl)
                                  }
                                  onChange={() =>
                                      this.onPhotoSelected(
                                          this.fileInputEl.files
                                      )
                                  }
                              />
                              <div className='edit-profile-modal-profile-photo-overlay'>
                                <div className='edit-profile-modal-profile-photo-overlay-message'>
                                  <div className='edit-profile-modal-profile-photo-overlay-message-icon'>
                                    <EditProfilePhotoCamera />
                                  </div>
                                  <div className='edit-profile-modal-profile-photo-overlay-message-text'>
                                    Add your profile photo
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </div>

                  </div>
                </div>

                <div className='edit-profile-modal-section-2'>
                  <div className='edit-profile-modal-section-2-container'>
                    <div className='edit-profile-modal-section-2-row-1'>
                      <div className='edit-profile-modal-section-2-column-1'>
                        <div className='edit-profile-modal-first-name-field-container'>
                          <div className='edit-profile-modal-first-name-field-label'>
                            First name
                          </div>
                        </div>
                        <div className='edit-profile-modal-first-name-field-container'>
                          <input
                            className='edit-profile-modal-first-name-field'
                            placeholder='First name is required'
                            value={this.state.firstName}
                            onChange={e => this.onFirstNameChange(e.target.value)}
                            style={{borderColor:this.state.firstName==''?'#EF7879':'#EBEDF2'}}
                            />

                        </div>
                      </div>
                      <div className='edit-profile-modal-section-2-column-2'>
                        <div className='edit-profile-modal-last-name-field-container'>
                          <div className='edit-profile-modal-last-name-field-label'>
                            Last name
                          </div>
                        </div>
                        <div className='edit-profile-modal-last-name-field-container'>
                          <input
                            className='edit-profile-modal-last-name-field'
                            placeholder='Last name is required'
                            value={this.state.lastName}
                            onChange={e => this.onLastNameChange(e.target.value)}
                            style={{borderColor:this.state.lastName==''?'#EF7879':'#EBEDF2'}}

                          />
                        </div>
                      </div>
                    </div>

                    <div className='edit-profile-modal-section-2-row-1'>
                      <div className='edit-profile-modal-section-2-column-3'>
                        <div className='edit-profile-modal-bio-field-container'>
                          <div className='edit-profile-modal-bio-field-label'>
                            One-sentence bio
                          </div>
                        </div>
                        <div className='edit-profile-modal-bio-field-container'>
                          <input
                            className='edit-profile-modal-bio-field'
                            placeholder='Bio is required'
                            value={this.state.bio}
                            onChange={e => this.onBioChange(e.target.value)}
                            style={{borderColor:this.state.bio==''?'#EF7879':'#EBEDF2'}}
                            />
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

                <div className='edit-profile-modal-section-3'>
                  <div className='edit-profile-modal-section-3-container'>

                    <div className='edit-profile-modal-section-3-row'>
                      <div className='edit-profile-modal-call-to-action-field-container'>
                        <div className='edit-profile-modal-call-to-action-field-label'>
                          Call-to-action text
                        </div>
                      </div>
                      <div className='edit-profile-modal-call-to-action-field-container'>
                        <input
                          className='edit-profile-modal-call-to-action-field'
                          placeholder='Add a call to action (i.e. "Get in touch")'
                          value={this.state.callToActionText}
                          onChange={e => this.onCtaTextChange(e.target.value)}

                        />
                      </div>
                      <div className='edit-profile-modal-call-to-action-field-container'>
                        <div className='edit-profile-modal-call-to-action-field-description'>
                          If you don’t want a call-to-action on your profile, leave this field blank
                        </div>
                      </div>
                    </div>

                    <div className='edit-profile-modal-section-3-row'>
                      <div className='edit-profile-modal-call-to-action-field-container'>
                        <div className='edit-profile-modal-call-to-action-field-label'>
                          Call-to-action link
                        </div>
                      </div>
                      <div className='edit-profile-modal-call-to-action-field-container'>
                        <input
                          className='edit-profile-modal-call-to-action-field'
                          placeholder={this.state.callToActionText !==''?'A link is required if you have call-to-action text':'Add a call-to-action link'}
                          value={this.state.callToActionLink}
                          onChange={e => this.onCtaLinkChange(e.target.value)}
                          style={{borderColor:this.state.callToActionLink =='' && this.state.callToActionText !==''?'#EF7879':'#EBEDF2'}}
                        />
                      </div>
                      <div className='edit-profile-modal-call-to-action-field-container'>
                        <div className='edit-profile-modal-call-to-action-field-description'>
                          Use “mailto:your@email.com” or “http://yourlink.com”
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                </div>

                <div className='edit-profile-modal-button-section'>
                  <div className='edit-profile-modal-button-section-container'>
                    <div onClick={this.revertDataOnClose} className='edit-profile-modal-close-button'>
                      <div className='edit-profile-modal-close-button-label'>
                        Close
                      </div>
                    </div>
                    <div onClick={this.onClickSave} className={!this.validateForm() || this.state.isWaiting ? 'edit-profile-modal-save-button-disabled' : 'edit-profile-modal-save-button'}>
                      {!this.state.isWaiting?
                        <div className='edit-profile-modal-save-button-label'>
                          Save changes
                        </div>

                        :

                        <AnimatingSpinnerButton
                          style={{marginTop:'15px'}}
                        />
                      }
                    </div>
                  </div>
                </div>

            </div>
            </div>

          </div>
        </div>


    );
  }
}

export default EditProfileModal;
