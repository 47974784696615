import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './index.css';
import Amplify from "aws-amplify";
import config from "./config";
import 'draft-js/dist/Draft.css';
import {ENV_LOGS_OFF, COGNITO_REGION, COGNITO_APP_CLIENT_ID, COGNITO_USER_POOL_ID} from './components/constants'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_APP_CLIENT_ID
  }
});

/*
Storage: {
  region: config.s3.REGION,
  bucket: config.s3.BUCKET,
  identityPoolId: config.cognito.IDENTITY_POOL_ID
},
API: {
  endpoints: [
    {
      name: "notes",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION
    },
  ]
}
*/

if (ENV_LOGS_OFF) { //if ENV = production

  console.log = function(){};
  console.warn = function(){};
  console.error = function(){};
}

ReactDOM.render(
  <App />,

  document.getElementById('root')
);
