import React from 'react'

const EditCaseStudyPenIcon= props => (
  <svg width={18} height={18} {...props}>
     <path
       d="M11.193 3.004l3.661 3.68-9.268 9.313-3.66-3.68 9.267-9.313zm6.44-.887L16 .476a1.615 1.615 0 0 0-2.29 0l-1.563 1.571 3.661 3.68 1.825-1.833c.49-.492.49-1.286 0-1.777zM.01 17.49a.418.418 0 0 0 .504.498l4.08-.994-3.659-3.68L.01 17.49z"
       fill="#FFF"
       fillRule="nonzero"
     />
   </svg>
 )
export default EditCaseStudyPenIcon
