
let emptyProject = {
"projectId": "",
"publishState": "", // only one of these — these are just the possibilities.
"dateLastPublish": "",
"projectTitle": "",
"backgroundImage": "",
"dateCreated": "",
"startDate": "",
"endDate": "",
"company": "",
"role": "",
"client": "",
"location": "",
"description": "",
"process": "",
"outcome": "",
"skills": {},
"creatorUsername": "",
"creatorId": "",
"edits": {},
}
let Projects = {"emptyProject":emptyProject,"cachedProjects":{"hi":{}}}

export default Projects
