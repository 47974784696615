import React from 'react'

const HomepageDownArrow = props => (
  <svg width={12} height={37} {...props}>
    <g
      stroke="#838383"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M10.167 30.833L6 35M1.833 30.833L6 35M6 1v33.071" />
    </g>
  </svg>
)

export default HomepageDownArrow
