import React from 'react'

export default ({ onUploadImage }) => (
  <div className="side-toolbar__button" >
    <svg width={26} height={24}>
      <path
        d="M25.4 6.5v14.657c0 1.349-1.058 2.443-2.362 2.443H2.562C1.258 23.6.2 22.506.2 21.157V6.5c0-1.349 1.058-2.443 2.362-2.443h4.332l.605-1.674C7.844 1.43 8.725.8 9.71.8h6.177c.984 0 1.865.631 2.21 1.583l.61 1.674h4.332c1.304 0 2.362 1.094 2.362 2.443zm-6 6.9c0-3.641-2.959-6.6-6.6-6.6a6.605 6.605 0 0 0-6.6 6.6c0 3.641 2.959 6.6 6.6 6.6 3.641 0 6.6-2.959 6.6-6.6zm-1.8 0c0 2.645-2.155 4.8-4.8 4.8A4.807 4.807 0 0 1 8 13.4c0-2.645 2.155-4.8 4.8-4.8s4.8 2.155 4.8 4.8z"
        fill="#242424"
        fillRule="nonzero"
      />
    </svg>
    <input
      type="file"
      accept="image/*"
      onChange={event => {
        const [file] = event.target.files
        if (file) onUploadImage(file)
      }}
    />
  </div>
)
