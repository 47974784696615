import React from 'react'

  const Logo = props => (
  <svg width={23} height={23} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11zm0-9.624c4.346 0 7.87 2.866 7.87-1.474S15.346 3.043 11 3.043s-7.87 3.519-7.87 7.859 3.524 1.474 7.87 1.474z"
        fill="#348EFF"
      />
      <text
        fontFamily="BrandonGrotesque-Medium, Brandon Grotesque"
        fontSize={22}
        fontWeight={400}
        fill="#242424"
        transform="translate(0 -7)"
      >
        <tspan x={31} y={23}>

        </tspan>
      </text>
    </g>
  </svg>
)

export default Logo
