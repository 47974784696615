import React from 'react'

const ShowLessArrow = props => (
  <svg width={20} height={11} {...props}>
    <path
      d="M19.795 10.787a.693.693 0 0 1-.999 0L10.01 1.736l-8.804 9.051a.693.693 0 0 1-1 0 .742.742 0 0 1 0-1.027L9.494.213a.68.68 0 0 1 .5-.213.71.71 0 0 1 .5.213l9.285 9.547a.726.726 0 0 1 .017 1.027z"
      fill="#767676"
      fillRule="nonzero"
    />
  </svg>
)

export default ShowLessArrow
