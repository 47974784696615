import React, { Component } from 'react';
import './command-bar.css';
import HideCommandBarArrow from '../../assets/svg/hide-command-bar-arrow.js'
import SavedSuccessfulBadge from '../../assets/svg/saved-successful-badge.js'
import PublishAirplaneIcon from '../../assets/svg/publish-airplane-icon.js'
import PreviewEyeIcon from '../../assets/svg/preview-eye-icon.js'
import DashboardGridIcon from '../../assets/svg/dashboard-grid-icon.js'
import DropdownArrow from '../../assets/svg/dropdown-arrow.js'
import DropdownArrowUp from '../../assets/svg/dropdown-arrow-up.js'
import PublishMenu from './publish-menu.js'
import Backend from '../backend.js'
import Icon from 'react-icons-kit'
import {exclamation} from 'react-icons-kit/fa/exclamation'
import EditCaseStudyPenIcon from '../../assets/svg/edit-case-study-pen-icon.js'
import GenericModal from '../modal/generic-modal.js';

class CommandBar extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isPublishMenuOpen: false,
        isBarHidden: false,
        isCaseStudyLockedModalOpen: false,
      }

      this.togglePublishMenu = this.togglePublishMenu.bind(this);
      this.toggleBarHidden = this.toggleBarHidden.bind(this);
      this.onExitEditMode = this.onExitEditMode.bind(this);
      this.toggleCaseStudyLockedModal = this.toggleCaseStudyLockedModal.bind(this);
    }

    togglePublishMenu() {
      this.setState(state => ({
        isPublishMenuOpen: !state.isPublishMenuOpen
      }));
    }

    toggleBarHidden() {
      this.setState(state => ({
        isBarHidden: !state.isBarHidden
      }));
    }

    onExitEditMode() {
      this.setState(state => ({
        isPublishMenuOpen:false
      }));

      this.props.togglePreviewMode();
    }

    toggleCaseStudyLockedModal() {
      this.setState(state => ({
        isCaseStudyLockedModalOpen: !state.isCaseStudyLockedModalOpen
      }));
    }

    render() {
    console.log(this.props.project.publishState)

    return (
      <div>
      <div className='command-area'>
        <div className={this.state.isBarHidden ? 'command-bar-hidden' : 'command-bar' }>
          <div className='command-bar-container'>
            <div className='command-bar-left-container'>
              <div className='hide-command-bar-block' onClick={this.toggleBarHidden}>
                <div className='hide-command-bar-arrow-container'>
                  <HideCommandBarArrow
                    className='hide-command-bar-arrow'
                  />
                </div>
              </div>
            </div>

            <div className='command-bar-right-container'>
            {this.props.isInPreviewMode ?
              <div onClick={this.props.togglePreviewMode} className='general-block-container'>
                  <div className='publish-block'>
                    <div className='publish-block-container'>
                      <EditCaseStudyPenIcon className='publish-airplane-icon'/>
                      <div className='edit-block-label'>Edit</div>
                    </div>
                  </div>
                </div>
            :
            <div>
              <div>
                {this.props.project.publishState!=='published'? null:
                <div onClick={this.onExitEditMode} className='general-block-container'>
                  <div className='preview-block'>
                    <div className='preview-block-container'>
                      <div className='preview-block-label'>Exit edit mode</div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>}
            {this.props.isInPreviewMode? null:
                <div className='general-block-container'>
                  <div className='publish-block' onClick={this.togglePublishMenu}>
                  { !this.state.isPublishMenuOpen?
                    <div className='publish-block-container'>
                      <PublishAirplaneIcon className='publish-airplane-icon'/>
                      <div className='publish-block-label'>Publish</div>
                      <DropdownArrow className='publish-dropdown-arrow' />
                    </div>
                    :

                    <div className='publish-block-container-open'>
                      <PublishAirplaneIcon className='publish-airplane-icon'/>
                      <div className='publish-block-label'>Publish</div>
                      <DropdownArrowUp className='publish-dropdown-arrow' />
                    </div>
                  }

                  </div>
                </div>
              }


            </div>
          </div>
        </div>

        { this.state.isPublishMenuOpen?
          <div className='command-publish-menu'>
            <div className='command-publish-menu-container'>
              <PublishMenu
                project={this.props.project}
                togglePublishMenu={this.togglePublishMenu}
                onPublishProjectClick={this.props.onPublishProjectClick}
                onUnpublishClick={this.props.onUnpublishClick}
                onDeleteClick={this.props.onDeleteClick}
                togglePreviewMode={this.props.togglePreviewMode}
                toggleDeleteCaseStudyModal={this.props.toggleDeleteCaseStudyModal}
                toggleUnpublishCaseStudyModal={this.props.toggleUnpublishCaseStudyModal}
                isReadyToPublish={this.props.isReadyToPublish}
                validatePublish={this.props.validatePublish}
                hasPublished={this.props.hasPublished}
              />
            </div>
          </div>
          :null
        }

      </div>

      <div className='command-bar-tab'>
        <div className='hide-command-bar-block-tab' onClick={this.toggleBarHidden}>
          <div className='hide-command-bar-arrow-container'>
            <HideCommandBarArrow className='hide-command-bar-arrow'/>
          </div>
        </div>
      </div>

      <GenericModal
         isModalOpen={this.state.isCaseStudyLockedModalOpen}
         toggleModal={this.toggleCaseStudyLockedModal}
         title={"Case Study is Scored"}
         xDoesPrimaryAction={true}
         message={<div>
           <p>{"At least one of your case study skills has finalized its assessment and received a score."}</p>
           <p>{"Although you cannot edit this case study anymore, you can create a new one."}</p>
           </div>}
         primaryActionLabel={"Okay"}
      />
    </div>
    );
  }
};

export default CommandBar;
