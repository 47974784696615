// @flow

import React, { Component } from 'react'
import AtomicBlock from '@djsp/atomic-block'
import { Plugin } from '@djsp/core'

class EmbeddedBlock extends Component {
  render() {
    const { apiKeys, isFocused, blockProps: { description, thumbnail_url, provider_url, image, title, url } } = this.props

    let blockClass = 'block editor-embed'
    if (isFocused) blockClass+= ' block--selected'

    return <div onClick={()=> window.open(url, "_blank")}className={blockClass}>
      {thumbnail_url != null && <div className="editor-embed__thumbnail-container"><img
        className="editor-embed__thumbnail"
        src={`https://i.embed.ly/1/display/crop?key=${apiKeys.EMBEDLY_KEY}&url=${encodeURIComponent(thumbnail_url)}&width=300&height=300&quality=80`}
        alt={title}/></div>}
      <div className="editor-embed__text">
        <div className="editor-embed__title">{title}</div>
        <div className="editor-embed__link">{provider_url}</div>
      </div>
    </div>
  }
}

export default ({ apiKeys }) => <AtomicBlock type="embed">{(props) => <EmbeddedBlock apiKeys={apiKeys} {...props} />}</AtomicBlock>
