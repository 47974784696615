import React, { Component } from 'react';
import './create-account-modal.css';
import ModalX from '../../assets/svg/modal-x.js';
import AnimatingSpinnerButton from '../../assets/svg/animating-spinner-button.js'
import { Auth } from "aws-amplify";
import Backend from "../backend.js";
import Users from "../../data/user.js";

class LoginModal extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isWaiting: false,
        isEmailError:false,
        isPasswordError:false,
        isErrorMessageShown:false,
        email: "",
        password: "",
        errorMessage: "Invalid email or password",
      };
      this.switchToCreateAccount = this.switchToCreateAccount.bind(this);
      this.onLoginClick = this.onLoginClick.bind(this);
    }

    switchToCreateAccount() {
      this.props.toggleLogInModal();
      this.props.toggleCreateAccountModal();
    }

    onPasswordChange(value) {
      this.setState({password: value});
    }

    onEmailChange(value) {
      this.setState({email: value});
    }

    validateForm() {
      return this.state.email.length > 0 && this.state.password.length > 0;
    }

    async onLoginClick() {
      this.setState({isWaiting: true})
      try {
        let user = await Auth.signIn(this.state.email, this.state.password);

        console.log("the user from auth: ", user);
        let userSignedIn = await Backend.fetchUser(user.username);
        console.log("got this user: ", userSignedIn);
        if (user) {
          Users.auth.userId = userSignedIn.userId;

          let session = user.signInUserSession;
          if (session) {
            Users.auth["accessToken"] = session.accessToken
          }

          await this.setAuthUserInfo(userSignedIn.userId);

          this.props.userHasAuthenticated(true, userSignedIn.userId);
          this.setState({isWaiting: false});
          this.props.history.push("/");
        } else {
          this.setState({isErrorMessageShown: true, errorMessage: "There was an error creating your account. Please try again or contact us."});
          this.setState({isWaiting: false});
        }
      } catch (e) {
        this.setState({isErrorMessageShown: true, errorMessage: e.message, isWaiting: false});
        //alert(e.message);
      }
    }

    async setAuthUserInfo(userId) {
      let fullUser = await Backend.fetchUser(userId);

      Users.auth["username"] = fullUser.username;
      Users.auth["firstName"] = fullUser.firstName;
      Users.auth["lastName"] = fullUser.lastName;
      Users.auth["email"] = fullUser.email;
      Users.auth["bio"] = fullUser.bio;
      Users.auth["callToActionLink"] = fullUser.callToActionLink;
      Users.auth["callToActionText"] = fullUser.callToActionText;
      for (var property in fullUser) {
          if (fullUser.hasOwnProperty(property)) {
            Users.auth[property] = fullUser[property]
          }
      }
    }

    _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onLoginClick();
    }
  }


  render() {
    return (
        <div style={{visibility: this.props.isLogInModalOpen? 'visible' : 'hidden', opacity:this.props.isLogInModalOpen? '1' : '0'}} className='create-account-modal-overlay'>
          <div className='create-account-modal-container'>
            <div className='create-account-modal'>
              <div className='create-account-modal-responsive-container'>
              <div className='create-account-modal-top-bar'>
                <div className='create-account-modal-x-container'>
                  <div onClick={this.props.toggleLogInModal} className='create-account-modal-x'>
                    <ModalX />
                  </div>
                </div>
              </div>

              <div className='create-account-modal-title-body'>
                <div className='create-account-modal-title-container'>
                  <div className='create-account-modal-title'>
                    <div className='create-account-modal-title-label'>
                      Log in
                    </div>
                  </div>
                  <div className='create-account-modal-login-option'>
                    <div>
                      New here? <span onClick={this.switchToCreateAccount} className='blue-log-in'>Create account</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='create-account-modal-scroll-container'>
                <div className='create-account-modal-section'>
                  <div className='create-account-modal-section-container'>
                    <div className='create-account-modal-section-row'>
                      <div className='create-account-modal-section-column'>
                        <div className='create-account-modal-field-container'>
                          <input
                            type='email'
                            placeholder='Email'
                            className='create-account-modal-field'
                            style={{borderColor:this.state.isEmailError?'#EF7879':'#EBEDF2'}}
                            onChange={(e) => this.onEmailChange(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='create-account-modal-section-row'>
                      <div className='create-account-modal-section-column'>
                        <div className='create-account-modal-field-container'>
                          <input
                            className='create-account-modal-field'
                            type='password'
                            placeholder='Password'
                            style={{borderColor:this.state.isPasswordError?'#EF7879':'#EBEDF2'}}
                            onChange={(e) => this.onPasswordChange(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                    {!this.state.isErrorMessageShown? null:
                      <div className='create-account-modal-section-row'>
                        <div className='create-account-modal-section-column'>
                          <div className='create-account-modal-error-message'>
                            {this.state.errorMessage}
                          </div>
                        </div>
                      </div>
                  }
                  </div>

                    <div className='create-account-modal-section-row'>
                      <div className='create-account-modal-section-column'>
                        <div className='create-account-accept-message'>
                        </div>
                      </div>
                    </div>




                  </div>
                </div>

                </div>

                <div className='create-account-modal-button-section'>
                  <div className='create-account-modal-button-section-container'>
                    <div onClick={this.onLoginClick} className={!this.state.isWaiting?'create-account-modal-primary-button':'create-account-modal-primary-button-waiting'}>

                      <div className='create-account-modal-primary-button-label'>
                        {!this.state.isWaiting?
                          "Log in"

                          :

                          <AnimatingSpinnerButton
                            style={{marginTop:'4px'}}
                          />
                        }
                      </div>



                    </div>

                  </div>
                </div>

            </div>
            </div>

          </div>
        </div>


    );
  }
}

export default LoginModal;
