let SkillDictionary = [
  {'skillName':'Graphic_Design'},
  {'skillName':'UI*UX_Design'},
  {'skillName':'Software_Engineering'},
  {'skillName':'Digital_Marketing'},
  {'skillName':'Product_Management'},
  {'skillName':'Creativity'},
  {'skillName':'Data_Science'},
  {'skillName':'Branding'},
  {'skillName':'Business_Strategy'},
  {'skillName':'Public_Speaking'},
  {'skillName':'Leadership'},
  {'skillName':'Entrepreneurship'},
  {'skillName':'Learning'},
  {'skillName':'Decision-Making'},
  {'skillName':'Front-End_Development'},
  {'skillName':'Project_Management'},
  {'skillName':'Product_Marketing'},
  {'skillName':'Growth_Marketing'},
  {'skillName':'Machine_Learning'},
  {'skillName':'Sales'},
  {'skillName':'Recruiting'},
  {'skillName':'Business_Development'},
  {'skillName':'Illustration'},
  {'skillName':'Photography'},
  {'skillName':'Animation'},
  {'skillName':'Web_Design'},
  {'skillName':'Motion_Graphics'},
  {'skillName':'Writing'},
  {'skillName':'Interaction_Design'},
  {'skillName':'Fashion_Design'},
  {'skillName':'Game_Design'},
  {'skillName':'Algorithm_Design'},
  {'skillName':'Brand_Marketing'},
  {'skillName':'Corporate_Marketing'},
  {'skillName':'Negotiation'},
  {'skillName':'Communications'},
  {'skillName':'Art_Direction'},
  {'skillName':'Public_Relations'},
  {'skillName':'Data_Analysis'},
  {'skillName':'Business_Operations'},
  {'skillName':'Financial_Analysis'},
  {'skillName':'Investing'},
  {'skillName':'SEO'},
];

export default SkillDictionary;
