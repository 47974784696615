import React, { Component } from 'react';
import '../App.css';
import ProjectHero from './Project-hero';
import CommandBar from './command-bar.js'
import ProjectDetailsSection from './project-details-section.js'
import AddSkillModal from './add-skill-modal.js'
import Backend from "../backend.js"
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import AnimatingSpinnerBigWhite from '../../assets/svg/animating-spinner-big-white.js';
import GenericModal from '../modal/generic-modal.js'
import Users from "../../data/user.js";
import Projects from "../../data/projects.js";
import BottomBadge from "../branding/bottom-badge.js";
import {Helmet} from "react-helmet";
import testState from '../editor/__mocks__/testState';



class ProjectPage extends Component {

  constructor(props) {
      super(props);
      var urlProjectId = this.props.match.params.projectId;
      var urlLen = urlProjectId.length;
      var realProjectId = urlProjectId.substring(urlLen - 36, urlLen);
      console.log("the real deal: ", realProjectId)

      this.state = {
        isInPreviewMode: true,
        isAddSkillModalOpen: false,
        isAuthUsersProject: Users.auth.username == this.props.match.params.username,
        projectId: realProjectId,
        projectUser: Users.otherUsers[this.props.match.params.username],
        projectVisible: {},
        project: {}, // what's currently published & stores the new edits in "edits".
        projectEdits: null,
        isLoading: false,
        shouldLoadEdits: false,
        isModalOpen: false,
        existingSkillsByPopularity: [],
        isDeleteCaseStudyModalOpen: false,
        isUnpublishCaseStudyModalOpen: false,
        isPublishSuccessModalOpen: false,
        isReadyToPublish: true,
        hasPublished: false,
      };

      this.toggleAddSkillModal = this.toggleAddSkillModal.bind(this);
      this.togglePreviewMode = this.togglePreviewMode.bind(this);
      this.onPublishProjectClick = this.onPublishProjectClick.bind(this);
      this.onUnpublishClick = this.onUnpublishClick.bind(this);
      this.onReturnToProfileClick = this.onReturnToProfileClick.bind(this);
      this.onAddSkillClick = this.onAddSkillClick.bind(this);
      this.togglePrivate = this.togglePrivate.bind(this);
      this.deleteSkill = this.deleteSkill.bind(this);
      this.onDeleteClick = this.onDeleteClick.bind(this);
      this.setProjectEdits = this.setProjectEdits.bind(this);
      this.setProjectTitle = this.setProjectTitle.bind(this);
      this.hasEdits = this.hasEdits.bind(this);
      this.fetchExistingSkillsByPopularity = this.fetchExistingSkillsByPopularity.bind(this);
      this.toggleModal = this.toggleModal.bind(this);
      this.setIsLoading = this.setIsLoading.bind(this);
      this.toggleDeleteCaseStudyModal = this.toggleDeleteCaseStudyModal.bind(this);
      this.toggleUnpublishCaseStudyModal = this.toggleUnpublishCaseStudyModal.bind(this);
      this.togglePublishSuccessModal = this.togglePublishSuccessModal.bind(this);
      this.validatePublish = this.validatePublish.bind(this);
      this.toggleReadyToPublish = this.toggleReadyToPublish.bind(this);
      this.setReadyToPublish = this.setReadyToPublish.bind(this);
      this.toggleHasPublished = this.toggleHasPublished.bind(this);

  }


  async componentDidMount() {

    let username = this.props.match.params.username
    if (Projects.cachedProjects[username] && Projects.cachedProjects[username].projects) {
      let cachedProjects = Projects.cachedProjects[username].projects
      let project = cachedProjects.filter(project => {
        return project.projectId === this.props.match.params.projectId
      })
      project = project[0]
      this.setState({project: project, backgroundImage: project.backgroundImage});
      if (this.props.isAuthenticated) {
          this.setState(state => ({
            isInPreviewMode: project.publishState == 'published'
          }));
      }

      if (!this.state.isAuthUsersProject && project.publishState != "published") {
        this.props.history.push("/u/" + username);
      }
    }

    console.log('johnny cache', this.state.project)

    this.fetchExistingSkillsByPopularity();

    await this.setProject(this.state.projectId);

    console.log('johnny server', this.state.project)
  }

  async fetchExistingSkillsByPopularity() {
    // get existingSkillsByPopularity.
    let existingSkillsByPopularity = ["Design", "Music Curation"];
    let result = await Backend.fetchExistingSkillsByPopularity()
    console.log("skills by pop: ", result.skills)
    let skills = result.skills.map((skill) => {
      return skill[0];
    })

    this.setState({existingSkillsByPopularity: skills});
  }

  setProjectEdits(key,value) {
    let projectTitle
      console.log('TTIITTLLEE')
    if (this.state.projectEdits && this.state.projectEdits.projectTitle) {
      console.log('title1')
      projectTitle = this.state.projectEdits.projectTitle;
    } else {
      if (this.state.project.edits && this.state.project.edits.projectTitle) {
        console.log('title2')
        projectTitle = this.state.project.edits.projectTitle;
      } else {
        console.log('title3')
        projectTitle = this.state.project.projectTitle;
      }
    }
    console.log('projectTItle:',projectTitle);
    let projectEdits = Object.assign({}, value);
    console.log('projectEdits',projectEdits)
    projectEdits["projectTitle"] = projectTitle
    console.log('projectEdits',projectEdits)
    this.setState({projectEdits: projectEdits});
  }

  setProjectTitle(key,value) {
    let projectEdits

    if (this.state.projectEdits) {
      projectEdits = Object.assign({}, this.state.projectEdits);
    } else {
      if (this.state.project.edits) {
        projectEdits = Object.assign({}, this.state.project.edits);
      } else {
        projectEdits = Object.assign({}, this.state.project);
      }
    }
    projectEdits["projectTitle"]=value["projectTitle"];
    this.setState({projectEdits:projectEdits});
  }

  async setProject(realProjectId): Promise<void> {
    var project = await Backend.fetchProject(realProjectId);
    this.setState({backgroundImage: project.backgroundImage, project: project});
    this.setState({isAuthUsersProject: Users.auth.username == this.props.match.params.username});
    console.log('THIS IS THE WHOLEEEEEE PRJE',project)
    if (!this.state.isAuthUsersProject && project.publishState != "published") {
      this.props.history.push("/u/" + this.props.match.params.username);
    }

    var projectUser = await Backend.fetchUser(project.creatorId);
    this.setState({projectUser: projectUser});

    if (this.props.isAuthenticated) {
      if (project.publishState == 'published') {
        this.setState(state => ({
          isInPreviewMode: true
        }));
      }

      if (project.publishState !== 'published') {
        this.setState(state => ({
          isInPreviewMode: false
        }));
      }
    }
  }

  toggleAddSkillModal() {
    this.setState(state => ({
      isAddSkillModalOpen: !state.isAddSkillModalOpen
    }));
  }

  togglePreviewMode() {
    this.setState(state => ({
      isInPreviewMode: !state.isInPreviewMode
    }));
  }

  setIsLoading(loading) {
    this.setState({isLoading: loading});
  }

  async onPublishProjectClick(isOptInToQuantify, isOptInToQuantifyPublicly) {

    this.toggleHasPublished()

    if (this.validatePublish()) {

      this.setState({isLoading: true});
      await Backend.updateProjectPublishState(this.state.project, "published", isOptInToQuantify, isOptInToQuantifyPublicly);
      console.log("DONE publishing house: ", this.state.project.edits);

      let project = await Backend.fetchProject(this.state.project.projectId);
      this.setState({project: project, isLoading: false});
      this.togglePublishSuccessModal();


    }

  }

  async onUnpublishClick(isOptInToQuantify, isOptInToQuantifyPublicly) {
    this.setState({isLoading: true});
    await Backend.updateProjectPublishState(this.state.project, "unpublished", isOptInToQuantify, isOptInToQuantifyPublicly);
    let project = await Backend.fetchProject(this.state.projectId);
    this.setState({project: project, projectEdits: project.edits, isLoading: false});

  }

  async onDeleteClick(isOptInToQuantify, isOptInToQuantifyPublicly) {
    await Backend.deleteProject(this.state.project);
    this.props.history.push('/u/'+this.props.match.params.username);
  }

  onReturnToProfileClick() {
    console.log("here are all the props: ", this.props);
    this.props.history.push('/u/'+this.props.match.params.username);
  }

  toggleModal() {
    this.setState({isModalOpen: !this.state.isModalOpen});
  }

  toggleHasPublished() {
    this.setState({hasPublished: true});
  }

  async onAddSkillClick(skillName) {
    skillName = skillName.trim();
    if (skillName.length <= 0) {
      // this.setState({modalTitle: "Can't add an empty skill"});
      // this.setState({modalMessage: "Please try something else."});
      // this.setState({modalPrimaryActionMessage: "Ok"});
      // this.setState({modalSecondaryActionMessage: null});
      // this.toggleModal();
      return;
    }
      // show loading icon and only dismiss modal if successfully added skill.
    this.setState({isLoading: true});
    await Backend.addSkill(skillName, this.state.projectId, this.state.projectUser["userId"], this.state.project.publishState);
    var newProject = await Backend.fetchProject(this.state.projectId);
    this.setState({project: newProject, isLoading: false});
    this.toggleAddSkillModal();
  }

  // async setBackgroundImage(backgroundImage): Promise<void> {
  //   console.log("OLD BG IMAGE CHANGE");
  //   // update project in backend.
  //   this.setState(state => ({
  //     backgroundImage: backgroundImage
  //   }));
  //
  //   let project = Object.assign({}, this.state.project);
  //   project["backgroundImage"] = backgroundImage;
  //   this.setState({project: project, hasNewEdits: true});
  //
  //   this.setProjectEdits()
  //
  //   this.setReadyToPublish(false);
  //   console.log("saving background image w project publishstate: ", this.state.project.publishState);
  //   await Backend.updateProjectInfo(this.state.project, "backgroundImage", backgroundImage);
  //   this.setReadyToPublish(true);
  //   console.log("done actually putting the link in the database");
  // }

  async togglePrivate(skillName) {
    let currentProject = JSON.parse(JSON.stringify(this.state.project));
    console.log("hey this is the current project for toggling: ", currentProject);
    console.log("the skillName:", skillName);
    //console.log("the skills: ", currentProject.edits.skills);
    //currentProject.edits.skills[skillName].private = !currentProject.skills[skillName].private
    let value;
    if (this.hasEdits()) {
      value = !currentProject.edits.skills[skillName].private
      currentProject.edits.skills[skillName].private = value
    } else {
      value = !currentProject.skills[skillName].private
      currentProject.skills[skillName].private = value
    }

    this.setState({project: currentProject});

    //this.setState({isLoading: true});
    let result = await Backend.updateSkillAttribute(skillName, this.state.projectId, this.state.project.creatorId, this.state.project.publishState, "private", value);
    console.log("result from updating attribute: ", result);
    // let newProject = await Backend.fetchProject(this.state.projectId);
    // console.log("new project received: ", newProject);
    // this.setState({project: newProject})//, isLoading: false});
  }

  async deleteSkill(skillName) {
    console.log("DELETING SKILL: ", skillName);
    let currentProject = JSON.parse(JSON.stringify(this.state.project));
    delete currentProject.skills[skillName];
    this.setState({project: currentProject});
    let result = await Backend.deleteSkill(skillName, this.state.projectId, this.state.project.creatorId, this.state.project.publishState);
    var newProject = await Backend.fetchProject(this.state.projectId);
    this.setState({project: newProject});
    console.log("result from deleting: ", result);
    if (result.status != "success") {
      alert("error deleting your skill ", result.status);
    }
  }

  hasEdits() {
    return this.state.project.edits != undefined && Object.keys(this.state.project.edits).length > 0;
  }

  toggleDeleteCaseStudyModal() {
    this.setState(state => ({
      isDeleteCaseStudyModalOpen: !state.isDeleteCaseStudyModalOpen
    }));
  }

  toggleUnpublishCaseStudyModal() {
    this.setState(state => ({
      isUnpublishCaseStudyModalOpen: !state.isUnpublishCaseStudyModalOpen
    }));
  }

  togglePublishSuccessModal() {
    this.setState(state => ({
      isPublishSuccessModalOpen: !state.isPublishSuccessModalOpen
    }));
  }


  toggleReadyToPublish() {
    this.setState(state => ({
      isReadyToPublish: !state.isReadyToPublish
    }));
  }

  setReadyToPublish(ready) {
    this.setState({isReadyToPublish: ready});
  }

  validateTitlePublish() {
    if (this.state.projectEdits) {
      if (this.state.projectEdits.projectTitle=='') {
        return false
      }
      return this.state.projectEdits.projectTitle
    }

    if (this.state.project.edits) {
      return this.state.project.edits.projectTitle
    }
    return this.state.project.projectTitle
  }

  validateDescriptionPublish() {
    if (this.state.projectEdits) {
      if (this.state.projectEdits.description=='') {
        return false
      }
      return this.state.projectEdits.description
    }

    if (this.state.project.edits) {
      return this.state.project.edits.description
    }
    return this.state.project.description
  }

  validateEndDatePublish() {
    if (this.state.projectEdits) {
      return this.state.projectEdits.endDate && this.state.projectEdits.endDate.length===7
    }
    if (this.state.project.edits && this.state.project.edits.endDate) {
      return this.state.project.edits.endDate.length===7
    }

    return this.state.project.endDate && this.state.project.endDate.length===7
  }

  validateProcessPublish() {
    if (this.state.projectEdits) {
      console.log('pp1')
      return this.state.projectEdits.process&&this.state.projectEdits.process!==JSON.stringify(testState)
    }

    if (this.state.project.edits) {
      console.log('pp2')
      return this.state.project.edits.process&&this.state.project.edits.process!==JSON.stringify(testState)
    }
    console.log('pp3')
    return this.state.project.process&&this.state.project.process!==JSON.stringify(testState)
  }

  validateOutcomePublish() {
    if (this.state.projectEdits) {
      console.log('op1')
      return this.state.projectEdits.outcome&&this.state.projectEdits.outcome!==JSON.stringify(testState)
    }

    if (this.state.project.edits) {
      console.log('op2')
      return this.state.project.edits.outcome&&this.state.project.edits.outcome!==JSON.stringify(testState)
    }
    console.log('op3')
    return this.state.project.outcome&&this.state.project.outcome!==JSON.stringify(testState)
  }


  validatePublish() {
    console.log('')
    console.log('')
    console.log('VALIDATION')
    console.log('title',this.validateTitlePublish())
    console.log('description',this.validateDescriptionPublish())
    console.log('endDate',this.validateEndDatePublish())
    console.log('process',this.validateProcessPublish())
    console.log('outcome',this.validateOutcomePublish())
    console.log('')
    console.log('')
    return this.validateTitlePublish() && this.validateDescriptionPublish() && this.validateEndDatePublish() && this.validateProcessPublish() && this.validateOutcomePublish()
  }

  isProjectLocked() {
    let project = this.state.project;
    let projectSkills = project["skills"];
    if (projectSkills == undefined) {
      return false;
    }
    for (var skillName in projectSkills) {
      if (projectSkills.hasOwnProperty(skillName)) {
        let skill = projectSkills[skillName];
        if (skill["state"] == "quantified") {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    let hasEdits = this.hasEdits()
    let isProjectLocked = this.isProjectLocked();
    console.log("is it locked? ", isProjectLocked);

    let projectPageTitle = 'Symbol'

    if (this.state.project.projectTitle) {
      projectPageTitle = this.state.project.projectTitle + ' | Symbol'
    }

    return (<div className="project-page">
      <Helmet>
        <title>{projectPageTitle}</title>
      </Helmet>
      { this.state.isAddSkillModalOpen ?
        <AddSkillModal toggleAddSkillModal={this.toggleAddSkillModal} project={this.state.project} hasEdits={hasEdits} existingSkillsByPopularity={this.state.existingSkillsByPopularity} onAddSkillClick={this.onAddSkillClick} />
         : null }

      {this.state.isAuthUsersProject &&
        (<CommandBar
          togglePreviewMode={this.togglePreviewMode}
          isProjectLocked={isProjectLocked}
          hasEdits={hasEdits}
          isInPreviewMode={this.state.isInPreviewMode}
          onPublishProjectClick={this.onPublishProjectClick}
          onUnpublishClick={this.onUnpublishClick}
          onDeleteClick={this.onDeleteClick}
          project={this.state.project}
          toggleDeleteCaseStudyModal={this.toggleDeleteCaseStudyModal}
          toggleUnpublishCaseStudyModal={this.toggleUnpublishCaseStudyModal}
          isReadyToPublish={this.state.isReadyToPublish}
          validatePublish={this.validatePublish}
          hasPublished={this.state.hasPublished}
        />)
      }
      {this.state.isLoading && <div className="loading-overlay"> <div className="loading-container"><AnimatingSpinnerBigWhite/></div></div>}
      <ProjectHero
        key={this.state.project.projectId + "hero"}
        project={this.state.project}
        hasEdits={hasEdits}
        setIsLoading={this.setIsLoading}
        userId={Users.auth.userId}
        projectEdits={this.state.project.edits}
        isProjectLocked={isProjectLocked}
        backgroundImage={this.state.backgroundImage}
        setBackgroundImage={this.setBackgroundImage}
        projectUser={this.state.projectUser}
        isAuthUsersProject={this.state.isAuthUsersProject}
        isInPreviewMode={this.state.isInPreviewMode}
        onReturnToProfileClick={this.onReturnToProfileClick}
        setProjectTitle={this.setProjectTitle}
        setReadyToPublish={this.setReadyToPublish}
        toggleReadyToPublish={this.toggleReadyToPublish}
      />
      <ProjectDetailsSection
        key={this.state.project.projectId}
        project={this.state.project}
        hasEdits={hasEdits}
        isProjectLocked={isProjectLocked}
        projectEdits={this.state.project.edits}
        isInPreviewMode={this.state.isInPreviewMode}
        toggleAddSkillModal={this.toggleAddSkillModal}
        togglePrivate={this.togglePrivate}
        deleteSkill={this.deleteSkill}
        projectUser={this.state.projectUser}
        setReadyToPublish={this.setReadyToPublish}
        setProjectEdits={this.setProjectEdits}
        onReturnToProfileClick={this.onReturnToProfileClick}
        history={this.props.history}
       />

       <GenericModal
          isModalOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
          title={this.state.modalTitle}
          message={<div>{this.state.modalMessage}</div>}
          primaryActionLabel={this.state.modalPrimaryActionMessage}
          secondaryActionLabel={this.state.modalSecondaryActionMessage}
       />

       {/* DELETE CASE STUDY MODAL */}

       <GenericModal
          isModalOpen={this.state.isDeleteCaseStudyModalOpen}
          toggleModal={this.toggleDeleteCaseStudyModal}
          title={'Delete case study?'}
          message={<div>Are you sure you want to delete this case study? Once you delete it, all its information and any associated reviews for its skills will be deleted forever.<br></br><br></br>You are not able to undo this action. </div>}
          primaryActionLabel={'Delete case study'}
          primaryAction={this.onDeleteClick}
          secondaryActionLabel={'Close'}
       />

       {/* UNPUBLISH CASE STUDY MODAL */}

       <GenericModal
          isModalOpen={this.state.isUnpublishCaseStudyModalOpen}
          toggleModal={this.toggleUnpublishCaseStudyModal}
          title={'Unpublish case study?'}
          message={<div>Are you sure you want to unpublish this case study? Once unpublished, it will not be shown on your public profile and any associated scores will be hidden.</div>}
          primaryActionLabel={'Unpublish case study'}
          primaryAction={this.onUnpublishClick}
          secondaryActionLabel={'Close'}
       />


       {/* SUCCESSFUL PUBLISH MODAL */}

       <GenericModal
          isModalOpen={this.state.isPublishSuccessModalOpen}
          toggleModal={this.togglePublishSuccessModal}
          title={'Publish successful!'}
          message={
            <div>Your case study was successfully published.
              <br></br><br></br>
            If you've added any new skills to this case study, they've been submitted for assessment and scoring.
              <br></br><br></br>
              To get your scores, make sure you contribute to the community and submit your peer reviews.
            </div>
          }
          primaryActionLabel={'Go to Peer Reviews'}
          primaryAction={()=> window.open('/review-lander','_blank')}
          secondaryActionLabel={'Close'}


       />
       <BottomBadge
         isAuth={Users.auth.username}
         onUser={this.props.match.params.username}
       />

    </div>)
  }
}

export default withRouter(ProjectPage);
