// @flow

import React, { Component } from 'react'
import AtomicBlock from '@djsp/atomic-block'
import { Plugin } from '@djsp/core'

class VideoBlock extends Component {
  render() {
    const { isFocused, blockProps: { html, description, thumbnail_url, provider_url, image, title, url } } = this.props
    let blockClass = 'block-video'
    if (isFocused) blockClass += ' block--selected'

    return <div className={blockClass} dangerouslySetInnerHTML={{ __html: html}} />
  }
}

export default () => <AtomicBlock type="video">{(props) => <VideoBlock {...props} />}</AtomicBlock>
