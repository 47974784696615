import React from 'react';
import './circle-skill-block.css';
import './react-circular-progressbar.css';
import CircularProgressbar from 'react-circular-progressbar';
import convertToDisplaySkill from '../skills/convert-to-display-skill.js'

const CircleSkillBlock = props => {


  return(
    <div className='circle-skill-block'>
      <div className='circle-skill-block-title-container'>
        <div className='circle-skill-block-title'>
          {convertToDisplaySkill(props.skill.skillName)}

        </div>
      </div>

      {props.numberOfSkillsQuantified==0? null:
        <div className='circle-skill-block-circle-container'>
          <div className='circle-skill-block-circle'>

            <div className='circle-skill-block-circle-text-container'>
              {props.skill.state!=='quantified' || props.skill.private!==false?null:
                <div className='circle-skill-block-circle-number-text-container'>
                  {props.skill.displayBucket}
                </div>
              }
              <div style={{marginTop: props.skill.state!=='quantified' || props.skill.private!==false? '10px':'0'}} className='circle-skill-block-circle-percentile-text-container'>
                {props.skill.state=='quantified'&& props.skill.private==false?'percentile':'Not scored'}
              </div>
            </div>

            <div className='circle-skill-block-progressbar-container'>
              <CircularProgressbar
                className='react-circular-progressbar'
                percentage={props.skill.state=='quantified' && props.skill.private==false?props.skill.displayBucket:0}
                strokeWidth='9'
                initialAnimation='true'

              />
            </div>
          </div>
        </div>
      }

      <div onClick={() => props.seeSkill(props.skill.skillName)} style={{marginTop: props.numberOfSkillsQuantified==0? '23px':'0'}} className='circle-skill-block-see-details-container'>
        <div className='circle-skill-block-see-details'>
          See details
        </div>
      </div>

    </div>

    );

}

export default CircleSkillBlock;
