import React, { Component } from 'react';
import './review-menu.css';
import ReviewMenuTabArrow from '../../assets/svg/review-menu-tab-arrow.js';
import Reviewer from './data/reviewer.js';
import AnimatingSpinnerBig from '../../assets/svg/animating-spinner-big.js';
import BackArrowDark from '../../assets/svg/back-arrow-dark.js';
import ResizeIcon from '../../assets/svg/resize-icon.js';
import Users from "../../data/user.js";
import GenericModal from '../modal/generic-modal.js'
import convertToDisplaySkill from '../skills/convert-to-display-skill.js'

class ReviewMenu extends Component {

  constructor(props) {
      super(props);
      this.state = {
        whoIsSelected: null,
        isSearchForNextReview: true,
        isBatchInfoModalOpen: false,
      };

      this.setPersonAWhoIsSelected = this.setPersonAWhoIsSelected.bind(this);
      this.setPersonBWhoIsSelected = this.setPersonBWhoIsSelected.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.onReturnToProfileClick = this.onReturnToProfileClick.bind(this);
      this.toggleBatchInfoModal = this.toggleBatchInfoModal.bind(this);
    }

  onSubmit() {
    this.props.handleComparison(this.state.whoIsSelected);
    this.setState({whoIsSelected: null})
  }

  setPersonAWhoIsSelected() {
    this.setState(state => ({
      whoIsSelected: "A"
    }));
  }

  setPersonBWhoIsSelected() {
    this.setState(state => ({
      whoIsSelected: "B"
    }));
  }

  onReturnToProfileClick() {
    this.props.history.push('/');
  }

  toggleBatchInfoModal() {
    this.setState(state => ({
      isBatchInfoModalOpen: !state.isBatchInfoModalOpen
    }));
  }

  render() {
    const personSelectedStyle = {
      border: "1px solid #2D8BFF"
    }

    console.log("hasRequiredReviews: ", this.props.hasRequiredReviews);

  return(
    <div className='review-menu-component'>

      <div  className='review-menu'>
        <div style={{bottom: this.props.isReviewMenuOpen? '0': '-403px'}} className='review-menu-container'>
          <div onClick={this.props.toggleIsReviewMenuOpen} className='review-menu-top-tab'>
            <div className='review-menu-top-tab-label-container'>
              <div className='review-menu-top-tab-arrow'>
                <ReviewMenuTabArrow
                  isReviewMenuOpen={this.props.isReviewMenuOpen}
                />
              </div>
              <div className='review-menu-top-tab-label'>
                {this.props.isReviewMenuOpen? 'Hide review menu' : 'Show review menu'}
              </div>
            </div>
          </div>

          <div className='review-menu-body-container'>
            <div onClick={this.onReturnToProfileClick} className='review-menu-back-to-dashboard'>
              <BackArrowDark />
              <div className='review-menu-back-to-dashboard-label'>
                Back to profile
              </div>
            </div>


            <div className='review-menu-submit-review-block-container'>
              <div className='review-menu-submit-review-block'>
                <div className='review-menu-submit-review-block-label-container'>
                  <div className='review-menu-submit-review-block-label'>
                    Submit your review
                  </div>
                </div>
                <div className='review-menu-submit-review-block-body'>
                  <div className='review-menu-submit-review-block-body-container'>
                    <div className='review-menu-submit-review-block-prompt'>
                      Based on the skills demonstrated by their case studies, which person demonstrates <span className='underline-bold'>more expertise</span> in <span className='underline-bold'>{convertToDisplaySkill(this.props.skillToReview)}</span>?
                    </div>
                    <div className='review-menu-submit-review-block-persons'>
                      <div className='review-menu-submit-review-block-persons-container'>
                        <div className='person-block-a' onClick={this.setPersonAWhoIsSelected} style={{border:this.state.whoIsSelected=='A'? "1px solid #2D8BFF" : "1px solid #EBEDF2"}}>
                          <div className='person-block-label' style={{opacity:this.state.whoIsSelected=='A'? '1' : '0.3'}}>
                            Person A
                          </div>
                        </div>
                        <div className='person-block-b' onClick={this.setPersonBWhoIsSelected} style={{border:this.state.whoIsSelected=='B'? "1px solid #2D8BFF" : "1px solid #EBEDF2"}}>
                          <div className='person-block-label' style={{opacity:this.state.whoIsSelected=='B'? '1' : '0.3'}}>
                            Person B
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div onClick={this.onSubmit} className='submit-button'>
                  <div style={{opacity:this.state.whoIsSelected=='A' || this.state.whoIsSelected=='B'? '1' : '0.3'}} className='submit-button-label'>
                    Submit Review & Next →
                  </div>
                </div>
              </div>
          </div>
          <div className='review-menu-progress-bar-container'>
            <div className='review-menu-progress-bar'>
              {
                this.props.hasRequiredReviews ?
                (<div className='review-menu-progress-bar-label'>
                  Required reviews completed for <span className='underline' onClick={this.toggleBatchInfoModal}> Batch {this.props.currentBatchNum} of {this.props.allNumBatches}</span>:
                </div>) :
                (<div className='review-menu-progress-bar-label'>
                  Reviews completed
                </div>)
              }

              <div className='review-menu-progress-bar-container'>
                <div className='review-menu-progress-bar-background'>
                  <div style={{width:this.props.percentReviewsCompleted}} className='review-menu-progress-bar-percentile'>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>

      <div className='review-overlay' style={{opacity: this.props.isReviewMenuOpen? '0.1': '0', visibility:this.props.isReviewMenuOpen? 'visible': 'hidden'}}>
      </div>


      <div className='screen-size-overlay'>
        <div className='screen-size-container'>
          <div className='screen-size-container-vertical-center'>
            <div className='screen-size-message-container'>

                <ResizeIcon />
            </div>
            <div className='screen-size-message-container'>
              <div className='screen-size-message'>
                Your browser is too small
              </div>
              <div className='screen-size-message-small'>
                Resize your browser to at least 900px <br></br>to get back into Review Mode
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* ExhaustedAllSkillsInPlatform MODAL */}
      <GenericModal
         isModalOpen={this.state.isBatchInfoModalOpen}
         toggleModal={this.toggleBatchInfoModal}
         title={"What is a batch?"}
         message={<div>
           <p>A batch is the group of reviews you must complete to get one skill from one of your case studies scored. </p>
           <p>For example, you are currently working on the batch of reviews required to score your {convertToDisplaySkill(this.props.caseStudySkillContributing)} skill from your case study <span className='static-underline'>{this.props.caseStudyTitleContributing}</span>.</p>
           <p>The ordering of your batches is based on the popularity of each skill.</p>
           </div>}
         primaryActionLabel={"Okay"}
      />

    </div>
    );
  }
}

export default ReviewMenu;
