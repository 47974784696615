import React from 'react'

export default ({ isActive, onClick  }) => 
  <button
    className={`toolbar__button${isActive != null ? ' toolbar__button--active': ''}`}
    onClick={onClick}>
    <svg viewBox="0 0 24 27">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-672.000000, -1147.000000)"
          fill={`${isActive ? '#3C93FF' : '#000'}`}>
          <g transform="translate(646.000000, 1131.000000)">
            <g transform="translate(26.471545, 16.916667)">
              <rect x="0" y="0" width="22.8617886" height="2.92788462"></rect>
              <rect x="0" y="22.4471154" width="22.8617886" height="2.92788462"></rect>
              <rect x="0" y="4.87980769" width="22.8617886" height="15.6153846"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
