import React, { Component } from 'react';
import Backend from "../backend.js";
import './user-page.css';
import ProfileHeader from './profile-header.js';
import ProfileSkillsSection from './profile-skills-section.js';
import ProfileProjectsSection from './profile-projects-section.js';
import {Browsouter as Router, Route, Link, withRouter} from 'react-router-dom';
import EditProfileModal from './edit-profile-modal.js';
import AnimatingSpinnerBig from '../../assets/svg/animating-spinner-big.js';
import Users from "../../data/user.js";
import { Auth } from "aws-amplify";
import Projects from "../../data/projects.js";
import TopBarBranding from "../branding/top-bar-branding.js";
import BottomBadge from "../branding/bottom-badge.js";
import {Helmet} from "react-helmet";
import GenericModal from '../modal/generic-modal.js';


class UserPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      username: this.props.match.params.username,
      isShownToExternalViewer: true,
      isEditProfileModalOpen: false,
      user: Users.emptyUser,
      isLoading: false,
      isUsernameError: false,
      isNewProjectLoading:false,
      reviewQueue:[],
      isFirstCaseStudyModalOpen: false,
    }

    this.setProjects = this.setProjects.bind(this);
    this.createNewProject = this.createNewProject.bind(this);
    this.seeProject = this.seeProject.bind(this);
    this.toggleEditProfileModal = this.toggleEditProfileModal.bind(this);
    this.toggleShowToExternalViewer = this.toggleShowToExternalViewer.bind(this);
    this.goToReviews = this.goToReviews.bind(this);
    this.setUser = this.setUser.bind(this);
    this.saveProfileInfo = this.saveProfileInfo.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.seeSkill = this.seeSkill.bind(this);
    this.toggleFirstCaseStudyModal = this.toggleFirstCaseStudyModal.bind(this);
  }

  async componentDidMount() {
    if (Users.auth.username == this.props.match.params.username) {
      this.setState({user: Users.auth});
    }

    if (Users.otherUsers[this.props.match.params.username]){
      this.setState({user: Users.otherUsers[this.props.match.params.username]});
    }

    if (Projects.cachedProjects[this.props.match.params.username]){
      this.setState({projects: Projects.cachedProjects[this.props.match.params.username].projects});
    }

    let isAuthUsersProfile = Users.auth.username == this.props.match.params.username
    console.log("aca boi: ", isAuthUsersProfile, Users.auth.username, this.props.match.params.username)
    this.setState({isShownToExternalViewer: !isAuthUsersProfile})
    this.setState({isUsernameError: false});
    await this.setUser();
    //await Backend.setUsername(this.state.username);
    await this.setProjects();
    this.setState({isLoading: false});
    console.log('BROOO')
    console.log(Users.auth.username)
    console.log(this.state.username)

    this.fetchReviewerInfo()
  }

  async setUser() {
    console.log("fetching user ", this.state.username);
    let user = await Backend.fetchUserByUsername(this.state.username);
    if (!user || user.length == 0) {
      this.props.history.push('/');
      return;
    }

    let username = user["username"]
    Users.otherUsers[username] = {"username": username}
    for (var property in user) {
        if (user.hasOwnProperty(property)) {
          Users.otherUsers[username][property] = user[property]
        }
    }
    this.setState({user: user});
  }

  async createNewProject() {
    this.setState({isNewProjectLoading: true});
    let response = await Backend.createNewProject();
    if (response.status != "success") {
      alert("error creating your project. try again later.");
    }
    await this.setProjects()
    this.setState({isNewProjectLoading: false});
    this.props.history.push('/u/' + this.props.match.params.username + '/' + response.projectId);
  }

  createFirstCaseStudy(){
    this.toggleFirstCaseStudyModal();

  }

  async setProjects(): Promise<void> {
    let projects = await Backend.fetchProjectsForUserId(this.state.user.userId);
    console.log("projects gott: ", projects);

    Projects.cachedProjects[this.state.username] = projects

    this.setState({projects: projects.projects});

  }

  toggleEditProfileModal() {
    this.setState(state => ({
      isEditProfileModalOpen: !state.isEditProfileModalOpen
    }));

    this.setState({isUsernameError: false});
  }

  toggleShowToExternalViewer() {
    this.setState(state => ({
      isShownToExternalViewer: !state.isShownToExternalViewer
    }));
  }

  toggleFirstCaseStudyModal() {
    this.setState(state => ({
      isFirstCaseStudyModalOpen: !state.isFirstCaseStudyModalOpen
    }));
  }

  async saveProfileInfo(info) {
    this.setState({isLoading: true});
    console.log('is this loading?!')
    console.log(this.state.isLoading)
    info["prevUsername"] = Users.auth.username;
    console.log("prev username: ", info["prevUsername"]);
    console.log("wants: ", info["username"]);
    console.log("full: ", info);
    var done = await Backend.saveProfileInfo(info);
    console.log("done is: ", done);
    let shouldClose = true;
    // if user tried changing their username
    if (info["prevUsername"] != info["username"]) {
      console.log("ERROR MESSAGE: ", done.usernameErrorMessage);
      if (done.usernameErrorMessage.length > 1) {
        Users.auth.username = info["prevUsername"];
        alert("username is already taken.");
        //this.forceUpdate();
        this.setState({isUsernameError: true});
        shouldClose = false;
      } else {
        this.setState({isUsernameError: false});
        Users.auth.username = info["username"];
        this.setState({username: info["username"]});
        this.props.history.push('/u/' + info["username"]);
      }
    }
    await this.setUser();
    this.setState({isLoading: false});

    if (shouldClose) {
      this.toggleEditProfileModal();
    }
  }

  async handleLogout() {
    await Auth.signOut();
    Users.auth = Users.emptyUser
    this.props.userHasAuthenticated(false);
    this.props.history.push("/");
  }

  seeProject(projectId) {
    this.props.history.push('/u/' + this.props.match.params.username + '/' + projectId);
  }

  goToReviews() {
    this.props.history.push('/review-lander');
  }

  seeSkill(skillName) {
    this.props.history.push('/u/' + this.props.match.params.username + '/s/' + skillName);
  }

  async fetchReviewerInfo() {
    let reviewQueue = await Backend.getBestQueueForReviewer(Users.auth.userId)
    console.log("whole review queue: ", reviewQueue);
    this.setState({reviewQueue: reviewQueue});
  }


  render() {
    let match = this.props.match
    let userPageTitle = 'Symbol'

    if (this.state.user.firstName && this.state.user.lastName) {
      userPageTitle = this.state.user.firstName + ' ' + this.state.user.lastName + ' | Symbol'
    }

    return (
      <div className="App">
        <Helmet>
          <title>{userPageTitle}</title>
        </Helmet>

        {this.state.isShownToExternalViewer ? null:
          <EditProfileModal
            toggleEditProfileModal={this.toggleEditProfileModal}
            isEditProfileModalOpen={this.state.isEditProfileModalOpen}
            user={Users.auth}
            username={this.state.username}
            isUsernameError={this.state.isUsernameError}
            isLoading={this.state.isLoading}
            saveProfileInfo={this.saveProfileInfo}
          />
        }
        <ProfileHeader
          isShownToExternalViewer={this.state.isShownToExternalViewer}
          toggleEditProfileModal={this.toggleEditProfileModal}
          toggleShowToExternalViewer={this.toggleShowToExternalViewer}
          handleLogout={this.handleLogout}
          goToReviews={this.goToReviews}
          user={this.state.user}
          reviewsNeeded={this.state.reviewQueue.length > 0}
        />
        {/* this.state.isLoading && <div className="loading-overlay"> <div className="loading-container"><AnimatingSpinnerBig/></div></div> */}
        <ProfileSkillsSection
          isShownToExternalViewer={this.state.isShownToExternalViewer}
          user={this.state.user}
          seeSkill={this.seeSkill}
        />
        <ProfileProjectsSection
          isShownToExternalViewer={this.state.isShownToExternalViewer}
          createNewProject={this.createNewProject}
          projects={this.state.projects}
          seeProject={this.seeProject}
          user={this.state.user}
          isNewProjectLoading={this.state.isNewProjectLoading}
          createFirstCaseStudy={this.createNewProject}
        />
        <GenericModal
           isModalOpen={this.state.isFirstCaseStudyModalOpen}
           toggleModal={this.toggleFirstCaseStudyModal}
           title={'Your first case study!'}
           message={
             <div>On Symbol,
               <br></br><br></br>
             If you've added any new skills to this case study, they've been submitted for assessment and scoring.
               <br></br><br></br>
               To get your scores, make sure you contribute to the community and submit your peer reviews.
             </div>
           }
           primaryActionLabel={'Continue'}
           primaryAction={this.createNewProject}


        />
        <BottomBadge
          isAuth={Users.auth.username}
          onUser={this.props.match.params.username}
        />
      </div>
    )
  }
}

export default UserPage;
