import React, { Component } from 'react';
import './projects-preview-section.css';
//import Projects from './data/projects.js';
import TextareaAutosize from 'react-autosize-textarea';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import testState from '../editor/__mocks__/testState'
import { getWordCountNormal } from '../editor/Editor/utils'
import {
  EMBEDLY_KEY,
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET
} from '../editor/constants'
import Editor from '../editor/Editor'
import ReactDOM from 'react-dom';


class ProjectsPreviewSection extends Component {

  constructor(props) {
    super(props);
    this.refA = React.createRef();
    this.refB = React.createRef();
  }

  scrollToDomRef = () => {
        const refA = ReactDOM.findDOMNode(this.refA.current)
        refA.scrollIntoView()
        const refB = ReactDOM.findDOMNode(this.refB.current)
        refB.scrollIntoView()
  }

  componentWillReceiveProps(props) {
    const { projectA, projectB } = this.props;
    if (props.projectB !== projectA && props.projectB !== projectB) {
      this.scrollToDomRef();
    }
  }

  render() {

    let processA = EditorState.createWithContent(convertFromRaw(this.props.projectA.process? JSON.parse(JSON.parse(this.props.projectA.process)) :testState))
    let processB = EditorState.createWithContent(convertFromRaw(this.props.projectB.process? JSON.parse(JSON.parse(this.props.projectB.process)) :testState))
    let outcomeA = EditorState.createWithContent(convertFromRaw(this.props.projectA.outcome? JSON.parse(JSON.parse(this.props.projectA.outcome)) :testState))
    let outcomeB = EditorState.createWithContent(convertFromRaw(this.props.projectB.outcome? JSON.parse(JSON.parse(this.props.projectB.outcome)) :testState))

    return(
      <div className='projects-preview-section'>
        <div className='projects-preview-section-container'>
          <div className='project-section-container'>
            <div className='project-container'>

              <div ref={this.refA} className='project-section'>
                <div className='project-section-label'>
                  Overview
                </div>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                      <TextareaAutosize
                         value={this.props.projectA.description}
                         readOnly={true}
                         className='project-section-body-textarea'
                       />
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='project-section'>
                <div className='project-section-label'>
                  Process
                </div>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                      <Editor
                        apiKeys={{ EMBEDLY_KEY, CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET }}
                        editorState={processA}
                        // editorState={EditorState.createWithContent(convertFromRaw(testState))}
                        readOnly={true}
                        className='project-section-body-text'
                      />
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='project-section'>
                <div className='project-section-label'>
                  Outcome
                </div>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                      <Editor
                        apiKeys={{ EMBEDLY_KEY, CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET }}
                        editorState={outcomeA}
                        readOnly={true}
                        className='project-details-section-textarea-for-draft'
                      />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='project-section-container'>
            <div className='project-container'>

              <div ref={this.refB} className='project-section'>
                <div className='project-section-label'>
                  Overview
                </div>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                    <TextareaAutosize
                       value={this.props.projectB.description}
                       readOnly={true}
                       className='project-section-body-textarea'
                     />
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='project-section'>
                <div className='project-section-label'>
                  Process
                </div>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                      <Editor
                        apiKeys={{ EMBEDLY_KEY, CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET }}
                        editorState={processB}
                        readOnly={true}
                        className='project-details-section-textarea-for-draft'
                      />
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='project-section'>
                <div className='project-section-label'>
                  Outcome
                </div>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                      <Editor
                        apiKeys={{ EMBEDLY_KEY, CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET }}
                        editorState={outcomeB}
                        readOnly={true}
                        className='project-details-section-textarea-for-draft'
                      />
                    </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>

      </div>

      );
  }
}

export default ProjectsPreviewSection;
