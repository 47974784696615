import React from 'react'

const SkillBlockLock = props => (
  <svg width={10} height={12} {...props}>
    <path
      d="M9.092 5.795a.75.75 0 0 0-.55-.225h-.258V4.03c0-.984-.355-1.83-1.066-2.535-.71-.706-1.56-1.06-2.551-1.06-.991 0-1.842.354-2.552 1.06-.71.706-1.066 1.55-1.066 2.535v1.54H.791a.75.75 0 0 0-.55.225.74.74 0 0 0-.226.546v4.62a.74.74 0 0 0 .227.546.75.75 0 0 0 .549.225h7.752a.75.75 0 0 0 .549-.225.741.741 0 0 0 .226-.545V6.34a.74.74 0 0 0-.226-.546zM6.734 5.57H2.599V4.03c0-.567.202-1.051.606-1.452.404-.401.891-.602 1.462-.602.57 0 1.057.2 1.461.602.404.401.606.885.606 1.452v1.54z"
      fill={props.isPrivate ? "#348EFF" : "#C3C3C3"}
      fillRule="nonzero"
    />
  </svg>
)

export default SkillBlockLock
