import React, { Component } from 'react';
import './publish-menu.css';
//import Project from '../../data/project.js'
import Backend from '../backend.js'
import CircleSpacer from '../../assets/svg/circle-spacer.js'
import PublishMenuTriangle from '../../assets/svg/publish-menu-triangle.js'
import PublicGlobeIcon from '../../assets/svg/public-globe-icon.js'
import OpenUrlArrowIcon from '../../assets/svg/open-url-arrow-icon.js'
import CheckboxCheckmark from '../../assets/svg/checkbox-checkmark.js'
import Icon from 'react-icons-kit'
import {exclamation} from 'react-icons-kit/fa/exclamation'


class PublishMenu extends Component {

  constructor(props) {
      super(props);
      this.state = {
        isOptInToQuantify: false,
        isOptInToQuantifyPublicly: false,
      };

      this.toggleIsOptInToQuantify = this.toggleIsOptInToQuantify.bind(this);
      this.toggleIsOptInToQuantifyPublicly = this.toggleIsOptInToQuantifyPublicly.bind(this);
      this.onUnpublishClick = this.onUnpublishClick.bind(this);
      this.onDeleteClick = this.onDeleteClick.bind(this);
      this.onPublishProjectClick = this.onPublishProjectClick.bind(this);
  }

  onDeleteClick() {
    // show modal of are you sure?
    this.props.toggleDeleteCaseStudyModal();
  }
  onUnpublishClick () {
    // this.props.toggleUnpublishCaseStudyModal();
    this.props.onUnpublishClick(this.state.isOptInToQuantify, this.state.isOptInToQuantifyPublicly)
    this.props.togglePublishMenu();
  }

  async onPublishProjectClick () {
    if (!this.props.isReadyToPublish) {
      return;
    }

    await this.props.onPublishProjectClick(this.state.isOptInToQuantify, this.state.isOptInToQuantifyPublicly)
    console.log('do we even find these ones?',this.props.didTitlePublishValidationFail)
    if (this.props.validatePublish()) {
      this.props.togglePublishMenu();
      this.props.togglePreviewMode();
    }

  }

  toggleIsOptInToQuantify() {
    this.setState(state => ({
      isOptInToQuantify: !state.isOptInToQuantify
    }));
    if (this.state.isOptInToQuantify) {
      this.setState(state => ({
        isOptInToQuantifyPublicly: false
      }))
    }
  }

  toggleIsOptInToQuantifyPublicly() {
    if (this.state.isOptInToQuantify) {
      this.setState(state => ({
        isOptInToQuantifyPublicly: !state.isOptInToQuantifyPublicly
      }))
    }
  }

  render() {

    const notPublishedSkillState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          Publish this case study to submit it for review. Once submitted, your skills will each be assessed and scored. Scores will be added to your profile, and you can choose to make them private or public.
        </div>
      </div>

    const publishedAndNoneQuantifiedSkillState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          Your skills are currently being assessed and scored. Once complete, scores will be added to your profile, and you can choose to make them private or public. You can add new skills at any time.
        </div>
      </div>

    const publishedAndQuantifiedSkillState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          At least one of your skills has been assessed, scored, and added to this case study. Any scores in progress will continue assessment and will be added to this case study when complete.
        </div>
      </div>

    const unpublishedAndNoneQuantifiedSkillState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          Your skills are no longer being assessed and scored. Republish this case study to resume the skill assessment process.
        </div>
      </div>

    const unpublishedAndQuantifiedSkillState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          Until you republish, your scored skills have been hidden and the skill assessment process for in-progress skills has been paused.
        </div>
      </div>


    const notPublishedEditState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          {"After publishing, you can make edits up until your skill assessment is complete. Once it's complete, you can no longer make edits."}
        </div>
      </div>

    const publishedAndNoneQuantifiedEditState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          {"You can make edits up until your skill assessment is complete. Once it's complete, you can no longer make edits."}
        </div>
      </div>

    const publishedAndQuantifiedEditState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          {"You can no longer make edits since some scores have been finalized. You can still add new skills or change skill privacy settings."}
        </div>
      </div>

    const unpublishedAndNoneQuantifiedEditState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          {"After republishing, you can make edits up until skill assessment is complete. Once it's complete, you can no longer make edits."}
        </div>
      </div>

    const unpublishedAndQuantifiedEditState =
      <div className='quantified-skills-secondary-row-container'>

        <div className='quantified-skills-body-text'>
          {"You can no longer make edits since some scores have been finalized. You can still add new skills or change skill privacy settings."}
        </div>
      </div>


      let publishMenuSkillMessage
      let skillStatusMessage
      let publishMenuEditMessage
      let editStatusMessage

      const numberOfSkills = Object.keys(this.props.project.skills).map(key => {return this.props.project.skills[key]}).length
      const numberOfSkillsQuantified = Object.keys(this.props.project.skills).map(key => {return this.props.project.skills[key]}).filter(filter_quantified).length

      function filter_quantified(skill) {
        return skill.state == 'quantified';
      }

      if (this.props.project.publishState == "not_published") {
        publishMenuSkillMessage = notPublishedSkillState
        skillStatusMessage = "Skills not published"
        publishMenuEditMessage = notPublishedEditState
        editStatusMessage = "Edits enabled"
      }

      if (this.props.project.publishState == "published" && numberOfSkillsQuantified==0) {
        publishMenuSkillMessage = publishedAndNoneQuantifiedSkillState
        skillStatusMessage = "Assessment in progress"
        publishMenuEditMessage = publishedAndNoneQuantifiedEditState
        editStatusMessage = "Edits enabled"
      }

      if (this.props.project.publishState == "published" && numberOfSkillsQuantified>0) {
        publishMenuSkillMessage = publishedAndQuantifiedSkillState
        skillStatusMessage = "Scores finalized"
        publishMenuEditMessage = publishedAndQuantifiedEditState
        editStatusMessage = "Edits disabled"
      }

      if (this.props.project.publishState == "unpublished" && numberOfSkillsQuantified==0) {
        publishMenuSkillMessage = unpublishedAndNoneQuantifiedSkillState
        skillStatusMessage = "Assessment paused"
        publishMenuEditMessage = unpublishedAndNoneQuantifiedEditState
        editStatusMessage = "Edits enabled"
      }

      if (this.props.project.publishState == "unpublished" && numberOfSkillsQuantified>0) {
        publishMenuSkillMessage = unpublishedAndQuantifiedSkillState
        skillStatusMessage = "Scores hidden and paused"
        publishMenuEditMessage = unpublishedAndQuantifiedEditState
        editStatusMessage = "Edits disabled"
      }


    return (
      <div className='publish-menu-container'>
        <div className='publish-menu-triangle-container'>
          <div className='publish-menu-triangle'>
            <PublishMenuTriangle />
          </div>
        </div>
        <div className='publish-menu'>
          <div className='publish-menu-top-title-bar'>
            <div className='publish-menu-top-title-bar-label'>
              Publish Menu
            </div>
          </div>

          <div className='publish-status-bar'>
            <div className='publish-status-bar-container'>
              <div className='publish-status-bar-project-url-container'>
                <div className='publish-status-bar-project-url'>
                  Publish status
                </div>

              </div>

              <div className='publish-status-bar-secondary-row-container'>

              <div className='publish-status-bar-publish-state'>
                 {this.props.project.publishState == 'published' ? "Published":null}
                 {this.props.project.publishState == 'not_published' ? 'Not published':null}
                 {this.props.project.publishState == 'unpublished' ? 'Unpublished':null}
               </div>


               <div className='publish-status-bar-circle-separator'>
                 <div className='publish-status-bar-circle-separator-container'>
                   <CircleSpacer className='publish-status-bar-circle-separator-icon'/>
                 </div>
               </div>



                {this.props.project.publishState == 'published' ?
                  <div className='publish-status-bar-unpublish' onClick={this.onUnpublishClick}>
                    Unpublish
                  </div>
                :null}

                {this.props.project.publishState == 'published' ?
                  <div className='publish-status-bar-circle-separator'>
                    <div className='publish-status-bar-circle-separator-container'>
                      <CircleSpacer className='publish-status-bar-circle-separator-icon'/>
                    </div>
                  </div>
                :null}

                <div className='publish-status-bar-unpublish' onClick={this.onDeleteClick}>
                  Delete case study
                </div>

              </div>
            </div>
          </div>


          <div className='quantified-skills-bar'>
            <div className='quantified-skills-bar-label-container'>
              <div className='quantified-skills-bar-label'>
                Skill assessment
              </div>
              <div className='publish-status-bar-publish-state'>
                {skillStatusMessage}
               </div>





              {publishMenuSkillMessage}


            </div>
          </div>

          <div className='quantified-skills-bar'>
            <div className='quantified-skills-bar-label-container'>
              <div className='quantified-skills-bar-label'>
                Editing this case study
              </div>
              <div className='publish-status-bar-publish-state'>
                {editStatusMessage}
               </div>

              {publishMenuEditMessage}


            </div>
          </div>
          {!this.props.validatePublish() && this.props.hasPublished?
          <div className='quantified-skills-bar'>
            <div className='quantified-skills-bar-label-container'>
              <div className='publish-status-bar-publish-state-error'>
                Make sure you fill in all the required fields before publishing. These include Title, Overview, End Date, Process, and Outcome.
               </div>
            </div>
          </div>
          :null
          }

          <div className='publish-menu-buttons'>
            <div className='publish-menu-buttons-container'>
            {!this.props.validatePublish() && this.props.hasPublished?
              <div className='publish-menu-publish-button-disabled'>
                <div className='publish-menu-publish-button-container'>
                  <div className='publish-menu-publish-button-label'>
                    Publish
                  </div>
                </div>
              </div>
              :
              <div className={this.props.isReadyToPublish ? 'publish-menu-publish-button' : 'publish-menu-publish-button-saving'} onClick={this.onPublishProjectClick}>
                <div className='publish-menu-publish-button-container'>
                  <div className='publish-menu-publish-button-label'>
                    {this.props.isReadyToPublish? `Publish`:`Saving...`}
                  </div>
                </div>
              </div>
            }

              <div className='publish-menu-close-button' onClick={this.props.togglePublishMenu}>
                <div className='publish-menu-close-button-container'>
                  <div className='publish-menu-close-button-label'>
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  };
}

export default PublishMenu;
