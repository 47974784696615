// @flow

import request from 'superagent'
import React, { Component, Fragment } from 'react'
import { EditorState, EditorBlock } from 'draft-js'
import { replaceWithAtomicBlock } from '@djsp/utils'
import Textarea from 'react-textarea-autosize'
import AtomicBlock from '@djsp/atomic-block'
import { withPluginContext } from '@djsp/core'
import Spinner from './Spinner'
import SideToolbar from './SideToolbar'

class UnstyledBlock extends Component {
  state = {
    insertLink: null,
    error: false,
    isLoading: false,
  }

  toggleSidebar = () => {
    const { insertLink } = this.state
    const { setReadOnly } = this.props.blockProps

    if (insertLink === null) {
      this.setState({ insertLink: '' })
      setReadOnly(true)
    } else {
      this.setState({ insertLink: null })
      setReadOnly(false)
    }
  }

  uploadImage = (file) => {
    const { apiKeys, setReadOnly, setEditorState, editorState } = this.props.blockProps

    const url = `https://api.cloudinary.com/v1_1/${
      apiKeys.CLOUDINARY_CLOUD_NAME
    }/upload`;

    setReadOnly(true)
    this.setState({ isLoading: true })

    const photoId = this.photoId++;
    const fileName = file.name;
    request.post(url)
    .field('upload_preset', apiKeys.CLOUDINARY_UPLOAD_PRESET)
    .field('max_width', 900)
    .field('file', file)
    .field('multiple', false)
    .end((error, response) => {
      setReadOnly(false)
      if (error != null) {
        this.setState({ isLoading: false })
        alert('The image could not be uploaded, please try again')
      } else {
        this.setState({ isLoading: false })
        setEditorState(replaceWithAtomicBlock(editorState, 'image', response.body))
      }
    });
  }

  embedLink = (link) => new Promise((resolve, reject) => {
    const { apiKeys } = this.props.blockProps

    request.get('https://api.embedly.com/1/oembed')
    .query({
      url: link,
      key: apiKeys.EMBEDLY_KEY,
      width: 700,
    })
    .end((error, response) => {
      if (error != null) {
        reject(error)
      } else {
        resolve(response)
      }
    })
  })

  onLinkKeyDown = event => {
    const { insertLink } = this.state
    const { setEditorState, editorState } = this.props.blockProps

    if ((insertLink == null || insertLink.length === 0 && event.key === 'Backspace') || event.key === 'Escape') {
      event.preventDefault()
      this.toggleSidebar()
    } else if (event.key === 'Enter') {
      // set loading state
      event.preventDefault()
      this.setState({ isLoading: true })

      this.embedLink(event.target.value)
        .then(response => {
          // replace current block with atomic block
          const { body } = response
          if (body.type === 'video') {
            this.setState({ isLoading: false })
            this.toggleSidebar()
            setEditorState(replaceWithAtomicBlock(editorState, 'video', body))
          } else {
            this.setState({ isLoading: false })
            this.toggleSidebar()
            setEditorState(replaceWithAtomicBlock(editorState, 'embed', body))
          }
        })
        .catch(err => this.setState({ isError: true, isLoading: false }))
    }
  }

  render() {
    const {
      block,
      selection
    } = this.props

    const { insertLink, isError, isLoading } = this.state

    const showSidebar =
      selection.isCollapsed() &&
      selection.getStartKey() === block.getKey() &&
      block.getText().length === 0

    return (
      <div className="block paragraph">
        {insertLink === null
          ? <EditorBlock {...this.props} />
          : <div onInput={e => {
            e.stopPropagation()
            e.preventDefault()
          }}>
            <div contentEditable={false}>
              <Textarea
                autoFocus={true}
                className="insert-link-input"
                type="text"
                onKeyDown={this.onLinkKeyDown}
                onChange={({ target: { value: insertLink }}) => this.setState({ isError: false, insertLink })}
              />
            </div>
          </div>
        }
        {insertLink !== null &&
          insertLink.length === 0 && (
            <div contentEditable={false} className="insert-link-placeholder">
              <em>Paste link here</em>
            </div>
          )}
        {isError === true &&
          <div contentEditable={false} className="insert-link-error">
            This link can't be embedded, please try again.
          </div>
        }
        {insertLink !== null &&
          !isError &&
          insertLink.length > 0 && (
            <Fragment>
              <div contentEditable={false} className="insert-link-instructions">
                Press <pre className="key-press">Enter</pre> when done
              </div>
            </Fragment>
          )}
        {showSidebar && <div key="side-toolbar" onInput={event => event.stopPropagation()}>
          <div contentEditable={false}>
            <SideToolbar
              hasFocus={selection.getHasFocus()}
              onUploadImage={this.uploadImage}
              onClickEmbed={this.toggleSidebar}
            />
          </div>
        </div>}
        {isLoading && <Spinner />}
      </div>
    )
  }
}

class InsertBlock extends Component {
  componentDidMount() {
    const { registerPlugin } = this.props

    this._unregister = registerPlugin({
      blockRendererFn: block => {
        const { readOnly } = this.props.editorProps

        if (block.getType() === 'unstyled') {
          return {
            component: UnstyledBlock,
            editable: readOnly !== true,
            props: this.props
          }
        }
      }
    })
  }

  componentWillUnmount() {
    this._unregister()
  }

  render() {
    return null
  }
}

export default withPluginContext(InsertBlock)
