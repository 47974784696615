import React, { Component } from 'react'
import './Project-hero.css';
import BackArrow from '../../assets/svg/back-arrow.js'
import EditHeaderButton from '../../assets/svg/edit-header-button.js'
import Backend from '../backend.js'
import TextareaAutosize from 'react-autosize-textarea'
import BGImageGallery from './bg-image-gallery'
import Images from '../../img/images'
import EditHeaderMenuTriangle from '../../assets/svg/edit-header-menu-triangle.js'
import Projects from "../../data/projects.js";

class ProjectHero extends Component {

  constructor(props) {
		super(props);

    //let project = Object.assign({}, );
    this.state = {
      isEditHeaderOpen: false,
      isGalleryOpen:false,
      isEditHeaderIconShown:true,
      tempBackgroundImage: '',
      project: this.props.hasEdits ? this.props.project.edits : this.props.project,
      hasNewEdits: false,
    }

    console.log('does it have edits: ', this.props.hasEdits)
    console.log('does it actually have edits though ', this.props.project)

		this.handleTitleChange = this.handleTitleChange.bind(this);
    this.toggleIsEditHeaderOpen = this.toggleIsEditHeaderOpen.bind(this);
    this.toggleIsGalleryOpen = this.toggleIsGalleryOpen.bind(this);
    this.setTempBackgroundImage = this.setTempBackgroundImage.bind(this);
    this.getSignedUrl = this.getSignedUrl.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.onPhotoSelected = this.onPhotoSelected.bind(this);
    this.handleBackgroundImageChange = this.handleBackgroundImageChange.bind(this);
	}

  async handleTitleChange(event) {
    let project = Object.assign({}, this.state.project);
    project["projectTitle"] = event.target.value;
    console.log('this is the project: ',project)
    this.setState({project: project, hasNewEdits: true});
    this.props.setProjectTitle("projectTitle",project);
    this.props.setReadyToPublish(false);
    await Backend.updateProjectTitle(this.props.project, event.target.value);
    this.props.setReadyToPublish(true);
  }

  async handleBackgroundImageChange(imageUrl) {
    console.log("new setting bg image ", imageUrl);
    let project = Object.assign({}, this.state.project);
    project["backgroundImage"] = imageUrl;
    console.log('this is the project: ', project);

    this.setState({project: project, hasNewEdits: true});
    // this.props.setProjectEdits(project);
    //this.forceUpdate();
    this.props.setReadyToPublish(false);
    console.log("sending to db");
    await Backend.updateProjectBackgroundImage(this.state.project, imageUrl);
    console.log("finished saving in db");
    this.props.setReadyToPublish(true);
  }

  toggleIsEditHeaderOpen() {
    this.setState(state => ({
      isEditHeaderOpen: !state.isEditHeaderOpen
    }));
  }

  toggleIsGalleryOpen() {
    this.setState(state => ({
      isGalleryOpen: !state.isGalleryOpen
    }));

    this.setState(state => ({
      isEditHeaderOpen: false
    }));

    this.setState(state => ({
      tempBackgroundImage: ''
    }));
  }

  setTempBackgroundImage(tempBackgroundImage) {
    this.setState(state => ({
      tempBackgroundImage: tempBackgroundImage
    }));
  }

  async getSignedUrl(file, callback) {
    console.log("working with file: ", file);
    let response = await Backend.getSignedUrlS3(file, callback);
    console.log("backend s3 response: ", response);
    if (response.status == "error") {
      alert("error uploading your pic");
    } else {
      callback(response);
    }
  }

  async onPhotoSelected(file) {
    this.setState(state => ({isEditHeaderOpen: false,isGalleryOpen:false}));
    file = file[0];
    if (file) {
      const userId = this.props.userId;
      this.props.setIsLoading(true);
      let result = await Backend.uploadPhotoToCloudinary(file, userId, "backgroundImageCS");
      let secureUrl = result.secure_url;
      this.handleBackgroundImageChange(secureUrl);
      setTimeout(() => { this.props.setIsLoading(false); }, 1000);
    }

  }

  onUploadFinish(e) {
    console.log("finished uploading ", e);
    this.handleBackgroundImageChange(e.url);
    //this.props.setBackgroundImage(e.url);
  }

  onUploadError(e) {
    console.log("error uploading: ", e);
  }


  render() {

    let hasNewEdits = this.props.hasEdits || this.state.hasNewEdits

    let projectTitle = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.projectTitle?this.state.project.projectTitle:"") : (this.props.project.projectTitle?this.props.project.projectTitle:"")
    let backgroundImage = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.backgroundImage?this.state.project.backgroundImage:"") : (this.props.project.backgroundImage?this.props.project.backgroundImage:"")


    return(
      <div>
        <div className='project-hero'>

          <img src={backgroundImage} className='hero-background-image'/>
          {!this.state.isGalleryOpen? null:
            <img src={this.state.tempBackgroundImage} className='hero-temp-background-image'/>
          }
          <div  className='project-hero-container'>
            <div className = 'project-hero-top-bar'>
              <div className = 'back-to-profile-arrow' onClick={this.props.onReturnToProfileClick}>
                <BackArrow className='backArrow' /> Back to profile
              </div>
              {(this.props.isAuthUsersProject && !this.props.isInPreviewMode) &&
                <div className='edit-header-button-container'>
                  <div className = 'edit-header-button' onClick={this.toggleIsEditHeaderOpen}>
                    <EditHeaderButton className='edit-header-button-icon'/>
                    <div className='edit-header-button-label'> Edit header </div>
                  </div>
                  {!this.state.isEditHeaderOpen? null:
                  <div className='edit-header-dropdown-container'>
                    <div className='edit-header-menu-triangle-container'>
                      <div className='edit-header-menu-triangle'>
                        <EditHeaderMenuTriangle />
                      </div>
                    </div>
                    <div className='edit-header-dropdown'>
                      <div className='edit-header-dropdown-row-image-upload-hover-container'>
                        <div className='edit-header-dropdown-row-image-upload'>
                        <input className='edit-header-dropdown-image-upload'
                              type="file"
                              id="fileupload"
                              accept="image/*"
                              ref={fileInputEl =>
                                  (this.fileInputEl = fileInputEl)
                              }
                              onChange={() => this.onPhotoSelected(this.fileInputEl.files)}/>
                        </div>
                        <div className='edit-header-dropdown-row'>
                          Upload image
                        </div>
                      </div>
                      <div onClick={this.toggleIsGalleryOpen} className='edit-header-dropdown-row'>
                        Choose from gallery
                      </div>
                      <div onClick={this.toggleIsEditHeaderOpen} className='edit-header-dropdown-row-last'>
                        Cancel
                      </div>
                    </div>
                  </div>
                }
                </div>
              }
            </div>
            <div className='project-hero-group-container'>
              <div className='project-hero-group'>
                <div className='editable-project-title'>
                  <TextareaAutosize
                    value={projectTitle}
                    onChange={this.handleTitleChange}
                    placeholder='Add title'
                    id='class-text-area'
                    className={'editable-project-title'}
                    maxLength={66}
                    readOnly={this.props.isInPreviewMode || this.props.isProjectLocked}
                    async={true}
                  />
                </div>
                <div className="profile-person">
                  <div onClick={this.props.onReturnToProfileClick} className="profile-person-container">
                    <div className="profile-person-photo">
                      {this.props.projectUser && this.props.projectUser.profilePhoto != null ?
                        <img src={this.props.projectUser.profilePhoto}/>
                        :
                        <div className='profile-picture-letter-small'>
                          {this.props.projectUser ? this.props.projectUser.firstName[0] : ""}
                        </div>
                      }
                    </div>
                    <div className="profile-person-name">
                      {this.props.projectUser ? this.props.projectUser.firstName + " " + this.props.projectUser.lastName : ""}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        { this.state.isGalleryOpen && !this.props.isInPreviewMode?
          <BGImageGallery
            toggleIsGalleryOpen={this.toggleIsGalleryOpen}
            images={Images}
            setTempBackgroundImage={tempBackgroundImage => this.setState({tempBackgroundImage})}
            setBackgroundImage={this.handleBackgroundImageChange}
            tempBackgroundImage={this.state.tempBackgroundImage}
          />
           : null }
      </div>
      );
  }
}

export default ProjectHero;
