import React, { Component } from 'react';
import './project-details-section.css';
import TextareaAutosize from 'react-autosize-textarea';
import SkillBlock from '../skills/skill-block.js';
import Backend from '../backend.js';
import FormattedInput from "@buttercup/react-formatted-input";
import DateConverter from './date-converter.js';
import convertToDisplaySkill from '../skills/convert-to-display-skill.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import testState from '../editor/__mocks__/testState'
import { getWordCountNormal } from '../editor/Editor/utils'
import {
  EMBEDLY_KEY,
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET
} from '../editor/constants'
import Editor from '../editor/Editor'

let MAX_WORDS_DESCRIPTION = 100;
let MAX_WORDS_PROCESS = 300;
let MAX_WORDS_OUTCOME = 150;

class ProjectDetailsSection extends Component {

  constructor(props) {
      super(props);

      let project = this.props.hasEdits ? this.props.project.edits : this.props.project;
      console.log('passed this project.fadf',project)
      let projectProcess = project.process ? JSON.parse(project.process) : testState;
      let projectPublishedProcess = this.props.project.process ? JSON.parse(this.props.project.process) : projectProcess;
      let projectOutcome = project.outcome ? JSON.parse(project.outcome) : testState;
      let projectPublishedOutcome = this.props.project.outcome ? JSON.parse(this.props.project.outcome) : projectOutcome;

      this.state = {
        location: this.props.project.location,
        project: project,
        readOnly: false,
        hasNewEdits: false,
        editorPublishedState: EditorState.createWithContent(convertFromRaw(projectPublishedProcess)),
        editorState: EditorState.createWithContent(convertFromRaw(projectProcess)),
        outcomeEditorPublishedState: EditorState.createWithContent(convertFromRaw(projectPublishedOutcome)),
        outcomeEditorState: EditorState.createWithContent(convertFromRaw(projectOutcome)),
      };

      this.handleStartDateChange = this.handleStartDateChange.bind(this);
      this.handleEndDateChange = this.handleEndDateChange.bind(this);
      this.handleCompanyChange = this.handleCompanyChange.bind(this);
      this.handleRoleChange = this.handleRoleChange.bind(this);
      this.handleClientChange = this.handleClientChange.bind(this);
      this.handleLocationChange = this.handleLocationChange.bind(this);
      this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
      this.goToHomepage = this.goToHomepage.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onOutcomeChange = this.onOutcomeChange.bind(this);
  }

  async onChange(editorState) {
  /*
   * To send document to an api, use convertToRaw to convert
   * EditorState into a serializable object,
   * convertFromRaw can be used to revive the raw editor state, as shown above
   *
   * saveDocument(convertToRaw(editorState.getCurrentContent()))
   */
   let rawState = convertToRaw(editorState.getCurrentContent());
   let stringState = JSON.stringify(rawState);
   this.setState({ editorState , hasNewEdits: true});
   let project = Object.assign({}, this.state.project);
   project["process"] = stringState;
   this.props.setProjectEdits("process",project);
   this.setState({project: project, hasNewEdits: true});
   if (this.state.project.publishState != "published") {
     this.setState({editorPublishedState: editorState});
   }
   this.props.setReadyToPublish(false);
   await Backend.updateProjectProcess(this.state.project, stringState);
   this.props.setReadyToPublish(true);
 }

 async onOutcomeChange(outcomeEditorState) {
   let rawState = convertToRaw(outcomeEditorState.getCurrentContent());
   let stringState = JSON.stringify(rawState);
   this.setState({ outcomeEditorState , hasNewEdits: true});
   let project = Object.assign({}, this.state.project);
   project["outcome"] = stringState;
   this.props.setProjectEdits("outcome",project);
   this.setState({project: project, hasNewEdits: true});
   if (this.state.project.publishState != "published") {
     this.setState({outcomeEditorPublishedState: outcomeEditorState});
   }
   this.props.setReadyToPublish(false);
   await Backend.updateProjectOutcome(this.state.project, stringState);
   this.props.setReadyToPublish(true);
 }

  async handleStartDateChange(value) {
    let project = Object.assign({}, this.state.project);
    project["startDate"] = value;
    this.setState({project: project, hasNewEdits: true});
    // this.props.setProjectEdits(project)
    this.props.setReadyToPublish(false);
    await Backend.updateProjectStartDate(project, value);
    this.props.setReadyToPublish(true);
  }

  async handleEndDateChange(value) {
    let project = Object.assign({}, this.state.project);
    project["endDate"] = value;
    console.log('endDate: ',project)
    this.setState({project: project, hasNewEdits: true});
    this.props.setProjectEdits("endDate",project);
    this.props.setReadyToPublish(false);
    await Backend.updateProjectEndDate(project, value);
    this.props.setReadyToPublish(true);
  }

  async handleCompanyChange(event) {
    let project = Object.assign({}, this.state.project);
    project["company"] = event.target.value;
    this.setState({project: project, hasNewEdits: true});
    // this.props.setProjectEdits(project)
    this.props.setReadyToPublish(false);
    await Backend.updateProjectCompany(project, event.target.value, event.timeStamp);
    this.props.setReadyToPublish(true);
  }

  async handleRoleChange(event) {
    let project = Object.assign({}, this.state.project);
    project["role"] = event.target.value;
    this.setState({project: project, hasNewEdits: true});
    // this.props.setProjectEdits(project)
    this.props.setReadyToPublish(false);
    await Backend.updateProjectRole(project, event.target.value);
    this.props.setReadyToPublish(true);
  }

  async handleClientChange(event) {
    let project = Object.assign({}, this.state.project);
    project["client"] = event.target.value;
    this.setState({project: project, hasNewEdits: true});
    // this.props.setProjectEdits(project)
    this.props.setReadyToPublish(false);
    await Backend.updateProjectClient(project, event.target.value);
    this.props.setReadyToPublish(true);
  }

  async handleLocationChange(event) {
    let project = Object.assign({}, this.state.project);
    project["location"] = event.target.value;
    this.setState({project: project, hasNewEdits: true});
    // this.props.setProjectEdits(project)
    this.props.setReadyToPublish(false);
    await Backend.updateProjectLocation(project, event.target.value);
    this.props.setReadyToPublish(true);
  }

  async handleDescriptionChange(event) {

    if (getWordCountNormal(event.target.value) <= MAX_WORDS_DESCRIPTION) {
      let project = Object.assign({}, this.state.project);
      project["description"] = event.target.value;
      this.setState({project: project, hasNewEdits: true});
      this.props.setProjectEdits("description",project)
      this.props.setReadyToPublish(false);
      let done = await Backend.updateProjectDescription(project, event.target.value);
      this.props.setReadyToPublish(true);
      console.log("done with updating in backend: ", done);
    } else {
      let project = Object.assign({}, this.state.project);
      this.setState({project: project});
    }
  }

  goToHomepage() {
    window.open('/homepage', "_blank")
  }

  sortSkills(skills) {

    function getSkillValueForSort(skill) {
      let s_val = 0;

      if (skill.state == "quantified") {
        s_val += 500;
      }
      if (skill.ownerNumReqReviews <= 0) {
        s_val += 400;
      }
      if (skill.state == "in_progress") {
        s_val += 300;
      }
      if (skill.state != "waiting_to_publish") {
        s_val += 200;
      }
      return s_val;
    }

    let sortedSkills = skills.sort((s1, s2) => {
      let s1_val = getSkillValueForSort(s1);
      let s2_val = getSkillValueForSort(s2);
      let firstComparison = s2_val - s1_val;
      if (firstComparison != 0) {
        return firstComparison;
      }
      let byScore = s2.displayBucket - s1.displayBucket;
      if (byScore != 0) {
        return byScore;
      }
      // only thing left is by time added.
      let s1_time = new Date(s1.dateCreated);
      let s2_time = new Date(s2.dateCreated);
      if (s1_time < s2_time) {
        return -1;
      } else {
        return 1;
      }
    })

    return sortedSkills;
  }


  render() {
    let hasNewEdits = this.props.hasEdits || this.state.hasNewEdits
    let description = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.description?this.state.project.description:"") : (this.props.project.description?this.props.project.description:"")
    let startDate = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.startDate?this.state.project.startDate:"") : (this.props.project.startDate?this.props.project.startDate:"")
    let endDate = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.endDate?this.state.project.endDate:"") : (this.props.project.endDate?this.props.project.endDate:"")
    let company = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.company?this.state.project.company:"") : (this.props.project.company?this.props.project.company:"")
    let role = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.role?this.state.project.role:"") : (this.props.project.role?this.props.project.role:"")
    let client = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.client?this.state.project.client:"") : (this.props.project.client?this.props.project.client:"")
    let location = !this.props.isInPreviewMode && hasNewEdits ? (this.state.project.location?this.state.project.location:"") : (this.props.project.location?this.props.project.location:"")

    let project = this.props.hasEdits ? this.props.project.edits : this.props.project;
    let projectProcess = project.process ? JSON.parse(project.process) : testState;
    let projectPublishedProcess = this.props.project.process ? JSON.parse(this.props.project.process) : projectProcess;
    let processEditorState = !this.props.isInPreviewMode && hasNewEdits ? this.state.editorState : EditorState.createWithContent(convertFromRaw(projectPublishedProcess));
    let projectOutcome = project.outcome ? JSON.parse(project.outcome) : testState;
    let projectPublishedOutcome = this.props.project.outcome ? JSON.parse(this.props.project.outcome) : projectOutcome;
    let outcomeEditorState = !this.props.isInPreviewMode && hasNewEdits ? this.state.outcomeEditorState : EditorState.createWithContent(convertFromRaw(projectPublishedOutcome));

    // LOOK TO SEE IF THIS PROJECT HAS ANY QUANTIFIED SKILLS -> PASSED INTO SKILL BLOCK

    function filter_quantified(skill) {
      return skill.state == 'quantified';
    }

    function filter_reviews_contributed(skill) {
      return skill.ownerNumReqReviews == 0;
    }

    function filter_public(skill) {
      return skill.private == false;

    }


    let numberOfSkillsQuantified = 0
    if (this.props.project.skills) {
      numberOfSkillsQuantified = Object.keys(this.props.project.skills).map(key => {return this.props.project.skills[key]}).filter(filter_quantified).filter(filter_reviews_contributed).filter(filter_public).length
    }

    console.log('numberofskillsquantified! ', numberOfSkillsQuantified)

    let anyQuantified = false

    if (numberOfSkillsQuantified) {
      anyQuantified = true
    }

    // SETTING ARRAY OF SKILLS AND GENERATING HTML FOR ALL SKILL BLOCKS

    var skills = []
    let sortedSkills
    let projectSkills = !this.props.isInPreviewMode && hasNewEdits && this.props.project.edits && this.props.project.edits.skills ? this.props.project.edits.skills : this.props.project.skills
    if (projectSkills != undefined) {
      let skillsInArrayForm = Object.keys(projectSkills).map((skillName, index) => {
        return projectSkills[skillName]
      });
      sortedSkills = this.sortSkills(skillsInArrayForm)
      skills = sortedSkills.map((skill, index) => {
                          //var skill = projectSkills[skillName];
                          return (
                            <div key={index}>
                              <SkillBlock
                                skill={skill}
                                handleSkillAttrChange={this.props.handleSkillAttrChange}
                                isInPreviewMode={this.props.isInPreviewMode}
                                project={this.props.project}
                                togglePrivate={this.props.togglePrivate}
                                deleteSkill={this.props.deleteSkill}
                                anyQuantified={anyQuantified}
                                from={"project"}
                                history={this.props.history}
                                />
                            </div>
                          );
                      });
    }

    // SETTING DATES TO BE SHOWN WITHIN DETAILS

    let dateValue = DateConverter(this.props.project.startDate,this.props.project.endDate)

    const datePattern = [
      { char: /[01]/ },  // month, 2 digits
      { char: /[0-9]/ }, // "
      { exactly: "/" },
      { char: /[12]/ },                  // year, 4 digits
      { char: /[0-9]/, repeat: 3 }    // "
        ];


    // SETTING MESSAGE FOR SKILLS SECTION ON PROJECT PAGE

    var firstName = this.props.projectUser ? this.props.projectUser.firstName : "This user"

    let previewModeQuantifiedMessage=<div>{firstName}’s skills, as demonstrated by this case study, have been scored and assigned to a percentile score from 1 to 99 based on how {firstName} ranks globally. A score of 60, for example, means {firstName} is more skilled in this area than 60% of all other humans in the world who have this skill. <span className='underline' onClick={()=> window.open('/homepage', "_blank")}>Learn more</span></div>

    if (numberOfSkillsQuantified > 0) {
      previewModeQuantifiedMessage=<div>{firstName}’s skills, as demonstrated by this case study, have been scored and assigned to a percentile score from 1 to 99 based on how {firstName} ranks globally. For example, a score of {sortedSkills[0].displayBucket} in {convertToDisplaySkill(sortedSkills[0].skillName)} means {firstName} is more skilled in this area than {sortedSkills[0].displayBucket}% of all other people who have this skill. <span className='underline' onClick={()=> window.open('/homepage', "_blank")}>Learn more</span></div>

    }

    const previewModeNotMessage=<div>Through this case study, {firstName} has demonstrated the following skills. To see all of {firstName}'s case studies and skills, go to <span onClick={this.props.onReturnToProfileClick} className='underline'>{firstName}'s profile</span>.</div>

    let skillsAreaMessage

  if (numberOfSkillsQuantified > 0) {

    skillsAreaMessage = previewModeQuantifiedMessage

  } else {

    skillsAreaMessage = previewModeNotMessage

  }

    return (
      <div className='project-details'>

        <div className='project-details-container'>

          <div className='project-details-section'>

            <div className='project-details-section-title-container'>
              <div className='project-details-section-title'>
                Overview
              </div>
            </div>
            <div className='project-details-section-textarea-container'>
               <TextareaAutosize
                maxRows={30}
                 onChange={this.handleDescriptionChange}
                 value={description}
                 readOnly={this.props.isInPreviewMode || this.props.isProjectLocked}
                 placeholder={`Use this case study to document a project or past work. Summarize the project here (${MAX_WORDS_DESCRIPTION} words max)`}
                 className='project-details-section-textarea'>

               </TextareaAutosize>
            </div>

            {this.props.isInPreviewMode || this.props.isProjectLocked ? null :
            <div className="project-details-section-metadata-row-container">
              <div className="project-details-section-metadata-row">
                <div className='project-details-section-metadata-row-flex-container'>
                  <div className="project-details-section-metadata-row-label">
                    Start date
                  </div>

                  <FormattedInput
                    className="project-details-section-metadata-row-textarea"
                    format={datePattern}
                    value={startDate}
                    onChange={(formattedValue, raw) => { this.handleStartDateChange(formattedValue); }}
                    placeholder="MM/YYYY (optional)"
                    />
                </div>
              </div>
            </div>
          }


          {this.props.isInPreviewMode || this.props.isProjectLocked ? null :
            <div className="project-details-section-metadata-row-container">
              <div className="project-details-section-metadata-row">
                <div className='project-details-section-metadata-row-flex-container'>
                  <div className="project-details-section-metadata-row-label">
                    End date
                  </div>
                  <FormattedInput
                    className={"project-details-section-metadata-row-textarea"}
                    format={datePattern}
                    value={endDate}
                    onChange={(formattedValue, raw) => { this.handleEndDateChange(formattedValue)}}
                    placeholder="MM/YYYY"
                    />
                </div>
              </div>
            </div>
          }

            {!this.props.isInPreviewMode && !this.props.isProjectLocked ? null:

            <div className="project-details-section-metadata-row-container">
              <div className="project-details-section-metadata-row">
                <div className='project-details-section-metadata-row-flex-container'>
                  <div className="project-details-section-metadata-row-label">
                    Date
                  </div>
                  <TextareaAutosize
                    value={dateValue}
                    readOnly={true}
                    className='project-details-section-metadata-row-textarea'>
                  </TextareaAutosize>
                </div>
              </div>
            </div>
          }

          {(this.props.isInPreviewMode && !this.props.project.company) || (this.props.isProjectLocked && !this.props.project.company) ? null :
            <div className="project-details-section-metadata-row-container">
              <div className="project-details-section-metadata-row">
                <div className='project-details-section-metadata-row-flex-container'>
                  <div className="project-details-section-metadata-row-label">
                    Company
                  </div>
                  <TextareaAutosize
                    onChange={this.handleCompanyChange}
                    value={company}
                    readOnly={this.props.isInPreviewMode || this.props.isProjectLocked}
                    placeholder='Add company name (optional)'
                    className='project-details-section-metadata-row-textarea'>
                  </TextareaAutosize>
                </div>
              </div>
            </div>
          }

            {(this.props.isInPreviewMode && !this.props.project.role) || (this.props.isProjectLocked && !this.props.project.role) ? null :

            <div className="project-details-section-metadata-row-container">
              <div className="project-details-section-metadata-row">
                <div className='project-details-section-metadata-row-flex-container'>
                  <div className="project-details-section-metadata-row-label">
                    Role
                  </div>
                  <TextareaAutosize
                    onChange={this.handleRoleChange}
                    value={role}
                    readOnly={this.props.isInPreviewMode || this.props.isProjectLocked}
                    placeholder='Add role or job title (optional)'
                    className='project-details-section-metadata-row-textarea'>
                  </TextareaAutosize>
                </div>
              </div>
            </div>
          }

            {(this.props.isInPreviewMode && !this.props.project.client) || (this.props.isProjectLocked && !this.props.project.client) ? null :

            <div className="project-details-section-metadata-row-container">
              <div className="project-details-section-metadata-row">
                <div className='project-details-section-metadata-row-flex-container'>
                  <div className="project-details-section-metadata-row-label">
                    Client
                  </div>
                  <TextareaAutosize
                    onChange={this.handleClientChange}
                    value={client}
                    readOnly={this.props.isInPreviewMode || this.props.isProjectLocked}
                    placeholder='Add client (optional)'
                    className='project-details-section-metadata-row-textarea'>
                  </TextareaAutosize>
                </div>
              </div>
            </div>

          }

          {(this.props.isInPreviewMode && !this.state.location) || (this.props.isProjectLocked && !this.state.location) ? null :

            <div className="project-details-section-metadata-row-container">
              <div className="project-details-section-metadata-row">
                <div className='project-details-section-metadata-row-flex-container'>
                  <div className="project-details-section-metadata-row-label">
                    Location
                  </div>
                  <TextareaAutosize
                    onChange={this.handleLocationChange}
                    value={location}
                    readOnly={this.props.isInPreviewMode || this.props.isProjectLocked}
                    placeholder='Add location (optional)'
                    className='project-details-section-metadata-row-textarea'>
                  </TextareaAutosize>
                </div>
              </div>
            </div>
          }

          </div>


          <div className='project-details-section'>

            <div className='project-details-section-title-container'>
              <div className='project-details-section-title'>
                Process
              </div>
            </div>

            <div className='project-details-section-textarea-container'>
              <Editor
                apiKeys={{ EMBEDLY_KEY, CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET }}
                maxWords={MAX_WORDS_PROCESS}
                onChange={this.onChange}
                editorState={processEditorState}
                setReadOnly={readOnly => this.setState({ readOnly })}
                readOnly={
                  this.state.readOnly || (this.props.isInPreviewMode || this.props.isProjectLocked)
                  || this.state.readOnly
                }
                placeholder={`Explain the work you did, the decisions you made, and your thought process during the project here (${MAX_WORDS_PROCESS} words max)`}
                className='project-details-section-textarea-for-draft'

              />
            </div>
          </div>

          <div className='project-details-section'>

            <div className='project-details-section-title-container'>
              <div className='project-details-section-title'>
                Outcome
              </div>
            </div>

            <div className='project-details-section-textarea-container'>

            <Editor
              apiKeys={{ EMBEDLY_KEY, CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET }}
              maxWords={300}
              onChange={this.onOutcomeChange}
              editorState={outcomeEditorState}
              setReadOnly={readOnly => this.setState({ readOnly })}
              readOnly={
                this.state.readOnly || (this.props.isInPreviewMode || this.props.isProjectLocked)
                || this.state.readOnly
              }

              contentEditable={false}
              placeholder='Describe or show the outcome of your project here (150 words maximum)'
              className='project-details-section-textarea-for-draft'
            />
            </div>
          </div>
        </div>

        <div className='project-details-skill-area-flex-container'>
          {skills.length === 0 && this.props.isInPreviewMode? null:
          <div className='project-details-skill-area'>
            <div className='project-details-skill-area-container'>
              <div className='project-details-skill-area-text-container'>
                <div className='project-details-skill-area-text'>
                  <div className='project-details-skill-area-label'>
                    Skills
                  </div>
                </div>
              </div>
              <div className='project-details-skill-area-text-container'>
                <div className='project-details-skill-area-text'>
                  <div className='project-details-skill-area-body'>
                    {this.props.isInPreviewMode ?
                      <div>{skillsAreaMessage}</div>
                      :

                    <div>Tag this case study with the skills it demonstrates. After publishing, these skills will be assessed and assigned to a percentile score from 1 to 99 based on how you rank globally. You can choose to share these scores publicly or keep them private for your own reference. <span className='underline' onClick={()=> window.open('/homepage', "_blank")}>Learn more</span></div>



                  }



                  </div>
                </div>
              </div>
            </div>
            <div
              key={this.props.project.projectId + "skills"}
             className='project-details-skill-area-text-container'>
              <div className='skills-container'>
                {skills}
              </div>
            </div>
            {this.props.isInPreviewMode ? null :
              <div>
                <div className='skill-area-divider'></div>
                <div onClick={this.props.toggleAddSkillModal} className='add-skill-button'>
                  Add skill
                </div>
              </div>
            }


          </div>
        }
        </div>

      </div>
    );
  };
}

export default ProjectDetailsSection;
