import React from "react"

const EditHeaderMenuTriangle= () => (
  <svg width={25} height={14}>
    <g fill="#FFFFFF" fillRule="evenodd">
      <path d="M23.1 11H1.9L12.5 1l10.6 10z" stroke="#EBEDF2" />
      <path d="M3.18 10.5h18.65l3.17 3H0z" />
    </g>
  </svg>
)

export default EditHeaderMenuTriangle
