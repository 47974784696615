import React, { Component } from 'react';
import './App.css';
import ProjectHero from './project/Project-hero';
import BGImageGallery from './project/bg-image-gallery'
import Images from '../img/images'
import CommandBar from './project/command-bar.js'
import ProjectDetailsSection from './project/project-details-section.js'
import AddSkillModal from './project/add-skill-modal.js'
import Backend from "./backend.js"
import ProjectPage from "./project/project-page.js"
import UserPage from "./user/user-page.js"
import ReviewsPage from "./reviews/reviews-page.js"
import {BrowserRouter as Router, Route, Link, Redirect, Switch } from 'react-router-dom'
import { Auth } from "aws-amplify";
import HomePage from "./homepage/homepage.js"
import Users from "../data/user.js";
import SkillPage from './skill-page/skill-page.js'
import ScrollToTop from './ScrollToTop.js'
import ReviewLander from './reviews/review-lander.js'
import BottomBadge from "./branding/bottom-badge.js";
import { FullStory, FullStoryClient } from 'react-fullstory-component';

const fullStorySettings = {
    debug: true,
    host: 'www.fullstory.com',
    orgKey: 'G62CF'
};
// const fullStoryClient = new FullStoryClient({
//     host: 'www.fullstory.com',
//     orgKey: 'G62CF',
//     iframe: true
// });

// const data = {
//     key: value
// };
const fullStorySessionId = '123456789';

const Home = () => (
  <div>
    <h2>Home</h2>
    <div>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/u">Users</Link>
        </li>
      </ul>
      <hr/>
    </div>
  </div>
);

const ProjectPageConst = ({match}) => (
  <ProjectPage match={match}/>
);

const UsersPage = ({ match }) => {
  return (
    <div>
      <h2>Users</h2>
      <ul>
        <li>
          <Link to={`${match.url}/valentin`}>Valentin</Link>
        </li>
        <li>
          <Link to={`${match.url}/max`}>Max</Link>
        </li>
        <li>
          <Link to={`${match.url}/thirdgod`}>Third God</Link>
        </li>
        <li>
          <Link to={`${match.url}/fourthgod`}>Fourth God</Link>
        </li>
      </ul>
      <Route exact path={match.url} render={() => <h3>Please select a user.</h3>} />
    </div>
  )
}

// const User = ({ match, history }) => (
//   <UserPage match={match} history={history} />
// );

class App extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isAuthenticated: false,
        isAuthenticating: true,
        selectedImage: "",
        galleryOpen: false,
        editHeaderDropDown:false,
        isInPreviewMode: false,
        isAddSkillModalOpen: false,
        userId: undefined,
      };
      this.toggleAddSkillModal = this.toggleAddSkillModal.bind(this);
      this.togglePreviewMode = this.togglePreviewMode.bind(this);
      this.userHasAuthenticated = this.userHasAuthenticated.bind(this);
  }

  async checkUserAuthorization() {
    this.setState({isLoading: true});
    try {
      let user = await Auth.currentAuthenticatedUser();
      console.log("current authenticated user from cognito: ", user);
      let userId = user.username;
      Users.auth["userId"] = userId;
      let session = user.signInUserSession;
      let fullUser = await Backend.fetchUser(userId);

      Users.auth["username"] = fullUser.username;
      Users.auth["firstName"] = fullUser.firstName;
      Users.auth["lastName"] = fullUser.lastName;
      Users.auth["email"] = fullUser.email;
      Users.auth["bio"] = fullUser.bio;
      Users.auth["callToActionLink"] = fullUser.callToActionLink;
      Users.auth["callToActionText"] = fullUser.callToActionText;
      for (var property in fullUser) {
          if (fullUser.hasOwnProperty(property)) {
            Users.auth[property] = fullUser[property]
          }
      }

      if (session) {
        console.log("user authenticated!!");
        console.log("THE ACCESSTOKEN: ", session.accessToken);
        Users.auth["accessToken"] = session.accessToken
        this.userHasAuthenticated(true, userId);
      }
      // console.log("got the auth user: ", user);
      //this.setState({isShownToExternalViewer: false});
    } catch (e) {
      console.log("error finding auth user: ", e.message);
    }
    this.setState({ isAuthenticating: false });
  }

  componentDidMount() {
    this.checkUserAuthorization();
  }

  userHasAuthenticated(authenticated, userId) {
    console.log("here authenticated: ", authenticated, userId)
    // fullStoryClient.render();
    // fullStoryClient.setSession(userId)
    this.setState({isAuthenticated: authenticated, userId: userId});
  }

  toggleAddSkillModal() {
    this.setState(state => ({
      isAddSkillModalOpen: !state.isAddSkillModalOpen
    }));
  }

  togglePreviewMode() {
    this.setState(state => ({
      isInPreviewMode: !state.isInPreviewMode
    }));
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
    let authUserProfilePath = "/u/" + Users.auth["username"];

    return (
      <Router>
      <ScrollToTop>
      {!this.state.isAuthenticating &&
        <div>
          <FullStory settings={fullStorySettings} sessionId={this.state.userId}/>
          <Switch>
            <Route exact path="/u/:username/:projectId" render={(props) => <ProjectPage {...props} isAuthenticated={this.state.isAuthenticated} /> }/>
            <Route exact path="/u/:username/s/:skillName" render={(props) => <SkillPage {...props} isAuthenticated={this.state.isAuthenticated} /> }/>
            <Route exact path="/u/:username/" render={(props) => <UserPage {...props} isAuthenticated={this.state.isAuthenticated} userHasAuthenticated={this.userHasAuthenticated} /> }/>
            <Route exact path="/" render={(props) => this.state.isAuthenticated ?
                                          (<Redirect to={{pathname: authUserProfilePath}} />) :
                                          (<HomePage {...props} isAuthenticated={this.state.isAuthenticated} userHasAuthenticated={this.userHasAuthenticated} />) } />
            <Route exact path="/reviews" render={(props) => this.state.isAuthenticated ?
              (<ReviewsPage {...props} isAuthenticated={this.state.isAuthenticated} />) :
              (<Redirect to={{pathname: "/", redirected: { from: props.location } }} />)  }/>

            <Route exact path="/review-lander" render={(props) => this.state.isAuthenticated ?
              (<ReviewLander {...props} isAuthenticated={this.state.isAuthenticated} />) :
              (<Redirect to={{pathname: "/", redirected: { from: props.location } }} />)  }/>

              <Route exact path="/homepage" render={(props) => this.state.isAuthenticated ?
                (<HomePage {...props} isAuthenticated={this.state.isAuthenticated} />) :
                (<Redirect to={{pathname: "/", redirected: { from: props.location } }} />)  }/>

            {/* Catch-All route. Later will be awesome 404 page. */}
            <Route render={(props) => <Redirect to={{pathname: "/"}} /> } />

          </Switch>
          {/* <BottomBadge style={{visibility:this.state.isAuthenticated?"hidden":"visible"}}/> */}

        </div>

      }
      </ScrollToTop>
      </Router>

    );
  }
}

export default App;
