import React from "react"
import { Plugin } from "@djsp/core"
import { createEntityStrategy } from "@djsp/utils"

const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()

  return <span className="editor-link">
    {children}
    <span
      contentEditable={false}
      className="editor-link__popover">
      <a target="_blank" href={url}>{url}</a>
    </span>
  </span>
};

export default () => (
  <Plugin
    decorators={[{
      strategy: createEntityStrategy('LINK'),
      component: Link,
    }]}
  />
)
