import React, { createRef, Component } from 'react'
import { EditorContainer, Editor } from '@djsp/core'
import InlineToolbar from './InlineToolbar'
import EmbeddedBlock from './EmbeddedBlock'
import VideoBlock from './VideoBlock'
import ImageBlock from './ImageBlock'
import Shortcuts from './Shortcuts'
import InsertBlock from './InsertBlock'
import LinkDecorator from './LinkDecorator'
import { getWordCount } from './utils'
import 'draft-js/dist/Draft.css'
import './editor.css'

export default class OpenmindEditor extends Component {
  state = {
    limitExceeded: false
  }

  constructor(props) {
    super(props)
    this.selectionRef = createRef()
  }

  onChange = editorState => {
    if (this.props.maxWords == null || getWordCount(editorState) <= this.props.maxWords) {
      this.props.onChange(editorState)
    } else {
      this.props.onChange(this.props.editorState)
    }
  }

  render() {
    return (
      <div className="editor" ref={this.selectionRef}>
        <EditorContainer
          editorState={this.props.editorState}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          onChange={this.onChange}
        >
          <main className="container">
            <Shortcuts />
            <EmbeddedBlock apiKeys={this.props.apiKeys} />
            <ImageBlock />
            <LinkDecorator />
            <VideoBlock />
            <InsertBlock setReadOnly={this.props.setReadOnly} apiKeys={this.props.apiKeys} />
            <InlineToolbar selectionRef={this.selectionRef} />
            <Editor />
          </main>
        </EditorContainer>
      </div>
    )
  }
}
