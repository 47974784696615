import React, { Component } from 'react';
import './reviews-page.css';
import HeaderBar from './header-bar.js';
import ProjectsPreviewSection from './projects-preview-section.js';
import AnimatingSpinnerBig from '../../assets/svg/animating-spinner-big.js';
import ReviewMenu from './review-menu.js';
import Backend from "../backend.js";
import Store from "../store.js";
import Users from "../../data/user.js";
import GenericModal from '../modal/generic-modal.js';
import ConvertToDisplaySkill from '../skills/convert-to-display-skill.js'
import {Helmet} from "react-helmet";


const TOTAL_STARTING_NUMREVIEWS = 25;

class ReviewsPage extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isSearchForNextReview: false,
        projectA: {},
        projectB: {},
        skillName: "",
        targetSkill: undefined,
        searchMessage: "",
        totalSkillsToQuantify: 0,
        reviewQueue: [],
        currentBatchNum: 1,
        allNumBatches: 1,
        skillsWithNoMoreProjects: {},
        placeInReviewQueue: 0,
        caseStudyTitleContributing: "",
        caseStudySkillContributing: "",
        contributedPart: false,
        doingOtherSkills: false,
        extraReviewsMade: 0,
        hasHiddenReviewMenu: false,
        isReviewMenuOpen: true,
        timeBeforeSubmitGood: false,
        isFinishedContributingModalOpen: false,
        isExhaustedSkillButNeedsMoreModalOpen: false,
        isFinishedSkillContributingModalOpen: false,
        isExhaustedAllSkillsInPlatformModalOpen: false,
        isLowQualityReviewModalOpen: false,
        noMoreCaseStudies: false,
      };

    if (!this.props.isAuthenticated) {
        this.props.history.push('/login');
    }
    this.handleComparison = this.handleComparison.bind(this);
    this.setProjects = this.setProjects.bind(this);
    this.toggleIsReviewMenuOpen = this.toggleIsReviewMenuOpen.bind(this);
    this.toggleFinishedContributingModal = this.toggleFinishedContributingModal.bind(this);
    this.toggleExhaustedSkillButNeedsMoreModal = this.toggleExhaustedSkillButNeedsMoreModal.bind(this);
    this.toggleFinishedSkillContributingModal = this.toggleFinishedSkillContributingModal.bind(this);
    this.toggleExhaustedAllSkillsInPlatformModal = this.toggleExhaustedAllSkillsInPlatformModal.bind(this);
    this.toggleLowQualityReviewModal = this.toggleLowQualityReviewModal.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.findNextSkillToReview = this.findNextSkillToReview.bind(this);
    this.exhaustedAllSkillsInPlatform = this.exhaustedAllSkillsInPlatform.bind(this);
  }


  async componentDidMount() {
    await this.fetchReviewerInfo();
    //this.fetchFreshProjects();
  }

  startSubmitTimer() {
    this.setState({timeBeforeSubmitGood: false, hasHiddenReviewMenu: false});
    setTimeout(() => {
        console.log("TIME SHOULD BE GOOD NOW");
        this.setState({timeBeforeSubmitGood: true});
    }, 7000);
  }

  async fetchReviewerInfo() {
    this.showSearching(true, "Searching for the best case studies for you to review");
    let reviewQueue = await Backend.getBestQueueForReviewer(Users.auth.userId)
    console.log("whole review queue: ", reviewQueue);
    this.setState({reviewQueue: reviewQueue, currentBatchNum: 1, allNumBatches: reviewQueue.length});
    this.findNextSkillToReview()
  }

  async fetchFreshProjects() {
    this.showSearching(true, "Searching for the best case studies for you to review");
    let targetSkill = "_EXTRA_"
    if (this.state.reviewQueue[this.state.placeInReviewQueue]) {
      targetSkill = this.state.reviewQueue[this.state.placeInReviewQueue]["project"]["skills"][this.state.caseStudySkillContributing]
    }
    let projects = await Backend.getTwoProjects(Users.auth.userId, this.state.skillName, this.state.targetSkill);
    console.log("feched for skill: ", this.state.skillName);
    console.log("projects fetched: ", projects);
    if (projects.projectA && projects.projectB) {
      this.setProjects(projects.projectA, projects.projectB);
      this.showSearching(false, "");
      this.startSubmitTimer();
    } else {
      // if we didn't find any available projects for this person on the current skill.

      // add it to the skillsWithNoMoreProjects state
      let skillsWithNoMoreProjects = Object.assign({}, this.state.skillsWithNoMoreProjects);
      console.log("adding a new exhausted one: ", this.state.skillName)
      skillsWithNoMoreProjects[this.state.skillName] = true;
      console.log("didn't find any projects for that skill, so adding it to");
      console.log("skillsWithNoMoreProjects for reviewer: ", skillsWithNoMoreProjects);
      this.setState({skillsWithNoMoreProjects: skillsWithNoMoreProjects});

      this.findNextSkillToReview();
      // if the reviewer hasn't exhausted the skills in their review queue and is thus not "doing other skills" yet
      // if (!this.state.doingOtherSkills) {
      //   //let message = "You've finished reviewing all available projects for " + this.state.skillName + ". Switching you to another one!";
      //   console.log("is not doing other skills yet");
      //   //this.toggleExhaustedSkillButNeedsMoreModal();
      //   this.findNextSkillToReview();
      //   //alert(message);
      //   // search for the next skill in their review queue that they still have reviews to contribute to.
      //   //  this.findNextSkillToReview();
      //   return;
      // } else {
      //   // let skillsWithNoMoreProjects = Object.assign({}, this.state.skillsWithNoMoreProjects);
      //   // skillsWithNoMoreProjects[this.state.skillName] = true;
      //   // this.setState({skillsWithNoMoreProjects: skillsWithNoMoreProjects});
      //   this.getOtherSkillToReview();
      // }
    }
  }

  findNextSkillToReview() {
    console.log("finding next skill to review");
    let reviewQueue = this.state.reviewQueue;
    // go through review queue for the highest-density skills within the user's case studies.
    for (let i = 0; i < reviewQueue.length; i++) {
      let skillName = reviewQueue[i]["skill"];
      let skillsWithNoMoreProjects = this.state.skillsWithNoMoreProjects;
      console.log("goig through reviewQueue: ", skillName);
      console.log("skillsWithNoMoreProjects: ", skillsWithNoMoreProjects);
      if (skillName in this.state.skillsWithNoMoreProjects) {
        // if we just went through whole review queue (we're on the last one) and all the skills were exhausted (had no more projects)
        //if (i == reviewQueue.length - 1) {
        // new: if there's no more projects in this skill, get from otherskills

        this.getOtherSkillToReview();
        return;
        //}
      } else {
        let skills = reviewQueue[i]["project"]["skills"];
        let numRevsNeeded = skills[skillName]["ownerNumReqReviews"];
        // if the user still needs to contribute reviews to this case study in their review queue
        if (numRevsNeeded > 0) {
          //this.setState({placeInReviewQueue: i});
          //console.log("HEYYYY U")
          // let message = "heyy contributing to batch " + i;
          // alert(message);
          let caseStudyTitleContributing = this.state.reviewQueue[i]["project"]["projectTitle"];
          let caseStudySkillContributing = this.state.reviewQueue[i]["skill"];
          let targetSkill = "_EXTRA_"
          if (this.state.reviewQueue[i]) {
            targetSkill = this.state.reviewQueue[i]["project"]["skills"][caseStudySkillContributing]
          }

          this.setState({targetSkill: targetSkill});
          console.log("CONTRIBUTING TO ", caseStudySkillContributing, caseStudyTitleContributing);
          this.setState({caseStudySkillContributing: caseStudySkillContributing, caseStudyTitleContributing: caseStudyTitleContributing});
          this.setState({contributedPart: false});
          this.setState({skillName: skillName});
          this.setPercentFromReviewsNeeded(numRevsNeeded);
          this.fetchFreshProjects();
          return;
        }
      }
    }

    console.log("passed the review queue");
    // passed the review queue.

    if (this.state.contributedPart) {
      //this.setPercentFromReviewsNeeded(TOTAL_STARTING_NUMREVIEWS);
      this.getOtherSkillToReview();
      return;
    }

    // got to the end with no positive numrevs needed.
    console.log("finished contributing inside findNextSkillToReview");
    this.toggleFinishedContributingModal();

    //this.fetchFreshProjects();
  }

  updateNumReviewsLeftInBatch(num) {
    if (this.state.contributedPart) {
      this.setState(state => ({
        extraReviewsMade: state.extraReviewsMade + 1
      }));
      return (TOTAL_STARTING_NUMREVIEWS - this.state.extraReviewsMade % TOTAL_STARTING_NUMREVIEWS);
    }

    let currReviewQueue = JSON.parse(JSON.stringify(this.state.reviewQueue));
    let skillName = currReviewQueue[this.state.placeInReviewQueue]["skill"]
    let projectSkill = currReviewQueue[this.state.placeInReviewQueue]["project"]["skills"][skillName]
    let numRevsNeeded = projectSkill["ownerNumReqReviews"]

    currReviewQueue[this.state.placeInReviewQueue]["project"]["skills"][skillName]["ownerNumReqReviews"] = num
    this.setState({reviewQueue: currReviewQueue});
    return num
  }

  getNumReviewsLeftInBatch() {
    let currReviewQueue = this.state.reviewQueue
    if (!this.state.contributedPart) {
      let skillName = currReviewQueue[this.state.placeInReviewQueue]["skill"]
      let projectSkill = currReviewQueue[this.state.placeInReviewQueue]["project"]["skills"][skillName]
      let numRevsNeeded = projectSkill["ownerNumReqReviews"]
      return numRevsNeeded
    }
    // show different messaging.
    return TOTAL_STARTING_NUMREVIEWS - this.state.extraReviewsMade;
  }

  async getOtherSkillToReview() {
    let skillsExhausted = Object.keys(this.state.skillsWithNoMoreProjects);
    //skillsExhausted.push("hey");
    console.log("getting other skill to review");
    console.log("SKILLS EXHAUSTED: ", skillsExhausted);
    // if (!this.state.doingOtherSkills) {
    //   console.log("Now doing other skills");
    //   //not needed: this.toggleExhaustedSkillButNeedsMoreModal();
    //   this.setState({doingOtherSkills: true});
    //   //return;
    // }

    this.setState({targetSkill: undefined});
    let newSkill = await Backend.fetchOtherSkillToReview(Users.auth.userId, skillsExhausted);
    console.log("got this new other skill: ", newSkill);
    if (newSkill.skillName == null) {
      this.showSearching(false, "");
      this.toggleExhaustedAllSkillsInPlatformModal();
      return;
    }
    this.setState({skillName: newSkill.skillName});
    this.fetchFreshProjects();
  }

  validateSubmission() {
    if (!this.state.hasHiddenReviewMenu) {
      this.toggleLowQualityReviewModal();
      return false;
    }
    if (!this.state.timeBeforeSubmitGood) {
      this.toggleLowQualityReviewModal();
      return false;
    }
    return true;
  }

  async handleComparison(personSelected) {
    if (personSelected == null || personSelected == "") {
      return;
    }
    if (!this.validateSubmission()) {
      return;
    }
    console.log("handling comparison broughh: ", personSelected)
    this.showSearching(true, "Submitting your review and searching for new case studies");
    let reviewerId = Users.auth.userId //Store.authUserId;
    let skillIdentifier = this.state.skillName
    let betterProjectId = personSelected === "A" ? this.state.projectA.projectId : this.state.projectB.projectId
    let otherProjectId = personSelected === "A" ? this.state.projectB.projectId : this.state.projectA.projectId
    let revNum = this.getNumReviewsLeftInBatch()
    let revProjId = this.getProjIdDoingInBatch()
    let revSkillName = this.getSkillnameDoingInBatch()
    console.log("submitting: ", reviewerId, skillIdentifier, betterProjectId, otherProjectId, revProjId, revSkillName, revNum)
    let result = await Backend.submitComparison(reviewerId, skillIdentifier, betterProjectId, otherProjectId, revProjId, revSkillName, revNum, this.state.targetSkill);
    //this.showSearching(false, "");
    let numRevs = this.updateNumReviewsLeftInBatch(result.numReviewsNeeded)

    this.setPercentFromReviewsNeeded(numRevs);
    if (numRevs <= 0) {
      //alert("Congratulations! You've done enough reviews to quantify your project X for skill, now let's quantify next one");
      this.setState(state => ({
        currentBatchNum: state.currentBatchNum + 1,
        placeInReviewQueue: state.placeInReviewQueue + 1
      }));

      if (this.state.currentBatchNum == this.state.allNumBatches + 1) {
        console.log("finished contributing here!!");

        this.toggleFinishedContributingModal();
      } else {
        this.toggleFinishedSkillContributingModal();
      }
      //this.findNextSkillToReview();
      return;
    }

    if (result.newProjects.projectA && result.newProjects.projectB) {
      this.setProjects(result.newProjects.projectA, result.newProjects.projectB);
      this.showSearching(false, "");
      this.startSubmitTimer();
    } else {
      console.log("this one in EVER HERE?");
      //let message = "this one. You've finished reviewing all available projects for " + this.state.skillName + ". Switching you to another one!";
      //this.toggleFinishedContributingModal();
      //alert(message);
      //this.toggleExhaustedSkillButNeedsMoreModal();
      this.findNextSkillToReview();
    }
  }

  getSkillnameDoingInBatch() {
    if (this.state.contributedPart) {
      return "_EXTRA_"
    }

    let currReviewQueue = this.state.reviewQueue
    let skillName = ""
    if (currReviewQueue[this.state.placeInReviewQueue]) {
      skillName = currReviewQueue[this.state.placeInReviewQueue]["skill"]
    }
    return skillName
  }

  getProjIdDoingInBatch() {
    let currReviewQueue = this.state.reviewQueue
    if (currReviewQueue.length == 0) {
      return ""
    }
    let projectId = currReviewQueue[this.state.placeInReviewQueue]["project"]["projectId"]
    return projectId
  }

  setNumSkillsToQuantify(skills) {
    console.log("all reviewer skills: ", skills);
    let in_progress_skills = Object.keys(skills).map(skillName => skills[skillName])//.filter(skill => tru)
    console.log("in_progress_skills", in_progress_skills);
    this.setState({totalSkillsToQuantify: in_progress_skills.length})
  }

  setProjects(projectA, projectB) {
    let projectA_clean = {"projectId": projectA.projectId, "description": projectA.description,  "process": JSON.stringify(projectA.process), "outcome": JSON.stringify(projectA.outcome)}
    let projectB_clean = {"projectId": projectB.projectId, "description": projectB.description,  "process": JSON.stringify(projectB.process), "outcome": JSON.stringify(projectB.outcome)}
    this.setState({projectA: projectA_clean, projectB: projectB_clean});
  }

  setPercentFromReviewsNeeded(numReviewsNeeded) {
  //  console.log("total: ", TOTAL_STARTING_NUMREVIEWS);
    //console.log("numReviewsNeeded: ", numReviewsNeeded);
    let total = TOTAL_STARTING_NUMREVIEWS;
    let percent = ((total - numReviewsNeeded) / total) * 100;
    //console.log("percent: ", percent);
    let percentString = percent.toString() + "%";
    this.setState({percentReviewsCompleted: percentString});
  }

  showSearching(searching, message) {
    this.setState({isSearchForNextReview: searching, searchMessage: message});
  }

  toggleIsReviewMenuOpen() {
    this.setState(state => ({
      isReviewMenuOpen: !state.isReviewMenuOpen
    }));
    if (!this.state.isReviewMenuOpen) {
      this.setState({hasHiddenReviewMenu: true});
    }

  }

  toggleFinishedContributingModal() {
    this.setState(state => ({
      isFinishedContributingModalOpen: !state.isFinishedContributingModalOpen
    }));

    if (this.state.isFinishedContributingModalOpen) {
      this.setPercentFromReviewsNeeded(TOTAL_STARTING_NUMREVIEWS);
      this.setState({caseStudySkillContributing: "", caseStudyTitleContributing: ""});
      console.log("here doing thiss  when it actually needed: ", this.state.isFinishedSkillContributingModalOpen);
      this.setState({isFinishedSkillContributingModalOpen: false, contributedPart: true});
    }
  }

  toggleExhaustedSkillButNeedsMoreModal() {
    this.setState(state => ({
      isExhaustedSkillButNeedsMoreModalOpen: !state.isExhaustedSkillButNeedsMoreModalOpen
    }));
  }

  toggleFinishedSkillContributingModal() {
    this.setState(state => ({
      isFinishedSkillContributingModalOpen: !state.isFinishedSkillContributingModalOpen
    }));

    if (this.state.isFinishedSkillContributingModalOpen) {
      this.setState({isExhaustedSkillButNeedsMoreModalOpen: false});
    }
  }

  toggleExhaustedAllSkillsInPlatformModal() {
    this.setState(state => ({
      isExhaustedAllSkillsInPlatformModalOpen: !state.isExhaustedAllSkillsInPlatformModalOpen
    }));

    if (this.state.isExhaustedAllSkillsInPlatformModalOpen) {
      this.setState({noMoreCaseStudies: true, isFinishedSkillContributingModalOpen: false, isFinishedContributingModalOpen: false, isExhaustedSkillButNeedsMoreModalOpen: false});
    }
  }

  toggleLowQualityReviewModal() {
    this.setState(state => ({
      isLowQualityReviewModalOpen: !state.isLowQualityReviewModalOpen
    }));
  }


  exhaustedAllSkillsInPlatform() {
    // save in db we should notify this person when there's more case studies available.
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="reviews-page">
        <Helmet>
          <title>Peer Reviews | Symbol</title>
        </Helmet>
        <HeaderBar skillToReview={this.state.skillName}/>
        <ProjectsPreviewSection projectA={this.state.projectA} projectB={this.state.projectB} skillToReview={this.state.skillName}/>
        <div className='searching-overlay' style={{opacity: this.state.isSearchForNextReview? '0.9': '0', visibility:this.state.isSearchForNextReview? 'visible': 'hidden'}}>
          <div className='searching-container'>
            <div className='searching-container-vertical-center'>
              <div className='searching-message-container'>
                <div className='searching-message'>
                  {this.state.searchMessage}
                </div>
              </div>
              <div className='searching-message-container'>
                <AnimatingSpinnerBig />
              </div>
            </div>
          </div>
        </div>
        <ReviewMenu
          caseStudySkillContributing={this.state.caseStudySkillContributing}
          caseStudyTitleContributing={this.state.caseStudyTitleContributing}
          currentBatchNum={this.state.currentBatchNum}
          allNumBatches={this.state.allNumBatches}
          handleComparison={this.handleComparison}
          hasRequiredReviews={!this.state.contributedPart}
          isReviewMenuOpen={this.state.isReviewMenuOpen}
          toggleIsReviewMenuOpen={this.toggleIsReviewMenuOpen}
          skillToReview={this.state.skillName}
          percentReviewsCompleted={this.state.percentReviewsCompleted}
          history={this.props.history}
          />

          {/* 1. FINISHED CONTRIBUTING BUT CAN CONTINUE EARNING INFLUENCE MODAL */}
          <GenericModal
             isModalOpen={this.state.isFinishedContributingModalOpen}
             toggleModal={this.toggleFinishedContributingModal}
             xDoesPrimaryAction={true}
             title={"Nice! Keep going?"}
             message={<div>
               <p>{"Nice job - You've finished submitting all of your required reviews!"}</p>
               <p>{"You can continue submitting reviews to boost your Reviews Score, to contribute to the community, or to get inspiration from other people's case studies."}</p>
               </div>}
             primaryActionLabel={'Okay'}
             primaryAction={this.findNextSkillToReview}
          />

          {/* 2. ExhaustedSkillButNeedsMore reviews MODAL */}
          <GenericModal
             isModalOpen={this.state.isExhaustedSkillButNeedsMoreModalOpen}
             toggleModal={this.toggleExhaustedSkillButNeedsMoreModal}
             xDoesPrimaryAction={true}
             title={"Well Done!"}
             message={<div>
               <p>You've reviewed all case studies available for {this.state.skillName}, so we'll switch to another skill.</p>
               <p>{"All the peer reviews you do count towards getting your skills scored."}</p>
               </div>}
             primaryActionLabel={'Okay'}
             primaryAction={this.findNextSkillToReview}
          />

          {/* 3. FinishedSkillContributing MODAL */}
          <GenericModal
             isModalOpen={this.state.isFinishedSkillContributingModalOpen}
             toggleModal={this.toggleFinishedSkillContributingModal}
             title={"Nice job!"}
             xDoesPrimaryAction={true}
             message={<div>
               <p>You have contributed enough reviews to score your {ConvertToDisplaySkill(this.state.caseStudySkillContributing)} skill in your "{this.state.caseStudyTitleContributing}" case study.</p>
               <p>{"Let's get your other case study skills scored."}</p>
               </div>}
             primaryActionLabel={"Let's do it"}
             primaryAction={this.findNextSkillToReview}
          />

          {/* ExhaustedAllSkillsInPlatform MODAL */}
          <GenericModal
             isModalOpen={this.state.isExhaustedAllSkillsInPlatformModalOpen}
             toggleModal={this.toggleExhaustedAllSkillsInPlatformModal}
             title={"You're good for now"}
             xDoesPrimaryAction={true}
             message={<div>
               <p>{"There aren't any more available case studies for you to review right now."}</p>
               <p>{"Come back later to continue your peer reviews."}</p>
               </div>}
             primaryActionLabel={"Okay"}
             primaryAction={this.exhaustedAllSkillsInPlatform}
          />

          {/* LowQualityReview MODAL */}
          <GenericModal
             isModalOpen={this.state.isLowQualityReviewModalOpen}
             toggleModal={this.toggleLowQualityReviewModal}
             title={"Good things take time"}
             xDoesPrimaryAction={true}
             message={<div>
               {"Make sure you've taken the time necessary to look at both case studies and make a thoughtful comparison. This includes reading the case studies all the way to the end."}
               </div>}
             primaryActionLabel={"Okay"}

          />
      </div>
    );
  }
}

export default ReviewsPage;
