function DateConverter(startDate,endDate) {

  let dateValue=''
  if (startDate) {
    let rawDate = startDate.split('/')
    let month

    if (rawDate[0] == '01') {
      month = 'January'
    }

    if (rawDate[0] == '02') {
      month = 'February'
    }

    if (rawDate[0] == '03') {
      month = 'March'
    }

    if (rawDate[0] == '04') {
      month = 'April'
    }

    if (rawDate[0] == '05') {
      month = 'May'
    }

    if (rawDate[0] == '06') {
      month = 'June'
    }

    if (rawDate[0] == '07') {
      month = 'July'
    }

    if (rawDate[0] == '08') {
      month = 'August'
    }

    if (rawDate[0] == '09') {
      month = 'September'
    }

    if (rawDate[0] == '10') {
      month = 'October'
    }

    if (rawDate[0] == '11') {
      month = 'November'
    }

    if (rawDate[0] == '12') {
      month = 'December'
    }


    dateValue = dateValue + month + ' ' + rawDate[1] + ' - '
  }

  if (endDate) {
    let rawDate = endDate.split('/')
    let month

    if (rawDate[0] == '01') {
      month = 'January'
    }

    if (rawDate[0] == '02') {
      month = 'February'
    }

    if (rawDate[0] == '03') {
      month = 'March'
    }

    if (rawDate[0] == '04') {
      month = 'April'
    }

    if (rawDate[0] == '05') {
      month = 'May'
    }

    if (rawDate[0] == '06') {
      month = 'June'
    }

    if (rawDate[0] == '07') {
      month = 'July'
    }

    if (rawDate[0] == '08') {
      month = 'August'
    }

    if (rawDate[0] == '09') {
      month = 'September'
    }

    if (rawDate[0] == '10') {
      month = 'October'
    }

    if (rawDate[0] == '11') {
      month = 'November'
    }

    if (rawDate[0] == '12') {
      month = 'December'
    }

    dateValue = dateValue + month + ' ' + rawDate[1]

  }

  return dateValue

}

export default DateConverter
