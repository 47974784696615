import React from 'react';
import './profile-header.css';
import ProfilePenIcon from '../../assets/svg/profile-pen-icon.js';
import ProfileReviewSquares from '../../assets/svg/profile-review-squares.js';
import ProfilePreviewEye from '../../assets/svg/profile-preview-eye.js';

const ProfileHeader = props => {

  let formattedCtaLink = props.user.callToActionLink

  if (formattedCtaLink && !formattedCtaLink.includes('mailto') && !formattedCtaLink.includes('http')) {
    formattedCtaLink = 'http://' + formattedCtaLink

  }

  return(
    <div className='profile-header'>
      {props.isShownToExternalViewer?null:
        <div className='profile-header-buttons-main-container'>
          <div className='profile-header-buttons-sub-container'>
            <div className='profile-header-button'>
              <div onClick={props.toggleEditProfileModal} className='profile-header-button-label-container'>
                <div className='profile-header-button-icon'>
                  <ProfilePenIcon />
                </div>
                <div className='profile-header-button-label'>
                  Edit profile
                </div>
              </div>
            </div>

            <div className='profile-header-button' onClick={props.goToReviews}>
              <div className='profile-header-button-label-container'>
                <div className='profile-header-button-icon'>
                  <ProfileReviewSquares
                    reviewsNeeded={props.reviewsNeeded}
                  />
                </div>
                <div className={props.reviewsNeeded?'profile-header-button-label-red':'profile-header-button-label'}>
                  Peer reviews
                </div>
              </div>
            </div>

            <div className='profile-header-button' onClick={()=> window.open('mailto:hello@symbol.co?subject=Feedback%20about%20Symbol','_blank')}>
              <div className='profile-header-button-label-container'>
                <div className='profile-header-button-label'>
                  Feedback?
                </div>
              </div>
            </div>

            <div className='profile-header-button' onClick={props.handleLogout}>
              <div className='profile-header-button-label-container'>
                <div className='profile-header-button-icon'>
                </div>
                <div className='profile-header-button-label'>
                  Log out
                </div>
              </div>
            </div>


          </div>

        </div>
      }

      <div className='profile-header-container'>
        <div className='profile-header-main-container'>
          <div className='profile-picture-container'>
            <div className='profile-picture-border'>
              <div className='profile-picture'>

                {props.user.profilePhoto ?
                  <img src={props.user.profilePhoto}/>
                  :
                  <div className='profile-picture-letter'>
                    {props.user.firstName ? props.user.firstName[0] : ""}
                  </div>
                }
              </div>
            </div>

          </div>
          <div className='profile-header-name-container'>
            <div className='profile-header-name'>
              {props.user.firstName} {props.user.lastName}
            </div>
          </div>
          <div className='profile-header-bio-container'>
            <div className='profile-header-bio'>
              {props.user.bio}
            </div>
          </div>
          {props.user.callToActionText?
          <div className='profile-header-cta-container'>
            {props.user.callToActionLink.includes('mailto') ?
              <div onClick={()=> window.open(formattedCtaLink,"_self")} className='profile-header-cta'>
                {props.user.callToActionText} →
              </div>
              :
              <div onClick={()=> window.open(formattedCtaLink, "_blank")} className='profile-header-cta'>
                {props.user.callToActionText} →
              </div>
            }


          </div>
          :null}

        </div>
      </div>
    </div>

    );

}

export default ProfileHeader;
