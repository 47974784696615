function CreatedDateConverter(input) {
  let inputDate = input.split(' ')[0].split('-')
  let outputDate=''
  let month=''

  if (inputDate[1] == '01') {
    month = 'January'
  }

  if (inputDate[1] == '02') {
    month = 'February'
  }

  if (inputDate[1] == '03') {
    month = 'March'
  }

  if (inputDate[1] == '04') {
    month = 'April'
  }

  if (inputDate[1] == '05') {
    month = 'May'
  }

  if (inputDate[1] == '06') {
    month = 'June'
  }

  if (inputDate[1] == '07') {
    month = 'July'
  }

  if (inputDate[1] == '08') {
    month = 'August'
  }

  if (inputDate[1] == '09') {
    month = 'September'
  }

  if (inputDate[1] == '10') {
    month = 'October'
  }

  if (inputDate[1] == '11') {
    month = 'November'
  }

  if (inputDate[1] == '12') {
    month = 'December'
  }


  outputDate = outputDate + month + ' ' + inputDate[2] + ', ' + inputDate[0]
  return outputDate
}



export default CreatedDateConverter
