import React from 'react';
import './profile-projects-section.css';
import ProjectBlock from './project-block.js';
import User from '../../data/user.js';
import ProfilePenIcon from '../../assets/svg/profile-pen-icon.js';
import GenericModal from '../modal/generic-modal.js';


const ProfileProjectsSection = props => {


  function filter_published(project) {
    return project.publishState == 'published';
  }

  function filter_not_published(project) {
    return project.publishState !== 'published';
  }

  function compareDates(a, b) {
    console.log('b ',b.date_creation)
    console.log('a ',a.date_creation)
    return new Date(b.date_creation) - new Date(a.date_creation)
  }

  function comparePublishedDates(a, b) {
    let firstDate = 0

    if (a.endDate) {
      firstDate = parseInt(a.endDate.split('/')[1]+a.endDate.split('/')[0]+'999999')
    }

    if (a.startDate) {
      firstDate = firstDate-999999+parseInt(a.startDate.split('/')[1]+a.startDate.split('/')[0])
    }

    let secondDate = 0

    if (b.endDate) {
      secondDate = parseInt(b.endDate.split('/')[1]+b.endDate.split('/')[0]+'999999')
    }

    if (b.startDate) {
      secondDate = secondDate-999999+parseInt(b.startDate.split('/')[1]+b.startDate.split('/')[0])
    }

    if (firstDate === secondDate) {
      return new Date(b.date_creation) - new Date(a.date_creation)
    }

    return secondDate - firstDate

  }

  let projectsList=props.projects
  let projectListPublished=projectsList.filter(filter_published).sort(comparePublishedDates)
  let projectListNotPublished=projectsList.filter(filter_not_published).sort(compareDates)
  projectsList = projectListNotPublished.concat(projectListPublished)

  if (props.isShownToExternalViewer) {
    projectsList=projectsList.filter(filter_published)
  }

  const projects = projectsList.map(project => {
      return (
        <div className='project-block-container'>
          <ProjectBlock
            project = {project}
            key={project.projectId}
            isShownToExternalViewer={props.isShownToExternalViewer}
            seeProject={props.seeProject}
          />

        </div>
      );
  });

  return(
    <div className='profile-projects-section'>

    {props.isShownToExternalViewer || !props.isShownToExternalViewer && projectsList.length==0?null:

      <div className='profile-projects-new-project-button-container'>
        {!props.isNewProjectLoading?
        <div onClick={props.createNewProject} className='profile-projects-new-project-button'>
          <div className='profile-projects-new-project-button-label-container'>
            <div className='profile-projects-new-project-button-icon'>
              <ProfilePenIcon />
            </div>
            <div className='profile-projects-new-project-button-label'>
              New case study
            </div>
          </div>
        </div>

        :

        <div className='profile-projects-new-project-button-loading'>
          <div className='profile-projects-new-project-button-label-container'>
            <div className='profile-projects-new-project-button-label'>
              Creating...
            </div>
          </div>
        </div>
      }


      </div>

    }

      <div className='profile-projects-section-container'>
        <div className='profile-projects-section-container-main'>
          <div className='profile-projects-section-title-container'>
            <div className='profile-projects-section-title'>
              Case studies
            </div>
          </div>
          <div className='profile-projects-section-project-blocks-container'>

            {projects}

          </div>

          {projectsList.length>0?null:

            <div className='profile-projects-section-empty-state'>
              <div className='profile-projects-section-empty-state-message'>
                {props.isShownToExternalViewer ? `No case studies published`:`Share with the world what you’ve done. Create your first case study.`}
              </div>


              {props.isShownToExternalViewer ? null:
                <div className='profile-projects-new-project-button-container-empty'>
                  {!props.isNewProjectLoading?
                  <div onClick={props.createFirstCaseStudy} className='profile-projects-new-project-button-empty'>
                    <div className='profile-projects-new-project-button-label-container'>
                      <div className='profile-projects-new-project-button-icon'>
                        <ProfilePenIcon />
                      </div>
                      <div className='profile-projects-new-project-button-label'>
                        New case study
                      </div>
                    </div>
                  </div>

                  :

                  <div className='profile-projects-new-project-button-empty-loading'>
                    <div className='profile-projects-new-project-button-label-container'>
                      <div className='profile-projects-new-project-button-label'>
                        Creating...
                      </div>
                    </div>
                  </div>
                }


                </div>
              }
            </div>
          }

        </div>
      </div>
    </div>

    );

}

export default ProfileProjectsSection;
