import React, { Component } from 'react';
import './skill-page.css';
import Users from "../../data/user.js";
import ProjectBlock from '../user/project-block.js'
import Backend from "../backend.js";
import { Auth } from "aws-amplify";
import Projects from "../../data/projects.js";
import BackArrowDark from '../../assets/svg/back-arrow-dark.js';
import CircularProgressbar from 'react-circular-progressbar';
import BottomBadge from "../branding/bottom-badge.js";
import {Helmet} from "react-helmet";
import convertToDisplaySkill from '../skills/convert-to-display-skill.js'

class SkillPage extends Component {
  constructor(props) {
      super(props);
      this.state = {
        projects: [],
        username: this.props.match.params.username,
        isShownToExternalViewer: true,
        user: Users.emptyUser,
        skillName:this.props.match.params.skillName,
        loaded:false
      };

      this.setProjects = this.setProjects.bind(this);
      this.setUser = this.setUser.bind(this);
      this.seeProject = this.seeProject.bind(this);
      this.vanityDomain = this.vanityDomain.bind(this);
      this.onReturnToProfileClick = this.onReturnToProfileClick.bind(this);
  }

  vanityDomain() {
    // if (this.props.match.params.skillName.includes("-")) {
    //   let newSkillTag = this.props.match.params.skillName.replace("-", " ");
    //   this.setState({skillName: newSkillTag});
    // }

    let lowerCaseSkillUrl = this.props.match.params.skillName; //.toLowerCase();

    if (this.props.match.params.skillName.includes(" ")) {
      lowerCaseSkillUrl = lowerCaseSkillUrl.replace(" ", "");
    }
    this.props.history.push("/u/"+this.props.match.params.username + "/s/" + lowerCaseSkillUrl);
  }

  async componentDidMount() {

    //this.vanityDomain();

    console.log('User auth?', Users.auth)
    if (Users.auth.username == this.props.match.params.username) {
      this.setState({user: Users.auth});
    }

    if (Users.otherUsers[this.props.match.params.username]){
      this.setState({user: Users.otherUsers[this.props.match.params.username]});
    }

    if (Projects.cachedProjects[this.props.match.params.username]){
      let projects = Projects.cachedProjects[this.props.match.params.username].projects;
      function filter_published(project) {
        return project.publishState == 'published';
      }

      let skillName=this.state.skillName

      function filter_skillName(project) {
        if (!project.skills[skillName]) {
          return false
        }

        return true
      }

      function comparePublishedDates(a, b) {
        let firstDate = 0

        if (a.endDate) {
          firstDate = parseInt(a.endDate.split('/')[1]+a.endDate.split('/')[0]+'999999')
        }

        if (a.startDate) {
          firstDate = firstDate-999999+parseInt(a.startDate.split('/')[1]+a.startDate.split('/')[0])
        }

        let secondDate = 0

        if (b.endDate) {
          secondDate = parseInt(b.endDate.split('/')[1]+b.endDate.split('/')[0]+'999999')
        }

        if (b.startDate) {
          secondDate = secondDate-999999+parseInt(b.startDate.split('/')[1]+b.startDate.split('/')[0])
        }

        if (firstDate === secondDate) {
          return new Date(b.date_creation) - new Date(a.date_creation)
        }

        console.log('first date: ', firstDate)
        console.log('second date: ', secondDate)
        return secondDate - firstDate

      }

      projects=projects.filter(filter_published).filter(filter_skillName).sort(comparePublishedDates)

      this.setState({projects: projects});
      this.setState({loaded: true});

    }
    let isAuthUsersProfile = this.props.match.params.username
    this.setState({isShownToExternalViewer: !isAuthUsersProfile})
    this.setState({isUsernameError: false});
    await this.setUser();
    //await Backend.setUsername(this.state.username);

    await this.setProjects();
    this.setState({isLoading: false});


  }

  async setUser() {
    let user = await Backend.fetchUserByUsername(this.state.username);

    let username = user["username"]
    Users.otherUsers[username] = {"username": username}
    for (var property in user) {
        if (user.hasOwnProperty(property)) {
          Users.otherUsers[username][property] = user[property]
        }
    }
    this.setState({user: user});
  }

  async setProjects(): Promise<void> {
    console.log('here are the project: ', projects)
    let projects = await Backend.fetchProjectsForUserId(this.state.user.userId);

    function filter_published(project) {
      return project.publishState == 'published';
    }

    let skillName=this.state.skillName

    function filter_skillName(project) {
      if (!project.skills[skillName]) {
        return false
      }

      return true
    }

    function comparePublishedDates(a, b) {
      let firstDate = 0

      if (a.endDate) {
        firstDate = parseInt(a.endDate.split('/')[1]+a.endDate.split('/')[0]+'999999')
      }

      if (a.startDate) {
        firstDate = firstDate-999999+parseInt(a.startDate.split('/')[1]+a.startDate.split('/')[0])
      }

      let secondDate = 0

      if (b.endDate) {
        secondDate = parseInt(b.endDate.split('/')[1]+b.endDate.split('/')[0]+'999999')
      }

      if (b.startDate) {
        secondDate = secondDate-999999+parseInt(b.startDate.split('/')[1]+b.startDate.split('/')[0])
      }

      if (firstDate === secondDate) {
        return new Date(b.date_creation) - new Date(a.date_creation)
      }

      console.log('first date: ', firstDate)
      console.log('second date: ', secondDate)
      return secondDate - firstDate

    }

    projects=projects.projects.filter(filter_published).filter(filter_skillName).sort(comparePublishedDates)
    console.log('here are the filtered project: ', projects)
    this.setState({projects: projects});
    this.setState({loaded: true});
  }

  seeProject(projectId) {
    this.props.history.push('/u/' + this.props.match.params.username + '/' + projectId);
  }


  onReturnToProfileClick() {
    this.props.history.push('/u/'+this.props.match.params.username);
  }

  render() {

    var toTitleCase = function (str) {
      // //str = str.replace('-', ' ')
    	// str = str.toLowerCase().split(' ');
    	// for (var i = 0; i < str.length; i++) {
    	// 	str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    	// }
    	// return str.join(' ');
      return str;
    };

    let caseStudyNumberLabel=<div>Through <span className='skill-page-description-blue-span'>1 case study</span>, {this.state.user.firstName} has demonstrated skills in {convertToDisplaySkill(this.state.skillName)}.</div>

    if (this.state.projects.length==0 && this.state.loaded) {

      if (this.state.user.firstName) {
        caseStudyNumberLabel = `${this.state.user.firstName} hasn't published any case studies that demonstrate skills in ${convertToDisplaySkill(this.state.skillName)}`
      } else {
        caseStudyNumberLabel = `This user hasn't published any case studies that demonstrate skills in ${convertToDisplaySkill(this.state.skillName)}.`

      }
    }

    if (this.state.projects.length>1) {
      caseStudyNumberLabel = <div>Through <span className='skill-page-description-blue-span'>{this.state.projects.length} case studies</span>, {this.state.user.firstName} has demonstrated skills in {convertToDisplaySkill(this.state.skillName)}.</div>
    }

    if (this.state.projects.length==1) {
      caseStudyNumberLabel = <div>Through <span className='skill-page-description-blue-span'>1 case study</span>, {this.state.user.firstName} has demonstrated skills in {convertToDisplaySkill(this.state.skillName)}.</div>
    }


    let isSkillQuantified = false

    if (this.state.user.skills && this.state.user.skills[this.state.skillName]) {
        isSkillQuantified = this.state.user.skills[this.state.skillName].state === 'quantified'
    }

    let quantifiedScore = 0

    if (this.state.user.skills && this.state.user.skills[this.state.skillName]) {
        quantifiedScore = this.state.user.skills[this.state.skillName].displayBucket
    }

    let caseStudyNumberQuantifiedLabel=<div>Based on <span className='skill-page-description-blue-span'>1 case study</span>, {this.state.user.firstName} has been scored in the <span className='skill-page-description-blue-span'>{quantifiedScore} percentile</span> for {convertToDisplaySkill(this.state.skillName)} overall. This means {this.state.user.firstName} demonstrates higher expertise than {quantifiedScore}% of all others with skills in {convertToDisplaySkill(this.state.skillName)}.</div>

    if (this.state.projects.length>1) {
      caseStudyNumberQuantifiedLabel = <div>Based on <span className='skill-page-description-blue-span'>{this.state.projects.length} case studies</span>, {this.state.user.firstName} has been scored in the <span className='skill-page-description-blue-span'>{quantifiedScore} percentile</span> for {convertToDisplaySkill(this.state.skillName)} overall. This means {this.state.user.firstName} demonstrates higher expertise than {quantifiedScore}% of all others with skills in {convertToDisplaySkill(this.state.skillName)}.</div>
    }

    if (this.state.projects.length==1) {
      caseStudyNumberQuantifiedLabel = <div>Based on <span className='skill-page-description-blue-span'>1 case study</span>, {this.state.user.firstName} has been scored in the <span className='skill-page-description-blue-span'>{quantifiedScore} percentile</span> for {convertToDisplaySkill(this.state.skillName)} overall. This means {this.state.user.firstName} demonstrates higher expertise than {quantifiedScore}% of all others with skills in {convertToDisplaySkill(this.state.skillName)}.</div>

    }


    const projects = this.state.projects.map(project => {

        return (

          <div className='project-block-container'>
            <ProjectBlock
              project = {project}
              key={project.projectId}
              isShownToExternalViewer={true}
              seeProject={this.seeProject}
            />

          </div>
        );
    });

    let skillPageTitle = 'Symbol'

    if (this.state.user && this.state.skillName) {
      skillPageTitle = this.state.user.firstName + ' ' + this.state.user.lastName + ' | ' + convertToDisplaySkill(this.state.skillName) + ' Symbol'
    }


    return (
      <div className="skill-page">
        <Helmet>
          <title>{skillPageTitle}</title>
        </Helmet>
        <div className='skill-page-top-bar'>
          <div className='skill-page-top-bar-container'>
            <div onClick={this.onReturnToProfileClick} className='skill-page-back-to-profile'>
              <BackArrowDark />
              <div className='skill-page-back-to-profile-label'>
                Back to profile
              </div>
              <div className='skill-page-back-to-profile-label-mobile'>
                Back
              </div>
            </div>
            <div className='skill-page-top-section-container'>
              <div className="skill-page-profile-person">
                <div onClick={this.onReturnToProfileClick} className="skill-page-profile-person-container">
                  <div className="skill-page-profile-person-photo">
                    {this.state.user.profilePhoto ?
                      <img src={this.state.user.profilePhoto}/>
                      :
                      <div className='skill-page-profile-picture-letter-small'>
                        {this.state.user.firstName?this.state.user.firstName[0]:null}
                      </div>
                    }
                  </div>
                  <div className="skill-page-profile-person-name">
                    {this.state.user.firstName} {this.state.user.lastName}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>







        <div className='skill-page-top-section'>
          <div className='skill-page-skill-name-container'>
            <div className='skill-page-skill-name'>
              {convertToDisplaySkill(this.state.skillName)}
            </div>
          </div>

          {isSkillQuantified?
            <div>
            <div className='skill-page-score-block-container-margin'>
            <div className='skill-page-score-block-container'>
              <div className='skill-page-score-block'>
                  <div className='skill-page-score-block-inside-container-left'>
                    <div className='skill-page-score-block-score-container'>
                      <div className='scale-up-20-percent'>
                        <div className='circle-skill-block-circle'>

                          <div className='circle-skill-block-circle-text-container'>
                              <div className='circle-skill-block-circle-number-text-container'>
                                {quantifiedScore}
                              </div>
                            <div className='circle-skill-block-circle-percentile-text-container'>
                              percentile
                            </div>
                          </div>

                          <div className='circle-skill-block-progressbar-container'>
                            <CircularProgressbar
                              className='react-circular-progressbar'
                              percentage={quantifiedScore}
                              strokeWidth='9'
                              initialAnimation='true'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='skill-page-score-block-inside-container-right'>
                    <div className='skill-page-score-block-inside-container-right-center-container'>
                      <div className='skill-page-score-block-inside-container-right-text-container'>
                        <div className='skill-page-score-block-heading'>
                          Overall score
                        </div>
                        <div className='skill-page-score-block-body'>
                          {caseStudyNumberQuantifiedLabel}
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
            </div>
            </div>
            <div className='skill-page-show-when-small'>
              <div className='skill-page-description-container'>
                <div className='skill-page-description'>
                  {caseStudyNumberQuantifiedLabel}
                </div>
              </div>
            </div>
            </div>
            :

            <div className='skill-page-description-container'>
              <div className='skill-page-description'>
                {caseStudyNumberLabel}
              </div>
            </div>
          }

          </div>

          {this.state.projects.length==0 && this.state.loaded? null:

            <div className='skill-page-case-study-section'>

              {projects}

            </div>
          }
          <BottomBadge
            isAuth={Users.auth.username}
            onUser={this.props.match.params.username}
          />
        </div>
    );
  }
}

export default SkillPage;
