import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './homepage.css';
import HomepageDownArrow from '../../assets/svg/homepage-down-arrow.js';
import Logo from '../../assets/svg/logo.js';
import CreateAccountModal from './create-account-modal.js';
import LogInModal from './log-in-modal.js';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateAccountModalOpen: false,
      isLogInModalOpen: false,
      hasScrolled: false,
    };
    this.toggleCreateAccountModal = this.toggleCreateAccountModal.bind(this);
    this.toggleLogInModal = this.toggleLogInModal.bind(this);
    this.myRef = React.createRef();

    console.log("CAME FROM: ", this.props.redirected);
  }

  toggleCreateAccountModal() {
    this.setState(state => ({
      isCreateAccountModalOpen: !state.isCreateAccountModalOpen
    }));
  }

  toggleLogInModal() {
    this.setState(state => ({
      isLogInModalOpen: !state.isLogInModalOpen
    }));
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 350) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  scrollToDomRef = () => {
        const myDomNode = ReactDOM.findDOMNode(this.myRef.current)
        myDomNode.scrollIntoView()
  }

  render() {
    return (
      <div className='homepage'>
        <Helmet>
          <title>Symbol - Prove your skills</title>
        </Helmet>
        <div className={!this.state.hasScrolled ? 'homepage-scroll-top-bar homepage-scroll-top-bar-hidden' : 'homepage-scroll-top-bar'}>
          <div className='homepage-header-bar-container'>
            <div className='homepage-header-bar-logo'>
                <div className='homepage-header-bar-logo'>
                  <Logo />
                </div>
                <div className='homepage-header-bar-logo-2'>
                  symbol
                </div>

            </div>
          <div className='homepage-header-bar-links'>

            <div className='homepage-header-bar-links-link-container'>
              <div onClick={this.toggleCreateAccountModal} className='homepage-scroll-bar-cta'>
                Create your profile →
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className='homepage-header-bar'>
          <div className='homepage-header-bar-container'>
            <div className='homepage-header-bar-logo'>
                <div className='homepage-header-bar-logo'>
                  <Logo />
                </div>
                <div className='homepage-header-bar-logo-2'>
                  symbol
                </div>

            </div>
            <div className='homepage-header-bar-links'>

              <div className='homepage-header-bar-links-link-container'>
                <div onClick={this.toggleCreateAccountModal} className='homepage-header-bar-links-link-container-text'>
                  Sign up
                </div>
              </div>
              <div className='homepage-header-bar-links-link-container'>
                <div onClick={this.toggleLogInModal} className='homepage-header-bar-links-link-container-text'>
                  Log in
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='homepage-container'>

          {/* THE HERO SECTION  */}

          <div className='homepage-hero-section'>

            <div className='homepage-hero-section-title'>
              Prove your skills.
            </div>

            <div className='homepage-hero-section-sub-title-container'>
              <div className='homepage-hero-section-sub-title'>
                <div>Publish a beautiful portfolio of your work and see where you rank globally for any of your skills. Rankings are free and over 95% accurate.</div>
              </div>
            </div>

            <div className='homepage-hero-section-cta-button-container'>
              <div onClick={this.toggleCreateAccountModal} className='homepage-hero-section-cta-button'>
                <div className='homepage-hero-section-cta-button-label'>
                  Create your profile →
                </div>
              </div>
            </div>

            <div className='homepage-hero-section-profile-image-container-center'>
              <div className='homepage-hero-section-profile-image-container'>
                <img src='https://uploads-ssl.webflow.com/5aeb13b249d05bc52a3d1de6/5bd3506c186d668558a7bb6f_1.png' className='homepage-hero-section-profile-image'/>
              </div>
            </div>
          </div>


          {/* THE ABOUT SECTION  */}

          <div ref={this.myRef} className='homepage-about-section'>

            <div className='homepage-about-section-section-header-container'>
              <div className='homepage-section-header'>
                The first universal score for skills
              </div>
            </div>

            <div className='homepage-about-section-section-body-container'>
              <div className='homepage-section-body'>
                You have a unique set of skills, but it’s hard to fully prove it to others. Traditionally, your degrees, job titles, and years of experience were used as signals of your professional abilities, but we know these signals hardly capture what you can actually do. <span className='homepage-section-body-highlight'>Symbol lets you share the truth.</span>
              </div>
            </div>

            <div className='homepage-about-section-profile-image-container-center'>
              <div className='homepage-about-section-profile-image-container'>
                <img src='https://uploads-ssl.webflow.com/5aeb13b249d05bc52a3d1de6/5bce58a349e5d38bd8ea16b8_Group%2017.png' className='homepage-about-section-profile-image'/>
              </div>
            </div>

            <div className='homepage-about-section-section-body-container'>
              <div className='homepage-section-body'>
                For the first time, <span className='homepage-section-body-highlight'>get all of your skills universally assessed and assigned to a percentile score between 1 and 99 based on how you rank globally.</span> Share your scores publicly to demonstrate your expertise to friends, companies, and clients, or keep them private for your own reference. With over 95% accuracy, <span className='homepage-section-body-highlight'>your skills are finally proveable in a universal and reliable way.</span>
              </div>
            </div>

          </div>


          <div className='homepage-divider-container'>
            <div className='homepage-divider'></div>
          </div>

          {/* THE HOW IT WORKS SECTION  */}

          <div className='homepage-how-it-works-section'>

            <div className='homepage-how-it-works-section-italics-label'>
              How it works, in four steps

            </div>

            <HomepageDownArrow
              className='homepage-how-it-works-section-down-arrow'
            />

            <div className='homepage-how-it-works-section-step-container'>
              <div className='homepage-section-header'>
                Step 1 — Document your best work
              </div>
            </div>

            <div className='homepage-about-section-section-body-container'>
              <div className='homepage-section-body'>
                The best way to show your skills is by demonstrating them through your past work and projects. <span className='homepage-section-body-highlight'>Use the Symbol Editor to create beautiful, detailed case studies of your past work and skills in action.</span> Tag your case studies with the relevant skills and publish them to your profile. You can easily share case studies with friends, companies, and clients.
              </div>
            </div>

            <div className='homepage-how-it-works-section-profile-image-container-center'>
              <div className='homepage-hero-section-profile-image-container'>
                <img src='https://uploads-ssl.webflow.com/5aeb13b249d05bc52a3d1de6/5bd3517157f745aaf688e692_2.png' className='homepage-hero-section-profile-image'/>
              </div>
            </div>

            <HomepageDownArrow
              className='homepage-how-it-works-section-down-arrow-2'
            />

            <div className='homepage-how-it-works-section-step-container'>
              <div className='homepage-section-header'>
                Step 2 — Get anonymously reviewed
              </div>
            </div>

            <div className='homepage-about-section-section-body-container'>
              <div className='homepage-section-body'>
                We take the info from your case studies, remove anything personally identifying, and send it to our network of reviewers. <span className='homepage-section-body-highlight'>Reviewers are shown two anonymous case studies at a time and are simply asked to select which person demonstrates more expertise in the relevant skill.</span> We ask everyone who submits a case study to complete a few peer reviews to help.
              </div>
            </div>

            <div className='homepage-how-it-works-section-profile-image-container-center'>
              <div className='homepage-hero-section-profile-image-container'>
                <img src='https://uploads-ssl.webflow.com/5aeb13b249d05bc52a3d1de6/5bce48c5e5910b97297695d3_Group%202%20Copy%203.png' className='homepage-hero-section-profile-image'/>
              </div>
            </div>

            <HomepageDownArrow
              className='homepage-how-it-works-section-down-arrow-2'
            />

            <div className='homepage-how-it-works-section-step-container'>
              <div className='homepage-section-header'>
                Step 3 — Your scores are computed
              </div>
            </div>

            <div className='homepage-about-section-section-body-container'>
              <div className='homepage-section-body'>
                While a single review like this can be highly subjective, once <span className='homepage-section-body-highlight'>your case study is reviewed thousands of times</span>, directly or indirectly via this process, each of your scores mathematically converges to your <i>true score</i> (with a little help from the <span onClick={()=> window.open('https://en.wikipedia.org/wiki/Law_of_large_numbers', "_blank")} className='homepage-section-body-linked'>Law of Large Numbers</span>). In fact, using this process, <span className='homepage-section-body-highlight'>your skills are scored accurately with over 95% confidence</span>, the scientific standard.
              </div>
            </div>

            <div className='homepage-how-it-works-section-profile-image-container-center'>
              <div className='homepage-hero-section-profile-image-container'>
                <img src='https://uploads-ssl.webflow.com/5aeb13b249d05bc52a3d1de6/5bb3f7aa8d3a3ce7ba0ef7a7_homepage_image_5.png' className='homepage-hero-section-profile-image'/>
              </div>
            </div>

            <HomepageDownArrow
              className='homepage-how-it-works-section-down-arrow-2'
            />

            <div className='homepage-how-it-works-section-step-container'>
              <div className='homepage-section-header'>
                Step 4 — Manage & share your skills
              </div>
            </div>

            <div className='homepage-about-section-section-body-container'>
              <div className='homepage-section-body'>
                <span className='homepage-section-body-highlight'>Once your scores are computed, you are in full control of how they are used.</span> You can keep your scores private, allowing only you to see where you stand and track your progress. You can make your scores public on your profile to universally communicate your skills to the world. To update your scores, submit and resubmit as many case studies as you want.
              </div>
            </div>

            <div className='homepage-how-it-works-section-profile-image-container-center'>
              <div className='homepage-about-section-profile-image-container'>
                <img src='https://uploads-ssl.webflow.com/5aeb13b249d05bc52a3d1de6/5bb3f938e6340f408215e5d1_homepage_image_6.png' className='homepage-about-section-profile-image'/>
              </div>
            </div>


          </div>

          <div className='homepage-divider-container'>
            <div className='homepage-divider'></div>
          </div>

          <div className='homepage-hero-section'>

            <div className='homepage-hero-section-title'>
              Join the future.
            </div>

            <div className='homepage-hero-section-sub-title-container'>
              <div className='homepage-hero-section-sub-title'>
                {"The old ways of proving your skills — degrees, job titles, years of experience — don't tell most of the story. Prove all of your skills reliably and freely with Symbol, and get a beautiful portfolio out of it."}
              </div>
            </div>

            <div className='homepage-hero-section-cta-button-container'>
              <div onClick={this.toggleCreateAccountModal} className='homepage-hero-section-cta-button'>
                <div className='homepage-hero-section-cta-button-label'>
                  Create your profile now →
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className='homepage-footer'>
          <div className='homepage-footer-container'>

            <div className='homepage-footer-links-link-container-text-copyright'>
              © 2018 Symbol.co
            </div>

            <div className='homepage-footer-links'>

              <div className='homepage-footer-links-link-container'>
                <div onClick={()=> window.open('mailto:hello@symbol.co','_blank')} className='homepage-footer-links-link-container-text'>
                  Contact us
                </div>
              </div>



              <div className='homepage-footer-links-link-container'>
                <div onClick={()=> window.open('https://www.notion.so/Symbol-Privacy-Policy-54cb653d8f66491389c123f59535a664','_blank')} className='homepage-footer-links-link-container-text'>
                  Privacy Policy
                </div>
              </div>

              <div className='homepage-footer-links-link-container'>
                <div onClick={()=> window.open('https://www.notion.so/openmind/Symbol-Terms-of-Service-ddbc390b70fd465f8c7a8077a1452abf','_blank')} className='homepage-footer-links-link-container-text'>
                  Terms of Service
                </div>
              </div>

            </div>
          </div>
        </div>

        <div style={{visibility:this.state.isCreateAccountModalOpen || this.state.isLogInModalOpen? 'visible':'hidden',opacity:this.state.isCreateAccountModalOpen || this.state.isLogInModalOpen? '1' : '0'}} className='general-overlay'></div>

        <CreateAccountModal
          userHasAuthenticated = {this.props.userHasAuthenticated}
          toggleCreateAccountModal = {this.toggleCreateAccountModal}
          isCreateAccountModalOpen = {this.state.isCreateAccountModalOpen}
          toggleLogInModal = {this.toggleLogInModal}
         />

         <LogInModal
            userHasAuthenticated = {this.props.userHasAuthenticated}
           toggleLogInModal = {this.toggleLogInModal}
           isLogInModalOpen = {this.state.isLogInModalOpen}
           toggleCreateAccountModal = {this.toggleCreateAccountModal}
          />

      </div>

  )}
}

export default withRouter(HomePage);
