import React from 'react'

const ProfileReviewSquares = props => (
  <svg width={15} height={15} {...props}>
    <g fill={props.reviewsNeeded?"#348EFF":"#838383"} fillRule="evenodd">
      <path d="M0 0h7v7H0zM0 8h7v7H0zM8 0h7v7H8zM8 8h7v7H8z" />
    </g>
  </svg>
)

export default ProfileReviewSquares
