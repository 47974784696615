import React from "react"

const DropdownArrow = () => (
  <svg width={8} height={5}>
    <g
      stroke="#FFF"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M1.5 1.5L4 4M6.5 1.5L4 4" />
    </g>
  </svg>
)

export default DropdownArrow
