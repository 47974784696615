import React from 'react'

const ShowAllArrow = props => (
  <svg width={20} height={11} {...props}>
    <path
      d="M19.795.213a.693.693 0 0 0-.999 0L10.01 9.264 1.206.213a.693.693 0 0 0-1 0 .742.742 0 0 0 0 1.027l9.287 9.547a.68.68 0 0 0 .5.213.71.71 0 0 0 .5-.213l9.285-9.547a.726.726 0 0 0 .017-1.027z"
      fill="#767676"
      fillRule="nonzero"
    />
  </svg>
)

export default ShowAllArrow
