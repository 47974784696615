import React, { Component } from 'react';
import './profile-skills-section.css';
import CircleSkillBlock from './circle-skill-block.js';
//import User from '../../data/this.props.user.js'
import ShowAllArrow from '../../assets/svg/show-all-arrow.js'
import ShowLessArrow from '../../assets/svg/show-less-arrow.js'

class ProfileSkillsSection extends Component {

  constructor(props) {
      super(props);
      this.state = {
        isAllSkills: false
      };

      this.toggleShowAll = this.toggleShowAll.bind(this);

    }

  toggleShowAll() {
    this.setState(state => ({
      isAllSkills: !this.state.isAllSkills
    }));
  }

  render() {

    function filter_hidden(skill) {
      return skill.hidden == false;
    }

    function filter_quantified(skill) {
      return skill.state == 'quantified';
    }

    function filter_public(skill) {
      return skill.private == false;
    }

    function filter_private(skill) {
      return skill.private == true;
    }

    function filter_not_quantified(skill) {
      return skill.state !== 'quantified';
    }

    function compareDisplayBuckets(a, b) {
      return b.displayBucket - a.displayBucket;
    }

    let skillsNotHidden = Object.keys(this.props.user.skills).map(key => {return this.props.user.skills[key]}).filter(filter_hidden)
    const skillsNotHiddenQuantified = skillsNotHidden.filter(filter_quantified).filter(filter_public).sort(compareDisplayBuckets)
    const skillsNotHiddenQuantifiedPrivate = skillsNotHidden.filter(filter_quantified).filter(filter_private)
    const skillsNotHiddenNotQuantified = skillsNotHidden.filter(filter_not_quantified)
    skillsNotHidden = skillsNotHiddenQuantified.concat(skillsNotHiddenQuantifiedPrivate).concat(skillsNotHiddenNotQuantified)

    const numberOfSkills = skillsNotHidden.length
    const numberOfSkillsQuantified = Object.keys(this.props.user.skills).map(key => {return this.props.user.skills[key]}).filter(filter_quantified).filter(filter_hidden).filter(filter_public).length


    if (this.state.isAllSkills) {
      skillsNotHidden = skillsNotHidden
    } else {
      skillsNotHidden = skillsNotHidden.slice(0,5)
    }

    const CircleSkillBlocks = skillsNotHidden.map(skill => {
      console.log("single skill: ", skill);
        return (
          <div key={skill.skillName} className='profile-skills-section-circle-skill-block-container'>
            <CircleSkillBlock
              skill ={skill}
              numberOfSkillsQuantified={numberOfSkillsQuantified}
              seeSkill={this.props.seeSkill}
            />
          </div>
        );
    });


    return(
      <div className='profile-skills-section'>
        <div className='profile-skills-section-container'>
          <div className='profile-skills-section-container-main'>
            <div className='profile-skills-section-title-container'>
              <div className='profile-skills-section-title'>
                {numberOfSkillsQuantified==0?'Skills':'Skills'}
              </div>
            </div>

            {numberOfSkills==0?null:


            <div className='profile-skills-section-circle-skill-blocks-container'>
              {CircleSkillBlocks}
            </div>

            }

            {numberOfSkills==0 || numberOfSkillsQuantified==0 ?null:

            <div className='profile-skills-section-quantified-message'>
              <div className='profile-skills-section-quantified-message-container'>
                  {this.props.user.firstName}’s skills have been universally assessed and assigned to a percentile score from 1 to 99 based on how {this.props.user.firstName} ranks globally. <span onClick={()=> window.open('/homepage', "_blank")} className='project-skills-underline'>Learn more</span>
              </div>
            </div>

            }


            {numberOfSkills<6?null:
              <div className='profile-skills-section-show-all'>
                <div onClick={this.toggleShowAll} className='profile-skills-section-show-all-container'>
                  {!this.state.isAllSkills?null:
                    <div className='profile-skills-section-show-less-arrow'>
                      <ShowLessArrow/>
                    </div>
                  }
                  <div className='profile-skills-section-show-all-text'>
                    {this.state.isAllSkills? 'Show less' : 'Show all'}
                  </div>
                  {this.state.isAllSkills?null:
                    <div className='profile-skills-section-show-all-arrow'>
                      <ShowAllArrow/>
                    </div>
                  }
                </div>
              </div>


            }


            {numberOfSkills>0?null:

            <div className='profile-skills-section-empty-message'>
              <div className='profile-skills-section-empty-message-container'>
                {this.props.isShownToExternalViewer? 'No skills published':'No skills published. Publish a case study to demonstrate your skills.'}
              </div>
            </div>

            }

          </div>
        </div>
      </div>

      );
  }
}

export default ProfileSkillsSection;
