import React from "react"

const CircleSpacer = () => (
  <svg width={2} height={2}>
    <circle
      cx={128}
      cy={37}
      r={1}
      transform="translate(-127 -36)"
      fill="#ABABAB"
      fillRule="evenodd"
    />
  </svg>
)

export default CircleSpacer
